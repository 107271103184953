<template>
	<div class="row">
		<div class="col-12">

			<!-- filter start -->
			<div class="card col-12">
				<div class="card-header row align-items-center justify-content-center">

					<div class="card-header-action dropdown btn-filter col-md-2 col-lg-2 col-sm-12 mt-2"
						v-if="this.userGpEcon.is_group_economic == 'S'">
						<a href="#" data-toggle="dropdown" class="btn btn-light dropdown-toggle">Instituição</a>
						<ul class="dropdown-menu dropdown-menu-sm dropdown-menu-left dropdown-filter">
							<li class="dropdown-title">Selecione uma Instituição:</li>
							<div v-for="item in bancoOption" :key="item.id"
								class="form-check custom-checkbox custom-control ml-3">
								<input class="form-check-input" type="radio" :value="item.id" v-model="selectedInstituicao"
									:id="'instituicao_' + item.id" />
								<label class="form-check-label" :for="'instituicao_' + item.id">{{ item.label
								}}</label>
							</div>
						</ul>
					</div>

					<!-- estado -->
					<div class="card-header-action dropdown btn-filter col-md-2 col-lg-2 col-sm-12 mt-2"
						v-if="this.userGpEcon.is_group_economic == 'S'">
						<a href="#" data-toggle="dropdown" class="btn btn-light dropdown-toggle">Detrans</a>
						<ul class="dropdown-menu dropdown-menu-sm dropdown-menu-left dropdown-filter">
							<li class="dropdown-title">Selecione os Detrans</li>
							<li v-if="!filterDetransGroup.length">
								<div class="text-center">
									<div class="spinner-border spinner-border-sm text-danger mt-1 mb-1" role="status">
										<span class="sr-only">Loading...</span>
									</div>
								</div>
							</li>
							<li v-for="dItem in filterDetransGroup" :key="dItem.id">
								<div class="form-check custom-checkbox custom-control ml-0 mt-1">
									<input class="form-check-input" type="checkbox" :value="dItem.id"
										v-model="checkedDetrans" :id="dItem.id" />
									<label class="form-check-label" :for="dItem.id">
										{{ dItem.estado }}
									</label>
								</div>
							</li>
						</ul>
					</div>

					<!-- estado -->
					<div class="card-header-action dropdown btn-filter col-md-2 col-lg-2 col-sm-12 mt-2"
						v-if="this.userGpEcon.is_group_economic !== 'S'">
						<a href="#" data-toggle="dropdown" class="btn btn-light dropdown-toggle">Detrans</a>
						<ul class="dropdown-menu dropdown-menu-sm dropdown-menu-left dropdown-filter">
							<li class="dropdown-title">Selecione os Detrans</li>
							<li v-if="!filterDetrans.length">
								<div class="text-center">
									<div class="spinner-border spinner-border-sm text-danger mt-1 mb-1" role="status">
										<span class="sr-only">Loading...</span>
									</div>
								</div>
							</li>
							<li v-for="dItem in filterDetrans" :key="dItem.id">
								<div class="form-check custom-checkbox custom-control ml-0 mt-1">
									<input class="form-check-input" type="checkbox" :value="dItem.id"
										v-model="checkedDetrans" :id="dItem.id" />
									<label class="form-check-label" :for="dItem.id">
										{{ dItem.estado }}
									</label>
								</div>
							</li>
						</ul>
					</div>
					<!-- end estado -->

					<!--filtro status-->
					<div class="card-header-action dropdown btn-filter col-md-2 col-lg-2 col-sm-12 mt-2">
						<a href="#" data-toggle="dropdown" class="btn btn-light dropdown-toggle">Status do Fechamento</a>
						<ul class="dropdown-menu dropdown-menu-sm dropdown-menu-left dropdown-filter">
							<li class="dropdown-title">Selecione o Status do Fechamento</li>
							<li v-if="!filterStatus.length">
								<div class="text-center">
									<div class="spinner-border spinner-border-sm text-danger mt-1 mb-1" role="status">
										<span class="sr-only">Loading...</span>
									</div>
								</div>
							</li>
							<li v-for="item in filterStatus" :key="item.id">
								<div class="form-check custom-checkbox custom-control ml-0 mt-1">
									<input class="form-check-input" type="checkbox" :value="item.id"
										v-model="checkedStatusPagamentosDetran" :id="item.id" />
									<label class="form-check-label" :for="item.id">
										{{ item.desc ? item.desc : "Erro" }}
									</label>
								</div>
							</li>
						</ul>
					</div>
					<!--end filtro status-->

					<!-- filtro período -->
					<div class="col-md-2 col-lg-2 col-sm-12 text-center">
						<a href="#" data-toggle="dropdown" class="btn btn-light dropdown-toggle">Período de Vencimento</a>
						<ul class="dropdown-menu dropdown-menu-sm dropdown-menu-left dropdown-filter">
							<li class="dropdown-title">Selecione um período de vencimento</li>
							<li v-for="item in months" :key="item.mesAno">
								<div class="form-check custom-checkbox custom-control ml-0 mt-1">
									<input class="form-check-input" type="radio" :value="item.id" v-model="selectedMonth"
										:id="item.mesAno" />
									<label class="form-check-label" :for="item.mesAno">
										{{ item.mesAno ? item.mesAno : "Erro" }}
									</label>
								</div>
							</li>
						</ul>
					</div>
					<!-- end filtro período -->

					<!-- filter button -->
					<div class="col-md-2 col-lg-2 col-sm-12 text-center">
						<a href="#" class="btn btn-danger col-md-5 col-lg-5 col-sm-12 mt-2 btn-filter" data-toggle="tooltip"
							data-placement="top" title="Filtrar" id="inicial" @click="updateItems">
							<i class="fas fa-filter"></i>
						</a>
						<a href="#" class="btn btn-danger col-md-5 col-lg-5 col-sm-12 mt-2 btn-filter" data-toggle="tooltip"
							data-placement="top" title="Limpar Filtro" @click="clearFilter">
							<i class="fas fa-times"></i>
						</a>
					</div>
					<!-- end filter button -->
				</div>


			</div>
			<!-- filter end -->

			<!-- LIST FATURAMENTOS -->
			<div class="card">
				<div class="card-body">
					<div class="row" style="display: flex; justify-content: center;">
						<div style="display: flex; justify-content: center; gap: 2rem; padding: 1rem 0;">
							<h5>FECHAMENTOS LOCALIZADOS COM O(S) FILTRO(S):</h5>
							<span v-if="userGpEcon.is_group_economic === 'S' && selectedInstituicao">
								<h5>Instituição:
									{{ getAllBancosById(selectedInstituicao) }} </h5>
							</span>
							<span v-if="checkedDetrans.length > 0">
								<h5>Detrans:
									<span v-for="(item, index) in checkedDetrans" :key="index">{{ getDetranById(item) }},
									</span>
								</h5>
							</span>
							<span v-if="checkedStatusPagamentosDetran.length > 0">
								<h5>Status:
									<span v-for="(item, index) in checkedStatusPagamentosDetran" :key="index">{{
										getFaturamentoStatusById(item) }}, </span>
								</h5>
							</span>
							<span v-if="months[selectedMonth].mesAno">
								<h5>Período de Vencimento: {{
									months[selectedMonth].mesAno }}
								</h5>
							</span>
						</div>
					</div>
					<div class="row">
						<div class="col-md-5 offset-md-1 summary">
							<div class="summary-info" style="padding: 1rem  !important;">
								<h4>{{ new Intl.NumberFormat('pt-BR', {
									style: 'currency', currency: 'BRL'
								}).format(totalValorDetran)
								}}
								</h4>
								<div class="text-muted text-primary">Valor total Detran</div>
							</div>
						</div>
						<div class="col-md-5 summary">
							<div class="summary-info" style="padding: 1rem 0 !important;">
								<h4>{{ new Intl.NumberFormat('pt-BR', {
									style: 'currency', currency: 'BRL'
								}).format(totalValorResult)
								}}
								</h4>
								<div class="text-muted text-primary">Valor total Registradora</div>
							</div>
						</div>
					</div>

					<!-- table -->
					<div class="pb-4" v-if="!loading && faturamentos.length > 0">

						<!-- export -->
						<div class="export-options" style="z-index: 1 !important;">
							<a href="#" class="btn btn-danger btn-filter export-button" @click="exportImportacaoToPdf">
								<i class="far fa-file-pdf"></i> PDF
							</a>

							<a href="#" class="btn btn-success btn-filter export-button" @click="exportImportacaoToExcel">
								<i class="far fa-file-excel"></i> EXCEL
							</a>
						</div>
						<!-- end export -->

						<vue-good-table :columns="columns" :rows="faturamentos" :pagination-options="tablePagination"
							:line-numbers="true" :search-options="{
								enabled: true,
								placeholder: 'Chassi, Gravame, Nº Contrato e Tipo',
								searchFn: buscaDadosContratos
							}" styleClass="vgt-table">

							<template slot="table-row" slot-scope="props">
								<span v-if="props.column.field == '_fechamento'">
									{{ formatShortDate(props.row.periodo_inicial) }} - {{
										formatShortDate(props.row.periodo_final) }}
								</span>
								<span v-if="props.column.field == 'dt_vencimento'">
									{{ formatShortDate(props.row.data_vencimento) }}
								</span>
								<span v-if="props.column.field == 'action'">
									<div class="dropdown col-md-4 col-lg-4 col-sm-6 p-0">
										<button class="btn btn-outline-primary btn-filter dropdown-toggle"
											@click="resized()" style="position: absolute;z-index: 999;" type="button"
											id="dropdownActions" data-toggle="dropdown" aria-haspopup="true"
											aria-expanded="true" title="Botão de ações">
											<span>Ações</span>
										</button>
										<ul class="dropdown-menu" aria-labelledby="dropdownActions">
											<a class="dropdown-item" title="Reenviar Contrato"
												@click="viewContracts(props.row)">
												<i class="fas fa-file-alt"></i>&nbsp;
												<span>Ver Contratos</span>
											</a>

											<hr v-if="props.row.nota_fiscal" />

											<a class="dropdown-item" title="Visualizar Arquivo"
												:href="'/portal/contasapagar/' + props.row.id + '/download/notafiscal'"
												target="_blank" v-if="props.row.nota_fiscal">
												<i class="fas fa-eye"></i>&nbsp;
												<span>Visualizar Nota Fiscal</span>
											</a>

											<a class="dropdown-item" title="Visualizar Arquivo"
												:href="'/portal/contasapagar/' + props.row.id + '/download/boleto'"
												target="_blank" v-if="props.row.boleto">
												<i class="fas fa-eye"></i>&nbsp;
												<span>Visualizar Boleto</span>
											</a>

											<hr v-if="props.row.status == 1" />

											<span v-if="props.row.status == 2">
												<a class="dropdown-item" title="Gerar Fatura"
													@click="gerarFatura(props.row)" target="_blank">
													<i class="fas fa-file-download"></i>&nbsp;
													<span>Visualizar Fatura</span>
												</a>
											</span>
											<span v-if="props.row.status == 2">
												<a class="dropdown-item" title="Gerar Cancelamento"
													@click="viewCancelamento(props.row)">
													<i class="fas fa-ban"></i>&nbsp;
													<span>Cancelar Fatura</span>
												</a>
											</span>
											<span v-if="props.row.status == 5">
												<a class="dropdown-item" title="Gerar Pagamento"
													@click="viewPagamentoEfetuado(props.row)">
													<i class="fas fa-solid fa-file-invoice-dollar"></i>&nbsp;
													<span>Pagamento Realizado</span>
												</a>
											</span>
										</ul>
									</div>
								</span>
								<span v-else>
									{{ props.formattedRow[props.column.field] }}
								</span>
							</template>
						</vue-good-table>
					</div>
					<!-- end table -->

					<div v-if="!faturamentos.length && !loading" class="text-center p-3 text-muted">
						<h5>Nenhum fechamento encontrado!</h5>
					</div>

					<div class="text-center p-4 text-muted" v-if="loading">
						<h5>Carregando...</h5>
						<p>Aguarde até que os dados sejam carregados...</p>
					</div>

				</div>
			</div>
			<!-- END LIST CONTRACTS -->

			<!-- MODAL CONTRACTS -->
			<div class="modal fade" id="modalContracts" tabindex="-1" aria-labelledby="modalContractsLabel"
				aria-hidden="true">
				<div class="modal-dialog modal-xl modal-dialog-centered" style="max-width: 1500px;">
					<div class="modal-content">
						<div class="modal-header">
							<div style="display: flex; justify-content: center; gap: 2rem; padding: 1rem 0;">
								<span>
									<h5>Contratos da Instituição: {{ instituicaoContract }} - Vencimento: {{
										formatShortDate(faturamento_contratos.data_vencimento) }} -
										Período de Fechamento: {{ formatShortDate(faturamento_contratos.periodo_inicial) }}
										-
										{{ formatShortDate(faturamento_contratos.periodo_final) }} - Detran:
										{{ detranContract }} - Tipo: {{ faturamento_contratos.tipo }} - Valor Total:
										{{ formatCurrency(faturamento_contratos.valor_total) }}
									</h5>
								</span>
							</div>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>

						<div class="modal-body">

							<div class="export-options w-auto">
								<a href="#" class="btn btn-danger btn-filter export-button"
									@click="exportItemImportacaoToPdf">
									<i class="far fa-file-pdf"></i> PDF
								</a>

								<a href="#" class="btn btn-success btn-filter export-button"
									@click="exportItemImportacaoToExcel">
									<i class="far fa-file-excel"></i> EXCEL
								</a>
							</div>


							<vue-good-table :columns="columnsContracts" :rows="contracts" :line-numbers="true"
								:pagination-options="tablePagination" :search-options="{
									enabled: true,
									placeholder: 'Pesquise',
								}" styleClass="vgt-table">

								<template slot="table-row" slot-scope="props">
									<span>
										{{ props.formattedRow[props.column.field] }}
									</span>
									<span v-if="props.column.field == 'dt_contrato'">
										{{ formatShortDate(props.row.data_contrato) }}
									</span>
								</template>
							</vue-good-table>

						</div>
					</div>
				</div>
			</div>
			<!-- END MODAL CONTRACTS -->

			<!-- MODAL Cancelamento -->
			<div class="modal fade" id="modalCancelamento" tabindex="-1" aria-labelledby="modalCancelamentoLabel"
				aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered">
					<div class="modal-content">
						<div class="modal-header">
							Cancelar Fatura
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<div class="col-md-12">
								<div class="form-group">
									<label class="form-control-label">Motivo do Cancelamento</label>
									<textarea style="height: 90px" id="input-observacao" class="form-control"
										placeholder="Motivo do Cancelamento" v-model="observacao" maxlength="255">
											</textarea>
								</div>
							</div>
							<div class="col-md-12 text-right">
								<button type="submit" class="btn btn-primary" @click="cancelarFatura()">SALVAR</button>
							</div>

						</div>
					</div>
				</div>
			</div>
			<!-- END MODAL Cancelamento -->

			<!-- MODAL PAGAMENTOS -->
			<div class="modal fade" id="modalPagamentos" tabindex="-1" aria-labelledby="modalPagamentosLabel"
				aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="modalPagamentosLabel">Digite a data da realização do pagamento</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<div class="col-md-12">
								<input type="date" id="input-data_pagamento" class="form-control"
									placeholder="Data Pagamento" v-model="data_pagamento">

							</div>
							<br>
							<div class="col-md-12 text-right" v-if="contracts != ''">
								<div class="col-md-12 text-right" v-if="contracts[0]['status_pagamento'] != 5">
									<button type="submit" class="btn btn-primary"
										@click="vincularPagamentos(contracts)">ENVIAR
										PAGAMENTO</button>
								</div>
								<div class="col-md-12 text-right" v-if="contracts[0]['status_pagamento'] == 5">
									<button type="submit" class="btn btn-primary" disabled>PAGAMENTO EFETUADO</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- END MODAL PAGAMENTOS -->

			<vue-loading color="#ec2423" :active.sync="loading" :can-cancel="false"></vue-loading>

		</div>
	</div>
</template>
<script>
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table';
import Datepicker from 'vuejs-datepicker';
import { ptBR } from 'vuejs-datepicker/dist/locale'
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import ExcelJS from 'exceljs';
import * as XLSX from 'xlsx';

export default {
	props: ['detrans', 'user'],
	data() {
		const dataUser = JSON.parse(this.user);
		return {
			selectedInstituicao: '',
			bancoOption: [],
			bancoSelecionado: [],
			userGpEcon: JSON.parse(this.user),
			response: false,
			selectedMonth: 0,
			dataSelecionada: "",
			months: [],
			contracts: [],
			faturamentos: [],
			observacao: '',
			filterDetrans: dataUser.is_group_economic != 'S' ? JSON.parse(this.detrans) : '-',
			filterDetransGroup: [],
			totalValorResult: 0,
			totalValorDetran: 0,
			filterStatus: [{ desc: 'Conciliado', id: 1 }, { desc: 'Faturado', id: 2 }, { desc: 'Cancelamento Solicitado', id: 3 }, { desc: 'Cancelado', id: 4 }, { desc: 'Pendente', id: 5 }, { desc: 'Pago', id: 6 }],
			checkedDetrans: [],
			checkedBancos: [],
			data_pagamento: '',
			checkedStatusPagamentosDetran: [],
			checkedStatusPagamentosResult1: [],
			faturamento_contratos: [],
			periodo_vencimento: "",
			loading: false,
			newStatus: "",
			faturamentoID: "",
			calendarLanguage: ptBR,
			codigoBarras: '',
			columns: [
				{
					label: "Instituição",
					field: "banco.nome",
					sortable: false
				},
				{
					label: "Data Vencimento",
					field: "dt_vencimento",
					sortable: true
				},
				{
					label: "Período Fechamento",
					field: "_fechamento",
					sortable: false
				},
				{
					label: 'Detran',
					field: 'detran.estado',
					type: "string",
					sortable: true
				},
				{
					label: "Tipo",
					field: "tipo",
					type: "string",
					sortable: true
				},
				{
					label: 'Qtd Contratos',
					field: 'qtd_contratos',
					type: "number",
					sortable: true
				},
				{
					label: "Valor Total",
					field: "valor_total",
					type: "decimal",
					formatFn: this.formatCurrency,
					sortable: true
				},
				{
					label: "Status Pagamento",
					field: 'status_nome',
					type: "string",
					sortable: true
				},
				{
					label: "Ações",
					field: "action",
					sortable: false,
					html: true,
				},
			],
			columnsContracts: [
				{
					label: 'UF',
					field: 'uf'
				},
				{
					label: 'Operação',
					field: 'tipo_operacao'
				},
				{
					label: 'Valor',
					field: 'valor_reg_contrato',
					type: "decimal",
					formatFn: this.formatCurrency,
					sortable: true
				},
				{
					label: 'Num. Contrato',
					field: 'num_contrato'
				},
				{
					label: 'Gravame',
					field: 'gravame'
				},
				{
					label: 'Chassi',
					field: 'chassi'
				},
				{
					label: "Data Contrato",
					field: "dt_contrato",
					formatFn: this.formatDate,
					sortable: false
				},
				{
					label: "Criado em",
					field: "created_at",
					formatFn: this.formatDate,
					sortable: false
				},
				{
					label: "Registrado em",
					field: "dt_sucesso_reg_detran",
					formatFn: this.formatDate,
					sortable: false
				}
			],
			tablePagination: {
				enabled: true,
				mode: 'records',
				position: 'bottom',
				dropdownAllowAll: true,
				perPageDropdown: [10, 30, 50],
				nextLabel: 'Próximo',
				prevLabel: 'Anterior',
				rowsPerPageLabel: '',
				ofLabel: 'de',
				allLabel: 'Todos',
				pageLabel: '',
				perPageDropdownEnabled: true,
				compactMode: true,
			},
			estadoOptions: [],
			instituicaoContract: '',
			detranContract: '',
		};
	},
	mounted() {
		this.getMonths();
		if (this.userGpEcon.is_group_economic != 'S') {
			this.loadItems();
		}
		if (this.userGpEcon.is_group_economic == 'S') {
			this.getAllBancos()
		}
	},
	watch: {
		selectedInstituicao: function (newValue) {
			if (newValue !== '') {
				this.loadStates();
			}
		},
	},
	methods: {
		loadStates() {
			this.filterDetransGroup = [];

			axios.post(`/api/portal/getDetransUser/${this.userGpEcon.is_group_economic}/${this.selectedInstituicao}`).then(res => {
				this.filterDetransGroup = Object.values(res.data.detrans);
				this.filterDetransGroup.forEach(element => {
					this.estadoOptions.push({
						name: element.estado,
						id: element.id,
						selected: false
					});
				});
				// Extrai os nomes dos estados para ordenação
				const estados = this.filterDetransGroup.map(element => element.estado);
				// Ordena os nomes dos estados em ordem alfabética
				const sortedEstados = this.sortVector(estados);
				// Atualiza filterDetrans com os estados ordenados
				this.filterDetransGroup = sortedEstados.map(estado => this.filterDetransGroup.find(element => element.estado === estado));
			});
		},
		sortVector(vector) {
			return vector.sort((a, b) => a.localeCompare(b));
		},
		selectedIntituicao(id) {
			this.estadoOptions.forEach(element => {
				if (element.id == id) {
					element.selected = !element.selected;
				}
			});
		},
		getAllBancos() {
			axios.get("/api/portal/getAllBancos").then(res => {
				this.bancos = res.data.bancos;
				this.bancoOption = this.bancos.map(element => ({
					label: element.nome,
					id: element.id,
				}));
			}).catch((err) => {
				console.error(err);
				this.loading = false;
			});
		},
		buscaDadosContratos(row, col, cellValue, searchTerm) {
			var newRow = row.contratos;
			for (let i = 0; i < newRow.length; i++) {
				if (newRow[i].gravame == searchTerm) {
					return true
				}
				if (newRow[i].num_contrato == searchTerm) {
					return true
				}
				if (newRow[i].chassi == searchTerm) {
					return true
				}
			}

			if (row.tipo.toUpperCase() == searchTerm.toUpperCase()) {
				return true
			}

			return false
		},
		loadItems() {
			let _this = this;
			_this.faturamentos = [];
			_this.contracts = [];
			_this.loading = true;
			_this.totalValorDetran = 0.00;
			_this.totalValorResult = 0.00;

			let formData = new FormData();

			let now = new Date();
			now.setMonth(now.getMonth() + 1);
			let referenceMonth = `${now.toLocaleString("default", {
				month: "2-digit",
			})}-${now.getFullYear()}`

			formData.append("periodo_vencimento", referenceMonth);
			formData.append("portal_cliente", 1);

			axios
				.post("/portal/contasapagar/search", formData, { headers: { 'Content-Type': 'multipart/form-data' } })
				.then((res) => {
					for (let key in res.data) {
						if (typeof res.data[key] === 'object') {
							const faturamento = res.data[key];
							faturamento.vgt_id = key;
							_this.faturamentos.push(faturamento);
						}
					}

					_this.totalValorResult = res.data.valorTotalResult;
					_this.totalValorDetran = res.data.valorTotalDetran;

					_this.loading = false;
				})
				.catch((err) => {
					_this.loading = false;
				});
		},
		updateItems() {
			let _this = this;
			_this.loading = true;
			_this.totalValorDetran = 0;
			_this.totalValorResult = 0;
			_this.faturamentos = [];

			let formData = new FormData();
			var detrans = ''
			if (this.userGpEcon.is_group_economic != 'S') {
				detrans = JSON.parse(_this.detrans);
			}
			const filterStatus = _this.filterStatus;

			let detransFiltered = _this.checkedDetrans.length > 0 ? _this.checkedDetrans : detrans;
			for (let i = 0; i < detransFiltered.length; i++) {
				if (_this.checkedDetrans.length > 0) {
					formData.append(`detrans[${i}]`, detransFiltered[i]);
				} else {
					formData.append(`detrans[${i}]`, detransFiltered[i].id);
				}
			}

			let statusFiltered = _this.checkedStatusPagamentosDetran.length > 0
				? _this.checkedStatusPagamentosDetran : filterStatus;
			for (let i = 0; i < statusFiltered.length; i++) {
				if (_this.checkedStatusPagamentosDetran.length > 0) {
					formData.append(`status[${i}]`, statusFiltered[i]);
				} else {
					formData.append(`status[${i}]`, statusFiltered[i].id);
				}
			}

			let periodoSelecionado = _this.months[_this.selectedMonth]
				? _this.months[_this.selectedMonth].mesAno || ''
				: '';
			let periodoVencimento = periodoSelecionado.replace("/", "-");
			formData.append("periodo_vencimento", periodoVencimento);
			formData.append("portal_cliente", 1);

			if (this.userGpEcon.is_group_economic == 'S') {
				formData.append(`insituicoesIds[0]`, this.selectedInstituicao)
				formData.append('userIsGroupEcon', this.userGpEcon.is_group_economic)
			}

			axios
				.post("/portal/contasapagar/search", formData, { headers: { 'Content-Type': 'multipart/form-data' } })
				.then((res) => {
					for (let key in res.data) {
						if (typeof res.data[key] === 'object') {
							const faturamento = res.data[key];
							faturamento.vgt_id = key;
							_this.faturamentos.push(faturamento);
						}
					}

					_this.totalValorResult = res.data.valorTotalResult;
					_this.totalValorDetran = res.data.valorTotalDetran;

					_this.loading = false;
				})
				.catch((err) => {
					_this.loading = false;
				});
		},
		clearFilter() {
			let _this = this;
			_this.checkedDetrans = [];
			_this.checkedStatusPagamentosDetran = [],
				_this.checkedStatusPagamentosResult1 = [],
				_this.periodo_vencimento = "";
			_this.totalValorDetran = 0;
			_this.totalValorResult = 0;
			_this.faturamentos = [];
			_this.selectedMonth = 0;
			_this.contracts = []
			_this.loadItems();
		},
		formatCurrency: function (value) {
			return Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
		},
		formatDate: function (value) {
			return moment(String(value)).format('DD/MM/YYYY HH:mm:ss');
		},
		formatShortDate: function (value) {
			return moment(String(value)).format('DD/MM/YYYY');
		},
		formatFilterDate(date) {
			let d = new Date(date);
			let dateString = moment(String(d)).format('YYYY-MM-DD');
			return dateString;
		},
		viewContracts(item) {
			this.contracts = [];
			this.contracts = item.contratos;
			this.faturamento_contratos = item;
			this.instituicaoContract = item.banco.nome;
			this.detranContract = item.detran.estado;
			$("#modalContracts").modal('show');
		},
		conciliarContratos(item, type) {
			let _this = this;
			let faturamento = item
			faturamento.faturamento_id = _this.faturamento_contratos.id
			faturamento.type = type

			axios.post('/portal/fatura/' + faturamento.id + '/conciliarContratos', faturamento).then((res) => {
				$("#modalCancelamento").modal('hide');
				_this.observacao = '';
				_this.$swal({
					title: "Sucesso",
					text: "Contrato(s) Conciliado(s)!",
					icon: "success"
				});
				_this.loadItems();
			})
				.catch((err) => {
					_this.loading = false;
					$("#modalCancelamento").modal('hide');
					_this.$swal({
						title: "Erro",
						text: "Falha na Conciliação dos Contratos. Tente novamente!",
						icon: "error"
					});
				});

		},
		viewCancelamento(item) {
			this.contracts = [];
			this.contracts = item;
			$("#modalCancelamento").modal('show');
		},
		cancelarFatura() {
			let _this = this;

			_this.contracts.observacao = _this.observacao
			axios.post('/portal/fatura/' + _this.contracts.id + '/solicitarcancelarfatura', _this.contracts).then((res) => {
				$("#modalCancelamento").modal('hide');
				_this.observacao = '';
				_this.$swal({
					title: "Sucesso",
					text: "Solicitação de cancelamento enviada com sucesso!",
					icon: "success"
				});
				location.reload();
			})
				.catch((err) => {
					_this.loading = false;
					$("#modalCancelamento").modal('hide');
					_this.$swal({
						title: "Erro",
						text: "Falha na Solicitação de cancelamento. Tente novamente!",
						icon: "error"
					});
				});

		},
		exportDataGrid(type, tableName) {
			let params = type == 'pdf' ?
				{
					type: 'pdf',
					fileName: tableName,
					htmlContent: false,
					onCellHtmlData: (cell, row, col, htmlContent) => htmlContent,
					jspdf: {
						orientation: 'l',
						margins: { right: 10, left: 10, top: 40, bottom: 40 },
						autotable: {
							styles: {
								fillColor: 'inherit',
								textColor: 'inherit',
								halign: 'center',
								fillStyle: 'DF',
								lineColor: 200,
								lineWidth: 0.1
							},
							headerStyles: { halign: 'left' },
							tableWidth: 'auto'
						}
					}
				} :
				{
					type: 'excel',
					htmlContent: false,
					fileName: tableName,
					mso: { fileFormat: 'xlsx' }
				};

			let options = { tableName: tableName };

			jQuery.extend(true, options, params);

			$('#vgt-table').tableExport(options);
		},
		resized() {
			var tb = document.getElementById('vgt-table')
			tb.style.height = '200px';
		},
		resizedM() {
			var tb = document.getElementById('vgt-table')
			if (tb.style.height == '380px') {
				tb.style.height = 'auto';

			}
		},
		gerarFatura(item) {
			let _this = this;
			_this.loading = true;
			axios.get('/portal/fatura/' + item.id + '/gerarfatura', item).then((res) => {
				location.href = res.config.url;
				_this.loading = false;
			})
				.catch((err) => {
					_this.loading = false;
				});
		},

		getMonths() {
			let now = new Date();
			let months = [];
			let firstMonth = new Date(now.getFullYear(), now.getMonth() + 1);

			for (let i = 0; i < 12; i++) {
				let generatedMonth = new Date(firstMonth.getFullYear(), firstMonth.getMonth() - i);
				months.push({
					id: i,
					mesAno: `${generatedMonth.toLocaleString("default", {
						month: "2-digit",
					})}/${generatedMonth.getFullYear()}`,
				}
				)
			}
			this.months = months;
		},
		getDetranById(id) {
			let detranEncontrado;

			if (this.filterDetransGroup.length > 0) {
				detranEncontrado = this.filterDetransGroup.find(item => item.id === id);
			} else {
				detranEncontrado = this.filterDetrans.find(item => item.id === id);
			}

			return detranEncontrado ? detranEncontrado.estado : "";
		},
		getFaturamentoStatusById(id) {
			const statusEncontrado = this.filterStatus.find(item => item.id === id);
			return statusEncontrado ? statusEncontrado.desc : "";
		},
		getAllBancosById(id) {
			const bancoEncontrado = this.bancoOption.find(item => item.id === id);
			return bancoEncontrado ? bancoEncontrado.label : "";
		},
		//PDF - FECHAMENTOS
		exportImportacaoToPdf() {

			const doc = new jsPDF({
				orientation: 'landscape'
			});

			const nomeArquivo = this.nomeImportacao;
			const instituicaoContract = this.faturamentos[0].banco.nome;
			const dataVenc = this.formatShortDate(this.faturamentos[0].data_vencimento);
			const detranContract = this.faturamentos[0].detran.estado;
			const valorTotalDetran = this.formatCurrency(this.totalValorDetran);
			const valorTotalResult = this.formatCurrency(this.totalValorResult);


			const img = new Image();
			img.src = '/images/default-logo.png';

			const marginLeft = 10;
			// Adicione a imagem no cabeçalho
			doc.addImage(img, 'JPEG', marginLeft, 2, 30, 13);

			// Adicione o título ao lado da imagem
			doc.setFontSize(12);
			doc.text(`Fechamentos Localizados - Instituição: ${instituicaoContract} - Vencimento: ${dataVenc} - Valor Total Detran: ${valorTotalDetran} - Valor Total Result: ${valorTotalResult}`, marginLeft + 7, 23);

			doc.setFontSize(8);
			const currentDate = moment().format('DD/MM/YYYY HH:mm'); // Data Atual/Hora
			doc.text('Gerado em: ' + currentDate, marginLeft + 239, 10);

			// Defina as colunas da tabela
			const columns = [
				'',
				'Instituição',
				'Data Vencimento',
				'Período Fechamento',
				'Detran',
				'Qtd. Contratos',
				'Valor Total',
				'Status Pagamento',
			];

			const columnStyles = {};
			for (let i = 0; i < 10; i++) {
				columnStyles[i] = { halign: 'center', fillColor: [231, 234, 236] };
			}

			// Defina os dados da tabela (substitua isso pelos seus próprios dados)
			const data = this.faturamentos.map((item, index) => [
				index + 1, // Adiciona 1 ao índice para obter a numeração de linha
				item.banco.nome,
				this.formatShortDate(item.data_vencimento),
				`${this.formatShortDate(item.periodo_inicial)} - ${this.formatShortDate(item.periodo_final)}`,
				item.detran.estado,
				item.qtd_contratos,
				this.formatCurrency(item.valor_total),
				item.status_nome
			]);

			// Crie uma tabela usando a função autoTable da biblioteca jsPDF
			doc.autoTable({
				head: [columns],
				body: data,
				theme: 'grid',
				styles: { overflow: 'linebreak' },
				margin: { top: 27, left: 7, right: 7 }, // Aumente o topo para dar espaço ao cabeçalho
				styles: { halign: 'center', fillColor: [236, 36, 52] },
				columnStyles: columnStyles,
			});

			// Adicione páginas adicionais (a partir da segunda página)
			const totalPages = doc.internal.getNumberOfPages();
			for (let i = 1; i <= totalPages; i++) {
				doc.setPage(i);
				doc.text('Página ' + i + '-' + totalPages, 10, doc.internal.pageSize.height - 10);
			}
			// Salve o arquivo PDF
			doc.save(`Fechamentos da Instituição: ${instituicaoContract} - Vencimento: ${dataVenc}.pdf`);
		},
		// XLSX FATURADOS
		exportImportacaoToExcel() {

			// Crie um novo workbook
			const workbook = XLSX.utils.book_new();

			// Crie uma nova planilha
			const worksheet = XLSX.utils.aoa_to_sheet([]);

			const nomeArquivo = this.nomeImportacao;
			const instituicaoContract = this.faturamentos[0].banco.nome;
			const dataVenc = this.formatShortDate(this.faturamentos[0].data_vencimento);
			const detranContract = this.faturamentos[0].detran.estado;
			const valorTotalDetran = this.formatCurrency(this.totalValorDetran);
			const valorTotalResult = this.formatCurrency(this.totalValorResult);

			const currentDate = moment().format('DD/MM/YYYY HH:mm'); // Data Atual/Hora

			// Defina o título na célula A1
			XLSX.utils.sheet_add_aoa(worksheet, [[`Result1`]], { origin: 'A1' });
			XLSX.utils.sheet_add_aoa(worksheet, [[``]], { origin: 'A2' });
			XLSX.utils.sheet_add_aoa(worksheet, [['Gerado em: ' + currentDate]], { origin: 'E1' });
			XLSX.utils.sheet_add_aoa(worksheet, [[`Fechamentos Localizados - Instituição: ${instituicaoContract} - Vencimento: ${dataVenc} - Valor Total Detran: ${valorTotalDetran} - Valor Total Result: ${valorTotalResult}`]], { origin: 'B3' });
			XLSX.utils.sheet_add_aoa(worksheet, [[``]], { origin: 'A4' });


			// Ajuste a largura da coluna A (assumindo que você deseja definir a largura para 15)
			worksheet['!cols'] = [
				{ wch: 15 }, // Largura da primeira coluna (coluna A)
				{ wch: 25 }, // Largura da segunda coluna (coluna B)
				{ wch: 35 }, // Largura da terceira coluna (coluna C)
				{ wch: 15 }, // Largura da terceira coluna (coluna D)
				{ wch: 15 }, // Largura da terceira coluna (coluna E)
				{ wch: 20 }, // Largura da terceira coluna (coluna F)
				{ wch: 20 }, // Largura da terceira coluna (coluna G)				
				// ... adicione mais objetos para mais colunas
			];

			// Defina as colunas e dados da planilha
			const columns = [
				'Instituição',
				'Data Vencimento',
				'Período Fechamento',
				'Detran',
				'Qtd. Contratos',
				'Valor Total',
				'Status Pagamento',
			];

			// Adicione as colunas na linha A2
			XLSX.utils.sheet_add_aoa(worksheet, [columns], { origin: 'A5' });

			// Preencha os dados
			const data = this.faturamentos.map((item) => [
				item.banco.nome,
				this.formatShortDate(item.data_vencimento),
				`${this.formatShortDate(item.periodo_inicial)} - ${this.formatShortDate(item.periodo_final)}`,
				item.detran.estado,
				item.qtd_contratos,
				this.formatCurrency(item.valor_total),
				item.status_nome
			]);

			// Adicione os dados
			XLSX.utils.sheet_add_aoa(worksheet, data, { origin: 'A6' });

			// Adicione a planilha ao workbook
			XLSX.utils.book_append_sheet(workbook, worksheet, 'Fechamentos da Instituição');

			// Crie um arquivo Excel blob
			const buffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
			const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

			// Crie um link para download
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.href = url;
			a.download = `Fechamentos da Instituição: ${instituicaoContract} - Vencimento: ${dataVenc}.xlsx`;
			a.click();
			window.URL.revokeObjectURL(url);

		},

		//PDF - CONTRATOS FATURADOS
		exportItemImportacaoToPdf() {

			const doc = new jsPDF({
				orientation: 'landscape'
			});

			const nomeArquivo = this.nomeImportacao;
			const instituicaoContract = this.instituicaoContract;
			const dataVenc = this.formatShortDate(this.faturamento_contratos.data_vencimento);
			const dtInicial = this.formatShortDate(this.faturamento_contratos.periodo_inicial);
			const dtFinal = this.formatShortDate(this.faturamento_contratos.periodo_final);
			const detranContract = this.detranContract;
			const tipoRelatorio = this.faturamento_contratos.tipo;
			const valorTotal = this.formatCurrency(this.faturamento_contratos.valor_total);


			const img = new Image();
			img.src = '/images/default-logo.png';

			const marginLeft = 10;
			// Adicione a imagem no cabeçalho
			doc.addImage(img, 'JPEG', marginLeft, 2, 30, 13);

			// Adicione o título ao lado da imagem
			doc.setFontSize(10);
			doc.text(`Contratos da Instituição: ${instituicaoContract} - Vencimento: ${dataVenc} - Período de Fechamento: ${dtInicial} - ${dtFinal} - Detran:${detranContract} - Tipo: ${tipoRelatorio} - Valor Total: ${valorTotal}`, marginLeft + 7, 23);

			doc.setFontSize(8);
			const currentDate = moment().format('DD/MM/YYYY HH:mm'); // Data Atual/Hora
			doc.text('Gerado em: ' + currentDate, marginLeft + 239, 10);

			// Defina as colunas da tabela
			const columns = [
				'',
				'UF',
				'Operação',
				'Valor',
				'Num. Contrato',
				'Gravame',
				'Chassi',
				'Dt. Contrato',
				'Criado em:',
				'Registrado em:'
			];

			const columnStyles = {};
			for (let i = 0; i < 10; i++) {
				columnStyles[i] = { halign: 'center', fillColor: [231, 234, 236] };
			}

			// Defina os dados da tabela (substitua isso pelos seus próprios dados)
			const data = this.contracts.map((item, index) => [
				index + 1, // Adiciona 1 ao índice para obter a numeração de linha
				item.uf,
				item.tipo_operacao,
				this.formatCurrency(item.valor_reg_contrato),
				item.num_contrato,
				item.gravame,
				item.chassi,
				this.formatShortDate(item.data_contrato),
				this.formatDate(item.created_at),
				this.formatDate(item.dt_sucesso_reg_detran)
			]);

			// Crie uma tabela usando a função autoTable da biblioteca jsPDF
			doc.autoTable({
				head: [columns],
				body: data,
				theme: 'grid',
				styles: { overflow: 'linebreak' },
				margin: { top: 27, left: 7, right: 7 }, // Aumente o topo para dar espaço ao cabeçalho
				styles: { halign: 'center', fillColor: [236, 36, 52] },
				columnStyles: columnStyles,
			});

			// Adicione páginas adicionais (a partir da segunda página)
			const totalPages = doc.internal.getNumberOfPages();
			for (let i = 1; i <= totalPages; i++) {
				doc.setPage(i);
				doc.text('Página ' + i + '-' + totalPages, 10, doc.internal.pageSize.height - 10);
			}
			// Salve o arquivo PDF
			doc.save(`Contratos da Instituição: ${instituicaoContract} - Vencimento: ${dataVenc}.pdf`);
		},

		//XLSX - CONTRATOS FATURADOS
		exportItemImportacaoToExcel() {

			// Crie um novo workbook
			const workbook = XLSX.utils.book_new();

			// Crie uma nova planilha
			const worksheet = XLSX.utils.aoa_to_sheet([]);

			const nomeArquivo = this.nomeImportacao;
			const instituicaoContract = this.instituicaoContract;
			const dataVenc = this.formatShortDate(this.faturamento_contratos.data_vencimento);
			const dtInicial = this.formatShortDate(this.faturamento_contratos.periodo_inicial);
			const dtFinal = this.formatShortDate(this.faturamento_contratos.periodo_final);
			const detranContract = this.detranContract;
			const tipoRelatorio = this.faturamento_contratos.tipo;
			const valorTotal = this.formatCurrency(this.faturamento_contratos.valor_total);

			const currentDate = moment().format('DD/MM/YYYY HH:mm'); // Data Atual/Hora

			// Defina o título na célula A1
			XLSX.utils.sheet_add_aoa(worksheet, [[`Result1`]], { origin: 'A1' });
			XLSX.utils.sheet_add_aoa(worksheet, [[``]], { origin: 'A2' });
			XLSX.utils.sheet_add_aoa(worksheet, [['Gerado em: ' + currentDate]], { origin: 'E1' });
			XLSX.utils.sheet_add_aoa(worksheet, [[`Contratos da Instituição: ${instituicaoContract} - Vencimento: ${dataVenc} - Período de Fechamento: ${dtInicial} - ${dtFinal} - Detran:${detranContract} - Tipo: ${tipoRelatorio} - Valor Total: ${valorTotal}`]], { origin: 'B3' });
			XLSX.utils.sheet_add_aoa(worksheet, [[``]], { origin: 'A4' });


			// Ajuste a largura da coluna A (assumindo que você deseja definir a largura para 15)
			worksheet['!cols'] = [
				{ wch: 10 }, // Largura da primeira coluna (coluna A)
				{ wch: 25 }, // Largura da segunda coluna (coluna B)
				{ wch: 15 }, // Largura da terceira coluna (coluna C)
				{ wch: 25 }, // Largura da terceira coluna (coluna D)
				{ wch: 25 }, // Largura da terceira coluna (coluna E)
				{ wch: 25 }, // Largura da terceira coluna (coluna F)
				{ wch: 25 }, // Largura da terceira coluna (coluna G)
				{ wch: 25 }, // Largura da terceira coluna (coluna H)
				{ wch: 25 }, // Largura da terceira coluna (coluna I)
				// ... adicione mais objetos para mais colunas
			];

			// Defina as colunas e dados da planilha
			const columns = [
				'UF',
				'Operação',
				'Valor',
				'Num. Contrato',
				'Gravame',
				'Chassi',
				'Dt. Contrato',
				'Criado em:',
				'Registrado em:'
			];

			// Adicione as colunas na linha A2
			XLSX.utils.sheet_add_aoa(worksheet, [columns], { origin: 'A5' });

			// Preencha os dados
			const data = this.contracts.map((item) => [
				item.uf,
				item.tipo_operacao,
				this.formatCurrency(item.valor_reg_contrato),
				item.num_contrato,
				item.gravame,
				item.chassi,
				this.formatShortDate(item.data_contrato),
				this.formatDate(item.created_at),
				this.formatDate(item.dt_sucesso_reg_detran)
			]);

			// Adicione os dados
			XLSX.utils.sheet_add_aoa(worksheet, data, { origin: 'A6' });

			// Adicione a planilha ao workbook
			XLSX.utils.book_append_sheet(workbook, worksheet, 'Contratos da Instituição');

			// Crie um arquivo Excel blob
			const buffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
			const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

			// Crie um link para download
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.href = url;
			a.download = `Contratos da Instituição: ${instituicaoContract} - Vencimento: ${dataVenc}.xlsx`;
			a.click();
			window.URL.revokeObjectURL(url);

		},
	},
	components: {
		VueGoodTable,
		Datepicker
	},
};
</script>
