<template>
	<div class="row">
		<div class="col-12">
			<form action="#" method="post" enctype='multipart/form-data'>
				<div class="form-card">
					<div class="row form-distance" v-if="userGpEcon.is_group_economic == 'S'">
						<div class="col-md-3">
							<div class="form-group">
								<label for="estado" class="form-control-label">Selecione a
									Instituição</label>
								<v-select id="instituicao" placeholder="Selecione a Instituição" label="label"
									@input="setSelectInstituicao" :options="instituicaoOption">
								</v-select>
							</div><!-- form group -->
						</div>
					</div>
					<vue-dropzone name="app" ref="dropzone" id="dropzone" v-on:vdropzone-error="onError"
						v-on:vdropzone-success="onSuccess" :options="dropzoneOptions">
					</vue-dropzone>
					<input type="hidden" name="_token" v-bind:value="csrf">
				</div>
			</form>
			<vue-loading color="#ec2423" :active.sync="loading" :can-cancel="false"></vue-loading>
		</div>
	</div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { SweetModal, SweetModalTab } from "sweet-modal-vue";

export default {
	props: ['user'],
	data() {
		return {
			userGpEcon: JSON.parse(this.user),
			instituicao: '',
			instituicaoOption: [],
			instituicaoSelecionada: null,
			csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
			dropzoneOptions: {
				headers: { "X-CSRF-TOKEN": document.querySelector('meta[name="csrf-token"]').getAttribute('content') },
				url: 'storeupload',
				thumbnailWidth: 100,
				maxFilesize: 7,
				acceptedFiles: ".pdf",
				palallelUploads: 5,
				dictDefaultMessage: "<i class='fa fa-cloud-upload-alt red' style='color: red'></i> Solte um arquivo PDF aqui ou clique para selecionar um arquivo para upload.",
				sending: (file, xhr, formData) => {
					if (this.userGpEcon.is_group_economic == 'S' && this.instituicaoSelecionada !== null) {
						formData.append('intituicaoId', this.instituicaoSelecionada.id);
					}
				}
			},
			loading: false,
		};
	},
	methods: {
		setSelectInstituicao(item) {
			this.instituicaoSelecionada = item
		},
		getAllBancos() {
			axios.get("/api/portal/getAllBancos").then(res => {
				this.bancos = res.data.bancos;
				this.instituicaoOption = this.bancos.map(element => ({
					label: element.nome,
					id: element.id,
					selected: false
				}));
				this.bancos = this.sortVector(this.instituicaoOption.map(banco => banco.label));
			}).catch((err) => {
				console.error(err);
				this.loading = false;
			});
		},
		onError(_file, _message, xhr) {
			_file.previewElement.querySelectorAll('.dz-error-message span')[0].textContent = _message.message;
		},

		onSuccess(_file, _response, _xhr) {
			/*false significa que nenhum contrato foi encontrado*/
			if (_response.success === false) {
				let node, _i, _len, _ref, _results;
				let message = _response.message // modify it to your error message
				_file.previewElement.classList.add("dz-error");
				_ref = _file.previewElement.querySelectorAll("[data-dz-errormessage]");
				_results = [];
				for (_i = 0, _len = _ref.length; _i < _len; _i++) {
					node = _ref[_i];
					_results.push(node.textContent = message);
				}
				return _results;
			}
		},

		handleFileUpload() {
			if (this.$refs.dropzone.files[0].size > 5120 * 5120) {
				this.$swal({
					title: "Erro",
					html: "Arquivo precisa ter no máximo 5mb de tamanho!",
					icon: "error"
				});

				return;
			} else {
				this.dropzone = this.$refs.dropzone.files[0];
			}
		}
	},
	mounted() {
		if (this.userGpEcon.is_group_economic == 'S') {
			this.getAllBancos();
		}
	},
	components: {
		vue2Dropzone,
		SweetModal,
		SweetModalTab
	},
};
</script>
