<template>
    <div class="error">
        <div class="message">
            <!-- <h4 class="text-center"><strong>Erro ao realizar a consulta no DETRAN {{ this.estado }} - Chassi: {{
                this.chassi
            }}</strong>
            </h4>            
            <h5 class="text-center"><strong>Consulta realizada: {{
                this.currentDate
            }}</strong>
            </h5> -->
        </div>
        <vue-loading color="#ec2423" :active.sync="loading" :can-cancel="false"></vue-loading>
    </div>
</template>
<script>

import Swal from 'sweetalert2';
import moment from 'moment';

export default {
    props: ['consulta'],
    data() {
        const consulta = JSON.parse(this.consulta);
        console.log(consulta);
        return {

            estado: consulta.estado,
            chassi: consulta.chassi,
            currentDate: moment().format('DD/MM/YYYY HH:mm'),
            pathReturn: process.env.MIX_URL + 'contratos',
            transacao: consulta.transacao,
            descricao: consulta.descricao,
            num_duda: consulta.duda,
            loading: false,

            title: '',
        }
    },
    mounted() {
        if (this.estado === 'RJ') {
            this.exibirMensagemDeErroRj()
        } else {
            this.exibirMensagemDeErro();
        }

    },
    methods: {
        exibirMensagemDeErro() {
            this.loading = true;
            setTimeout(() => {
                this.$swal({
                    icon: 'error',
                    title: `Erro ao realizar a consulta no DETRAN ${this.estado}`,
                    html: `Chassi: ${this.chassi}<br>Descrição: ${this.descricao}`,
                    footer: `Consulta realizada: ${this.currentDate}`
                }).then(() => {
                    // Redirecionar para outra tela após o usuário clicar em "OK"
                    window.location.href = this.pathReturn;
                });
            }, 1000); // Espera 1000 milissegundos antes de exibir o SweetAlert
        },
        exibirMensagemDeErroRj() {
            this.loading = true;
            setTimeout(() => {
                this.$swal({
                    icon: 'error',
                    title: `Erro ao realizar a consulta no DETRAN ${this.estado}`,
                    html: `Chassi: ${this.chassi}<br>Nº DUDA: ${this.num_duda}<br>Descrição: ${this.descricao}`,
                    footer: `Consulta realizada: ${this.currentDate}`
                }).then(() => {

                    window.location.href = process.env.MIX_URL + 'contratos';
                });
            }, 1000);
        },
        validateDate(date) {
            var temp = moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD');
            return String(temp);
        },
        validateDatehour(dthr) {
            var temp = moment(dthr, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:SS');
            return String(temp);
        },

    }
}
</script>

<style scoped>
.error {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.message {
    text-align: center;
}
</style>
