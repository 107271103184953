<template>
	<div class="row">
		<div class="col-12">
			<div class="card">

				<!-- card header -->
				<div class="card-header">
					<h4>Preencha os dados abaixo</h4>

					<a @click="updateData" class="btn btn-sm btn-primary text-white btn-right">SALVAR</a>
				</div>
				<!-- end card header -->

				<!-- card body -->
				<div class="card-body">

					<!-- form -->
					<h6 class="heading-small text-muted mb-4">Dados</h6>
					<div class="">
						<div class="row">

							<!-- nome -->
							<div class="col-md-5">
								<div class="form-group">
									<label class="form-control-label" for="input-nome">Nome da Instituição</label>
									<input type="text" id="input-nome" v-bind:class="{ 'is-invalid': errors.nome }"
										class="form-control" placeholder="Nome" v-model="nome" required="true">
									<div class="invalid-feedback" v-if="errors.nome">
										<p>{{ errors.nome[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-6 -->
							<!-- end nome -->

							<!-- nome_fantasia -->
							<div class="col-md-5">
								<div class="form-group">
									<label class="form-control-label" for="input-nome_fantasia">Nome Fantasia</label>
									<input type="text" id="input-nome_fantasia"
										v-bind:class="{ 'is-invalid': errors.nome_fantasia }" class="form-control"
										placeholder="Nome Fantasia" v-model="nome_fantasia" required="true"
										maxlength="25">
									<div class="invalid-feedback" v-if="errors.nome_fantasia">
										<p>{{ errors.nome_fantasia[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-6 -->
							<!-- end nome_fantasia -->

							<!-- tipo_instituicao -->
							<div class="col-md-2">
								<!-- <div class="form-group">
									<label class="form-control-label" for="input-tipo-instituicao">Tipo de
										Instituição</label>
									<select id="input-tipo-instituicao"
										v-bind:class="{ 'is-invalid': errors.tipo_instituicao }"
										v-model="tipo_instituicao" class="form-control"
										placeholder="Tipo de Instituição">
										<option value="1">1 - Banco</option>
										<option value="2">2 - Consórcio</option>
										<option value="3">3 - ESC (Empresa simples de crédito)</option>
										<option value="4">4 - Cooperativa de crédito</option>
										<option value="5">5 - Fintech</option>
									</select>
									<div class="invalid-feedback" v-if="errors.tipo_instituicao">
										<p>{{ errors.tipo_instituicao[0] }}</p>
									</div>
								</div> -->
							</div><!-- col-md-3 -->
							<!-- end tipo_instituicao -->

							<!-- cnpj -->
							<div class="col-2">
								<div class="form-group">
									<label class="form-control-label">CNPJ</label>
									<input type="tel" id="cnpj" v-bind:class="{ 'is-invalid': errors.cnpj }"
										class="form-control" placeholder="CNPJ" v-model="cnpj" maxlength="18"
										v-mask="'##.###.###/####-##'">
									<div class="invalid-feedback" v-if="errors.cnpj">
										<p>{{ errors.cnpj[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-lg-4 -->
							<!-- end cnpj -->

							<!-- ddd_instituição-->
							<div class="col-md-1">
								<div class="form-group">
									<label class="form-control-label" for="input-ddd">DDD</label>
									<input type="tel" id="input-ddd" v-bind:class="{ 'is-invalid': errors.ddd }"
										class="form-control" placeholder="DDD" v-model="ddd" required="true"
										maxlength="2">
									<div class="invalid-feedback" v-if="errors.ddd">
										<p>{{ errors.ddd[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-2 -->
							<!-- end ddd_instituição -->

							<!-- telefone_instituição -->
							<div class="col-md-2">
								<div class="form-group">
									<label class="form-control-label" for="input-telefone">Telefone</label>
									<input type="text" id="input-telefone"
										v-bind:class="{ 'is-invalid': errors.telefone }" class="form-control"
										placeholder="Telefone" v-model="telefone_instituicao" required="true"
										maxlength="10" v-mask="'#####-####'">
									<div class="invalid-feedback" v-if="errors.telefone">
										<p>{{ errors.telefone_instituicao[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-3 -->
							<!-- end telefone_instituição -->

							<!-- cep_agente -->
							<div class="col-md-2">
								<div class="form-group">
									<label class="form-control-label" for="input-cep">CEP</label>
									<input type="text" id="input-cep" v-bind:class="{ 'is-invalid': errors.cep }"
										class="form-control" placeholder="CEP" v-model="cep" v-mask="'#####-###'"
										required="true" maxlength="9" @blur="verificarCEP('banco')">
									<div class="invalid-feedback" v-if="errors.cep">
										<p>{{ errors.cep[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-3 -->
							<!-- end cep_agente -->

							<!-- uf_agente -->
							<div class="col-md-1">
								<div class="form-group">
									<label class="form-control-label" for="input-estado">UF</label>
									<select id="input-estado" v-bind:class="{ 'is-invalid': errors.estado }"
										class="form-control" placeholder="UF" v-model="estado" required="true"
										@change="onChangeUf('agente')" :disabled="ufBloqueado">
										<option v-for="item in states">{{ item }}</option>
									</select>
									<div class="invalid-feedback" v-if="errors.estado">
										<p>{{ errors.estado[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-2 -->
							<!-- end uf_agente -->

							<!-- nome_municipio_agente -->
							<div class="col-md-4">
								<div class="form-group">
									<label class="form-control-label" for="input-cidade">Município</label>
									<!--Aplicado um if/else para caso o cep nao seja encontrado abra o campo para digitar
	o municipio  -->
									<template v-if="!cepValido && !municipio_ag">
										<!-- Digitar manualmente -->
										<input type="text" id="input_municipio_ag_manual"
											v-bind:class="{ 'is-invalid': errors.municipio_ag_manual }"
											class="form-control" placeholder="Insira a cidade Manualmente"
											v-model="municipio_ag_manual" required="true" maxlength="60">
									</template>
									<!-- fim -->
									<template v-else>
										<!-- Vai ser preenchido manualmente pela API -->
										<v-select id="mySelect" label="municipio" @input="setSelected"
											:options="municipios_ag" :reduce="municipio => municipio.codigo_origem"
											v-bind:class="{ 'is-invalid': errors.nome_municipio_agente }"
											v-model="municipio_ag" :disabled="municipioBloqueado"></v-select>
									</template>
									<!-- Fim -->
									<div class="invalid-feedback" v-if="errors.cidade">
										<p>{{ errors.cidade[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-4 -->
							<!-- end nome_municipio_agente -->

							<!-- nome_logradouro_agente -->
							<div class="col-md-5">
								<div class="form-group">
									<label class="form-control-label" for="input-endereco">Endereço</label>
									<input type="text" id="input-endereco"
										v-bind:class="{ 'is-invalid': errors.endereco }" class="form-control"
										placeholder="Endereço" v-model="endereco" required="true" maxlength="30">
									<div class="invalid-feedback" v-if="errors.endereco">
										<p>{{ errors.endereco[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-6 -->
							<!-- end nome_logradouro_agente -->

							<!-- num_imovel_agente -->
							<div class="col-md-2">
								<div class="form-group">
									<label class="form-control-label" for="input-numero">Nº</label>
									<input type="text" id="input-numero" v-bind:class="{ 'is-invalid': errors.numero }"
										class="form-control" placeholder="Nº" v-model="numero" required="true"
										maxlength="5">
									<div class="invalid-feedback" v-if="errors.numero">
										<p>{{ errors.numero[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-2 -->
							<!-- end num_imovel_agente -->

							<!-- bairro_agente -->
							<div class="col-md-5">
								<div class="form-group">
									<label class="form-control-label" for="input-bairro">Bairro</label>
									<input type="text" id="input-bairro" v-bind:class="{ 'is-invalid': errors.bairro }"
										class="form-control" placeholder="Bairro" v-model="bairro" required="true"
										maxlength="100">
									<div class="invalid-feedback" v-if="errors.bairro">
										<p>{{ errors.bairro[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-3 -->
							<!-- end bairro_agente -->

							<!-- complemento_imovel_agente -->
							<div class="col-md-3">
								<div class="form-group">
									<label class="form-control-label" for="input-complemento">Complemento</label>
									<input type="text" id="input-complemento"
										v-bind:class="{ 'is-invalid': errors.complemento }" class="form-control"
										placeholder="Complemento" v-model="complemento" required="true" maxlength="20">
									<div class="invalid-feedback" v-if="errors.complemento">
										<p>{{ errors.complemento[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-4 -->
							<!-- end complemento_imovel_agente -->

							<!-- codigo SERPRO-->
							<div class="col-md-2">
								<div class="form-group">
									<!-- <div class="form-group" v-if="!cepValido"></div> -->
									<label class="form-control-label" for="input_cod_serpro">Cód
										SERPRO</label>
									<input type="text" id="input_cod_serpro"
										v-bind:class="{ 'is-invalid': errors.cod_serpro }" class="form-control"
										placeholder="Código" v-model="cod_serpro" required="true" maxlength="4"
										:disabled="serproBloqueado">
									<div class="invalid-feedback" v-if="errors.cep_devedor">
										<p>{{ errors.cod_serpro }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-2 -->
							<!-- end codigo SERPRO -->

							<!-- token_id -->
							<div v-show="false" class="col-md-2">
								<div class="form-group">
									<label class="form-control-label" for="input-token_id">Token ID</label>
									<div class="input-group mb-3">
										<input :type="inputType" id="input-token_id"
											v-bind:class="{ 'is-invalid': errors.token_id }" class="form-control"
											placeholder="Token ID" v-model="token_id" required="true" disabled>
										<div class="input-group-prepend">
											<button class="btn btn-outline-secondary" type="button"
												@click="changeInputView()"><i :class="inputIcon"></i></button>
										</div>
									</div>

									<div class="invalid-feedback" v-if="errors.token_id">
										<p>{{ errors.token_id[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-6 -->
							<!-- end token_id -->

							<!-- token_api -->
							<div v-show="false" class="col-md-5">
								<div class="form-group">
									<label class="form-control-label" for="input-token_api">Token da
										API</label>
									<div class="input-group mb-3">
										<input :type="inputType" id="input-token_api"
											v-bind:class="{ 'is-invalid': errors.token_api }" class="form-control"
											placeholder="Token da API" v-model="token_api" required="true" disabled>
										<div class="input-group-prepend">
											<button class="btn btn-outline-secondary" type="button"
												@click="changeInputView()"><i :class="inputIcon"></i></button>
										</div>
									</div>
									<div class="invalid-feedback" v-if="errors.token_api">
										<p>{{ errors.token_api[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-6 -->
							<!-- end token_api -->

							<!-- module -->
							<div class="col-md-3">
								<div class="form-group">
									<label class="form-control-label" for="input-modulo">Módulo</label>
									<select id="input-modulo" v-bind:class="{ 'is-invalid': errors.modulo }"
										class="form-control" v-model="modulo">
										<option value="Registro">Registro</option>
										<!-- <option value="Gestão">Automatização</option> -->
									</select>
									<div class="invalid-feedback" v-if="errors.modulo">
										<p>{{ errors.modulo[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-3 -->
							<!-- end module -->

							<!-- white label -->
							<div class="col-md-3">
								<div class="form-group">
									<label class="form-control-label" for="input-white_label">White Label</label>
									<select id="input-white_label" v-bind:class="{ 'is-invalid': errors.white_label }"
										class="form-control" v-model="white_label">
										<option value="false">Não</option>
										<!-- <option value="true">Sim</option> -->
									</select>
									<div class="invalid-feedback" v-if="errors.white_label">
										<p>{{ errors.white_label[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-3 -->
							<!-- end white label -->

							<!-- token_id -->
							<div class="col-md-2" v-show="false">
								<div class="form-group">
									<label class="form-control-label" for="input-token_id">Token ID</label>
									<input type="text" id="input-token_id"
										v-bind:class="{ 'is-invalid': errors.token_id }" class="form-control"
										placeholder="Token ID" v-model="token_id" required="true">
									<div class="invalid-feedback" v-if="errors.token_id">
										<p>{{ errors.token_id[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-6 -->
							<!-- end token_id -->

							<!-- token_api -->
							<div class="col-md-8" v-show="false">
								<div class="form-group">
									<label class="form-control-label" for="input-token_api">Token da API</label>
									<input type="text" id="input-token_api"
										v-bind:class="{ 'is-invalid': errors.token_api }" class="form-control"
										placeholder="Token da API" v-model="token_api" required="true">
									<div class="invalid-feedback" v-if="errors.token_api">
										<p>{{ errors.token_api[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-6 -->
							<!-- end token_api -->

						</div>
					</div>

				</div><!-- card body -->
			</div><!-- card -->

			<!-- detran -->
			<div class="card">

				<!-- card body -->
				<div class="card-body">

					<h6 class="heading-small text-muted mb-4">Detran (Financeiro)</h6>
					<div class="row">

						<!-- detran -->
						<div class="col-md-2">
							<div class="form-group">
								<label class="form-control-label" for="detran">Detran</label>
								<select id="detran" class="form-control" v-model="detran.detran_id" required="true">
									<option v-for="d in detrans" :value="d.id" v-bind:key="d.id">{{ d.estado }}</option>
								</select>
							</div><!-- form group -->
						</div><!-- col-md-2 -->

						<!-- custo_contrato -->
						<div class="col-md-2">
							<div class="form-group">
								<label class="form-control-label" for="input-custo_contrato">Custo Registro</label>
								<input type="number" id="input-custo_contrato" class="form-control"
									placeholder="Custo Contrato (R$)" v-model="detran.custo_contrato" required="true">
							</div><!-- form group -->
						</div><!-- col-md-3 -->

						<div class="col-md-2">
							<div class="form-group">
								<label class="form-control-label" for="input-aditivo">Custo Aditivo</label>
								<input type="number" id="input-aditivo" class="form-control"
									placeholder="Custo Aditivo (R$)" v-model="detran.custo_aditivo" required="true">
							</div><!-- form group -->
						</div><!-- col-md-3 -->
						<!-- end custo_contrato -->

						<!-- valor_contrato -->
						<div class="col-md-2">
							<div class="form-group">
								<label class="form-control-label" for="input-valor_contrato">Valor Registro</label>
								<input type="number" id="input-valor_contrato" class="form-control"
									placeholder="Valor Contrato (R$)" v-model="detran.valor_contrato" required="true">
							</div><!-- form group -->
						</div><!-- col-md-3 -->
						<!-- end valor_contrato -->

						<!-- payment_method -->
						<div class="col-md-3">
							<div class="form-group">
								<label class="form-control-label" for="input-payment_method">Método Pagamento</label>
								<select id="input-payment_method" class="form-control" v-model="detran.payment_method"
									required="true">
									<option value="individual">Individual</option>
									<option value="semanal">Semanal</option>
									<option value="quinzenal">Quinzenal</option>
									<option value="mensal">Mensal</option>
								</select>
							</div><!-- form group -->
						</div><!-- col-md-2 -->
						<!-- end payment_method -->

						<!-- payment day -->
						<div class="col-md-1">
							<div class="form-group">
								<label class="form-control-label" for="input-payment_day">Dia</label>
								<input type="text" id="input-payment_day" class="form-control" placeholder="Dia"
									v-model="detran.payment_day" required>
							</div><!-- form group -->
						</div><!-- col-md-2 -->
						<!-- end payment day -->

						<!-- payment day limit -->
						<div class="col-md-2">
							<div class="form-group">
								<label class="form-control-label" for="input-payment_day_limit">Limite (dias)</label>
								<input type="number" id="input-payment_day_limit" class="form-control"
									placeholder="Limite (dias)" v-model="detran.payment_day_limit" required>
							</div><!-- form group -->
						</div><!-- col-md-2 -->
						<!-- end payment day limit -->

						<div class="col-md-2">
							<div class="form-group">
								<label class="form-control-label" for="input-fatura_auto">Fatura Automática?</label>
								<select id="input-fatura_auto" class="form-control" v-model="detran.fatura_auto"
									required="true">
									<option value="1">Sim</option>
									<option value="2">Não</option>
								</select>
							</div><!-- form group -->
						</div><!-- col-md-2 -->

						<!-- begin Codigo do agente-->
						<div class="col-md-4">
							<div class="form-group">
								<label for="input-codigo_agente" class="form-control-label">Código do Agente</label>
								<input type="text" id="input-codigo_agente" maxlength="20" class="form-control"
									placeholder="Código do Agente" v-model="detran.codigo_agente" required>
							</div>

						</div>
						<!--end codigo agente-->


						<!-- begin vigencia_credenciamento-->
						<div class="col-md-3">
							<div class="form-group">
								<label for="input-vigencia_credenciamento" class="form-control-label">Vigência do
									Credenciamento</label>
								<input type="number" id="input-vigencia_credenciamento" class="form-control"
									placeholder="Vigência do Credenciamento" v-model="detran.vigencia_credenciamento"
									required="false">
							</div>

						</div>
						<!--end vigencia_credenciamento-->

						<div class="col-md-1">
							<div class="form-group" style="padding-top: 5px;">
								<label class="form-control-label">&nbsp;</label>
								<button @click="saveBancoDetran" class="btn btn-outline-primary mt-4"><i
										:class="icon"></i></button>
							</div>
						</div>

					</div>

					<hr />

					<!-- list -->
					<div class="table-responsive pb-4">
						<table class="table table-striped">
							<thead>
								<tr>
									<th class="p-2">Detran</th>
									<th class="p-2">Custo Registro</th>
									<th class="p-2">Custo Aditivo</th>
									<th class="p-2">Valor Registro</th>
									<th class="p-2">Método de Pagamento</th>
									<th class="p-2">Dia</th>
									<th class="p-2">Limite (dias)</th>
									<th class="p-2">Código do agente</th>
									<th class="p-2">Criado em</th>
									<th class="text-left p-2">Ações</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(item, index) in banco_detrans" :key="index">
									<td class="p-2">{{ item.detran.estado }}</td>
									<td class="p-2 text-right">{{ new Intl.NumberFormat('pt-BR', {
										style: 'currency',
										currency: 'BRL'
									}).format(item.custo_contrato) }}</td>
									<td class="p-2 text-right">{{ new Intl.NumberFormat('pt-BR', {
										style: 'currency',
										currency: 'BRL'
									}).format(item.custo_aditivo) }}</td>
									<td class="p-2 text-right">{{ new Intl.NumberFormat('pt-BR', {
										style: 'currency',
										currency: 'BRL'
									}).format(item.valor_contrato) }}</td>
									<td class="p-2 text-center">{{ item.payment_method }}</td>
									<td class="p-2 text-center">{{ item.payment_day }}</td>
									<td class="p-2 text-center">{{ item.payment_day_limit }}</td>
									<td class="p-2 text-center">{{ item.codigo_agente }}</td>
									<td class="p-2">{{ item.created_at | moment('DD/MM/YYYY HH:mm:ss') }}</td>
									<td class="p-2 text-left">
										<div style="width: 111% !important;">
											<button class="btn btn-sm btn-outline-secondary" @click="editData(item)"><i
													class="far fa-edit"></i></button>
											<button class="btn btn-sm btn-outline-danger"
												@click="deleteData(item.id, index)"><i
													class="far fa-trash-alt"></i></button>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div v-if="!banco_detrans.length && !loading" class="text-center p-3 text-muted">
						<h5>Nenhum Detran vinculado!</h5>
					</div>
					<!-- end list -->

				</div><!-- card body -->
			</div>
			<!-- end detran -->

			<!-- users -->
			<div class="row">
				<div class="col-12">

					<!-- table -->
					<div class="card p-4">
						<h6 class="heading-small text-muted mb-4">Usuários</h6>
						<div class="table-responsive pb-4">
							<table class="table table-striped table-flush bg-white" id="datatable">
								<thead class="thead-light">
									<tr>
										<th>Nome</th>
										<th>E-mail</th>
										<th>Criado em</th>
										<th class="text-center">Ações</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(item, index) in users" :key="index">
										<td>{{ validateName(item) }}</td>
										<td>{{ item.email }}</td>
										<td>{{ item.created_at | moment('DD/MM/YYYY HH:mm:ss') }}</td>
										<td class="text-right">
											<div class="text-center">
												<button class="btn btn-sm btn-outline-secondary"
													@click="editUser(item.id)"><i class="far fa-edit"></i></button>
												<!-- <button class="btn btn-sm btn-outline-danger" @click="deleteData(item.id, index)"><i class="far fa-trash-alt"></i></button> -->
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div v-if="!users.length && !loading" class="text-center p-3 text-muted">
							<h5>Nenhum usuário(a) encontrado(a)!</h5>
						</div>
					</div>
					<!-- end table -->

				</div>
			</div><!-- row -->
			<!-- end users -->

		</div><!-- col-12 -->

		<vue-loading color="#ec2423" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
	</div><!-- row -->
</template>

<script>
export default {
	props: ["bancos"],
	data() {
		return {
			states: ["Selecione...", "AC", "AL", "AM", "AP", "BA", "CE", "DF", "ES", "GO", "MA", "MT", "MS", "MG", "PA", "PB", "PR", "PE", "PI", "RJ", "RN", "RO", "RS", "RR", "SC", "SE", "SP", "TO"],
			cnpj: this.getData("cnpj"),
			ddd: this.getData("ddd"),
			cod_serpro: this.getData("cidade"),
			complemento: this.getData("complemento"),
			bairro: this.getData("bairro"),
			numero: this.getData("numero"),
			endereco: this.getData("endereco"),
			municipio_ag_manual: "",
			telefone_instituicao: this.getData("telefone"),
			municipios_ag: [],
			municipio_ag: '',
			estado: this.getData("estado"),
			serproBloqueado: true,
			ufBloqueado: false,
			cepValido: false,
			cep: this.getData("cep"),
			detrans: [],
			nome: this.getData("nome"),
			nome_fantasia: this.getData("nome_fantasia"),
			modulo: this.getData("modulo"),
			token_api: this.getData("token_api"),
			token_id: this.getData("token_id"),
			// tipo_instituicao: this.getData("tipo"),
			banco_detrans: this.getData('detrans'),
			white_label: this.getData('white_label'),
			detran: {
				detran_id: '',
				custo_contrato: '',
				custo_aditivo: '',
				valor_contrato: '',
				payment_method: '',
				payment_day: '',
				payment_day_limit: '',
				codigo_agente: '',
				vigencia_credenciamento: '',
			},
			isDetranEdit: false,
			detranID: null,
			email: this.getData("email"),
			password: "**********",
			errors: [],
			message: "",
			loading: false,
			fullPage: true,
			icon: 'fas fa-plus',
			users: [],
			columns: [
				{
					label: "Nome",
					field: "profile.name",
				},
				{
					label: "E-mail",
					field: "email",
				},
				{
					label: "Ações",
					field: "actions",
					html: true,
				},
			],
		}
	},
	mounted() {
		this.loadDetrans();
		this.loadUsers();
		this.verificarCEP();
	},
	methods: {
		getData(key) {
			return JSON.parse(this.bancos)[key];
		},
		handleFileUpload(field) {
			this.field = this.$refs.field.files[0];
		},
		updateData() {
			let _this = this;
			_this.errors = [];
			_this.message = "";
			_this.loading = true;
			let dataInfo = {
				"telefone": _this.telefone_instituicao,
				"cnpj": _this.cnpj == '' ? 'null' : _this.cnpj,
				"ddd": _this.ddd,
				"cep": _this.cep,
				"estado": _this.estado,
				"cidade": _this.cod_serpro,
				"endereco": _this.endereco,
				"numero": _this.numero,
				"bairro": _this.bairro,
				"complemento": _this.complemento,
				"nome": _this.nome,
				"nome_fantasia": _this.nome_fantasia,
				// "tipo_instituicao": _this.tipo_instituicao,
				"modulo": _this.modulo,
				"white_label": _this.white_label,
				"token_api": _this.token_api,
				"token_id": _this.token_id,

				"_method": "PATCH"
			};
			axios.post("/admin/bancos/" + this.getData('id'), dataInfo).then((res) => {

				_this.loading = false;

				//show confirm alert
				_this.$swal({
					title: "Sucesso",
					text: "Instituição Financeira atualizada com sucesso!",
					icon: "success"
				});
			}).then(() => location.href = "/admin/bancos")
				.catch((err) => {

					_this.loading = false;
					if (err.response.status == 422) {
						let errors = err.response.data.errors

						var errorFields = Object.keys(errors).map((key) => {
							switch (key) {
								case 'nome':
									return `O campo Nome da instituição não pode ser vazio`;
								case 'token_api':
									return `O campo Token API não pode ser vazio`;
								case 'token_id':
									return `O campo Token ID não pode ser vazio ou conter letras`;
								case 'tipo_instituicao':
									return `Tipo de instituição não pode ser vazio`;
								case 'email':
									return `O campo email não pode ser vazio ou está inválido`;
								case 'password':
									return `O campo Senha não pode ser vazio ou está inválido`;
								case 'nome_usuario':
									return `O campo nome do usuário não pode ser vázio`;
								case 'cpf':
									return `O campo CPF não pode ser vazio ou está inválido`;
								case 'ddd_profile':
									return `O campo DDD não pode ser vazio ou maior que dois caracteres`;
								case 'telefone':
									return `O campo telefone precisa conter 10 números`;
								case 'funcao':
									return `O campo função não pode ser vazio`;
								case 'cep':
									return `O campo CEP não pode ser vazio`;
								case 'estado':
									return `O campo UF não pode ser vazio`;
								case 'cidade':
									return `O campo Município não pode ser vazio`;
								case 'endereco':
									return `O campo Endereço não pode ser vazio`;
								case 'bairro':
									return `O campo Bairro não pode ser vazio`;
								case 'cnpj':
									return `O campo CNPJ não pode ser vazio ou está inválido`;
							}
						}).join(' <br> ');

						_this.$swal({
							title: 'Erro',
							html: errorFields,
							icon: 'error'
						});
					} else {
						_this.$swal({
							title: "Erro",
							text: "Falha ao atualizar instituição financeira. Tente novamente!",
							icon: "error"
						});
					}
				});
		},
		loadDetrans() {
			let _this = this;
			_this.loading = true;
			axios.get("/admin/detrans").then((res) => {
				_this.detrans = _this.detrans.concat(res.data);
				_this.loading = false;
			}).catch((err) => {
				_this.loading = false;
			});
		},
		async verificarCEP() {
			let cep = this.cep.replace('-', '');
			try {
				const response = await axios.get(`/admin/consultacep/${cep}`);
				const data = response.data;

				// Se o CEP foi encontrado, preenche os campos de endereço e bloqueia os campo SERPRO,UF e Municipio
				this.cod_serpro = data.codigo_serpro != "" ? data.codigo_serpro : "";
				this.cepValido = true;
				this.serproBloqueado = data.codigo_serpro != "" ? true : false;
				this.endereco = data.logradouro;
				this.bairro = data.bairro;
				this.municipio_ag = data.localidade;
				this.estado = data.uf;
				this.ufBloqueado = true;
				this.municipioBloqueado = true;
				this.dadosApi = data.codigo_serpro != "" ? true : false;
				this.onChangeUf('agente');
			} catch (error) {
				this.cod_serpro = ''
				this.cepValido = false;
				this.serproBloqueado = false;
				this.endereco = '';
				this.bairro = '';
				this.municipio_ag = '';
				this.estado = '';
				this.ufBloqueado = false;
				this.municipioBloqueado = false;
			}
		},
		gravaInfoMunicipio() {
			let nomeMunDev = "";

			if (this.municipio_ag_manual != "") { // Se o campo de município foi preenchido manualmente
				nomeMunDev = this.municipio_ag_manual;
			} else { // Caso contrário, encontra o nome do município selecionado no v-select
				let arr = this.municipios_ag;
				for (let i = 0; i < arr.length; i++) {

					if (arr[i]['municipio'] === this.municipio_ag) {
						nomeMunDev = arr[i]['municipio'];
						break;
					}
				}
			}
		},

		onChangeUf(typeSearch) {
			let _this = this;
			var estado;

			if (typeSearch == 'agente') {
				estado = _this.estado
			} else if (typeSearch == 'user') {
				estado = _this.uf_profile
			} else {
				estado = _this.userUf
			}

			axios.get("/api/portal/detrancidades/search/" + estado).then((res) => {
				if (typeSearch == 'agente') {
					_this.municipios_ag = res.data;

				} else if (typeSearch == 'user') {
					_this.municipios_profile = res.data;

				} else {
					_this.municipios_user = res.data;
				}
			}).catch((err) => {
				_this.$swal({
					title: "Erro",
					text: "Falha ao pesquisar o estao: " + estado + " tente novamente!",
					icon: "error"
				});
			});
		},

		setSelected(item) {
			let _this = this;
			let codigo = item;
			_this.cidade = codigo;
			// _this.municipio_dev = _this.municipios_dev.municipio
		},
		saveBancoDetran() {

			//validate if new
			if (this.isDetranEdit == false) {

				//new
				let _this = this;
				_this.errors = [];
				_this.message = "";
				_this.loading = true;
				let dataInfo = {
					"detran": _this.detran.detran_id,
					"banco": _this.getData('id'),
					"custo_contrato": _this.detran.custo_contrato,
					"custo_aditivo": _this.detran.custo_aditivo,
					"valor_contrato": _this.detran.valor_contrato,
					"payment_method": _this.detran.payment_method,
					"payment_day": _this.detran.payment_day,
					"payment_day_limit": _this.detran.payment_day_limit,
					"codigo_agente": _this.detran.codigo_agente,
					"vigencia_credenciamento": _this.detran.vigencia_credenciamento
				};
				axios.post("/admin/detran_banco", dataInfo).then((res) => {

					_this.loading = false;

					//clear form
					_this.detran = {
						detran_id: '',
						custo_contrato: '',
						custo_aditivo: '',
						valor_contrato: '',
						payment_method: '',
						payment_day: '',
						payment_day_limit: '',
						codigo_agente: '',
						vigencia_credenciamento: ''
					};
					//show confirm alert
					_this.$swal({
						title: "Sucesso",
						text: "Detran vinculado com sucesso!",
						icon: "success",
					}).then(function () {
						location.reload();
					});
				})
					.catch((err) => {
						_this.loading = false;

						//show error alert
						_this.$swal({
							title: "Erro",
							text: "Falha ao vincular detran. Tente novamente!",
							icon: "error"
						});
					});

			} else {

				//edit
				let _this = this;
				_this.errors = [];
				_this.message = "";
				_this.loading = true;
				let dataInfo = {
					"detran": _this.detranID,
					"banco": _this.getData('id'),
					"custo_contrato": _this.detran.custo_contrato,
					"custo_aditivo": _this.detran.custo_aditivo,
					"valor_contrato": _this.detran.valor_contrato,
					"payment_method": _this.detran.payment_method,
					"payment_day": _this.detran.payment_day,
					"payment_day_limit": _this.detran.payment_day_limit,
					"codigo_agente": _this.detran.codigo_agente,
					"vigencia_credenciamento": _this.detran.vigencia_credenciamento,
					"_method": "PATCH"
				};

				axios.post("/admin/detran_banco/" + _this.detranID, dataInfo).then((res) => {

					_this.loading = false;
					_this.isDetranEdit = false;
					_this.detranID = null;
					_this.icon = "fas fa-plus";

					//show confirm alert
					_this.$swal({
						title: "Sucesso",
						text: "Vínculo com Detran atualizado com sucesso!",
						icon: "success"
					}).then(function () {
						location.reload();
					});
				})
					.catch((err) => {

						_this.loading = false;

						//show error alert
						_this.$swal({
							title: "Erro",
							text: "Falha ao atualizar vínculo com detran. Tente novamente!",
							icon: "error"
						});
					});

			}
			//end valida if new

		},
		deleteData(ID, index) {
			let _this = this;

			//show alert confirmation
			_this.$swal({
				title: "Excluir Detran vinculado",
				text: "Tem certeza que deseja remover o vínculo com esse Detran?",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "Excluir",
				cancelButtonText: "Cancelar"
			}).then((alertConfirm) => {

				//validate isConfirmed
				if (alertConfirm.isConfirmed) {

					//remove user
					axios.delete("/admin/detran_banco/" + ID).then((res) => {

						_this.banco_detrans.splice(index, 1);
						//_this.loadItems();

						//show confirm alert
						_this.$swal({
							title: "Sucesso",
							text: "Vínculo removido com sucesso!",
							icon: "success"
						});

					}).catch(error => {

						//show error alert
						_this.$swal({
							title: "Erro",
							text: "Falha ao remover o vínculo. Tente novamente!",
							icon: "error"
						});

					});

				} else {

					//only close

				}//end validate isConfirmed

			});
			//end alert confirmation

		},
		editData(item) {
			this.detran.
				detran_id = item.detran_id;
			this.detran.
				custo_contrato = item.custo_contrato;
			this.detran.
				valor_contrato = item.valor_contrato;
			this.detran.
				payment_method = item.payment_method;
			this.detran.
				payment_day = item.payment_day;
			this.detran.
				payment_day_limit = item.payment_day_limit;
			this.detran.
				codigo_agente = item.codigo_agente;
			this.detran.
				vigencia_credenciamento = item.vigencia_credenciamento;
			this.isDetranEdit = true;
			this.detranID = item.id;
			this.icon = "far fa-save";
			document.getElementById('detran').disabled = true;

		},
		loadUsers() {

			let _this = this;
			_this.loading = true;
			axios.get('/admin/users/porempresa/' + _this.getData('id')).then((res) => {
				_this.users = _this.users.concat(res.data);
				_this.loading = false;
			}).catch((err) => {
				_this.loading = false;
			});
		},
		editUser(ID) {
			location.href = "/admin/users/" + ID + "/edit";
		},
		validateName(user) {
			if (user.profile) {
				return user.profile.name;
			} else {
				return "";
			}
		}

	}
}
</script>
