<template>

	<div class="card p-4">
		<div class='col'> 
			<a href="#" class="btn btn-success float-right btn-import" @click="importCsv()"><i class="far fa-file-excel"></i> UPLOAD</a></div>
		<div class="row">
			<div class="col">
				
				<!-- table -->
				<div v-if="!loading && detranCidades.length > 0">
					
					<vue-good-table
						:columns="columns"
						:rows="detranCidades"
						:pagination-options="{
							enabled: true,
							mode: 'records',
							position: 'bottom',
							dropdownAllowAll: true,
							perPageDropdown: [10, 30, 50],
							nextLabel: 'Próximo',
							prevLabel: 'Anterior',
							rowsPerPageLabel: '',
							ofLabel: 'de',
							allLabel: 'Todos',
							pageLabel: '',
							perPageDropdownEnabled: true,
							compactMode: true,
						}"
						:search-options="{
							enabled: true,
							placeholder: 'Search this table',
						}"
						styleClass="vgt-table"
						>
						<template slot="table-row" slot-scope="props">
							<span v-if="props.column.field == 'actions'">
							<div class="text-center">
								<button class="btn btn-sm btn-outline-secondary" @click="editData(props.row.id)"><i class="far fa-edit"></i></button>
								<button class="btn btn-sm btn-outline-danger" @click="deleteData(props.row.id, props.row.index)"><i class="far fa-trash-alt"></i></button>
							</div>
							</span>
							<span v-else>
							{{ props.formattedRow[props.column.field] }}
							</span>
					</template>
				</vue-good-table>
				</div>
				<div v-if="!detranCidades.length && !loading" class="text-center p-3 text-muted">
					<h5>Nenhum município encontrado!</h5>
				</div>
				<!-- end table -->
				<div class="text-center p-4 text-muted" v-if="loading">
					<h5>Carregando...</h5>
					<p>Aguarde até que os dados sejam carregados...</p>
				</div>
			</div><!-- col -->
		</div><!-- row -->
        
    <!-- modal  -->
    <div class="modal fade" id="modalImportCsv" tabindex="-1" aria-labelledby="modalImportCsvLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalImportCsvLabel">Importar arquivo CSV</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form method="POST" v-on:submit.prevent="excelMunicipios">

              <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label class="form-control-label">Anexe o Excel com os estados, municípios e codigo serpro</label>
                        <input type="file" class="form-control" id="attach" name="attach" ref="attach" lang="pt" v-on:change="handleFileUpload()"/>
                    </div>
                    <div class="text-right">
											<button type="submit" class="btn btn-primary">IMPORTAR</button>
										</div>
                </div>
              </div>            
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- end modal -->
		<vue-loading color="#ec2423" :active.sync="loading" :can-cancel="false"></vue-loading>
	</div><!-- card -->
</template>
<script>
	import "vue-good-table/dist/vue-good-table.css";
	import { VueGoodTable } from "vue-good-table";

	export default {
		data() {
			return {
				detranCidades: [],
				loading: false,
				columns: [
					{
					label: "Estado",
					field: "uf",
					},
					{
					label: "Município",
					field: "municipio",
					},
					{
					label: "Código Serpro",
					field: "codigo_origem",
					},
					{
					label: "Ações",
					field: "actions",
					html: true,
					},
				],
                attach: "",
			}
		},
		mounted() {
			this.loadItems();
			// console.log(this.detranCidades);
		},
		methods: {
			loadItems() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/detranCidades").then((res) => {
					// console.log(res.data);
					_this.detranCidades = _this.detranCidades.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			deleteData(id, index) {
				let _this = this;

				//show alert confirmation
				_this.$swal({
					title: "Excluir Município",
					text: "Tem certeza que deseja excluir este município?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonText: "Excluir",
					cancelButtonText: "Cancelar"
				}).then((alertConfirm) => {

					//validate isConfirmed
					if (alertConfirm.isConfirmed) {

						//remove user
						axios.delete("/admin/detranCidades/"+id).then((res) => {

							_this.detranCidades.splice(index, 1);
							_this.total = _this.total - 1;
							//_this.loadItems();

							//show confirm alert
							_this.$swal({
								title: "Sucesso",
								text: "Município excluído com sucesso!",
								icon: "success"
							});

						}).catch(error => {

							//show error alert
							_this.$swal({
								title: "Erro",
								text: "Falha ao excluir este município. Tente novamente!",
								icon: "error"
							});

						});

					} else {

						//only close

					}//end validate isConfirmed

				});
				//end alert confirmation

			},
			handleFileUpload(){

				if (this.$refs.attach.files[0].size > 5120 * 5120) {
					this.$swal({
						title: "Erro",
						html: "Arquivo precisa ter no máximo 5mb de tamanho!",
						icon: "error"
					});

					return;
				} else {

					this.attach = this.$refs.attach.files[0];

				}

			},
			editData(id){
				location.href="/admin/detranCidades/"+id+"/edit";
			},
			formatDate: function (value) {
				return moment(String(value)).format("DD/MM/YYYY");
			},
			formatCurrency: function(value){
				return Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(value);
			},
			importCsv(){
				$("#modalImportCsv").modal('show');
			},
      excelMunicipios(){
				let _this = this;
				let formData = new FormData();
				formData.append('arquivo', _this.attach);
          axios.post('/admin/detranCidades/salvaMunicipios', formData, {headers: {'Content-Type': 'multipart/form-data'}}).then((res) => { 

						$("#modalImportCsv").modal('hide');

						//show confirm alert
						if(!res.data.erro){
							_this.$swal({
								title: 'Sucesso',
								text: 'Municipios salvos com sucesso',
								icon: 'success'
							});
						}else{
							_this.$swal({
								title: 'Erro',
								text: res.data.erro,
								icon: 'error'
							});
						}
						_this.loadItems();
						// location.reload();
                }).catch((err) => {

                    _this.loading = false;

                    //show error alert
                    _this.$swal({
                        title: 'Erro',
                        text: 'Falha ao salvar municípios. Tente novamente!',
                        icon: 'error'
                    });
                });
                
            }
		},
		components: {
    		VueGoodTable,
		}
	}
</script>
