<template>
	<div class="row">
		<div class="col-12">

			<!-- widgets -->
			<div class="row">

				<!-- total a pagar detran -->
				<div class="col-md-6">
					<div class="card">
						<div class="card-body">
							<div class="summary">
								<div class="summary-info">
									<h4>{{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totalValorDetran)
									}}
									</h4>
									<div class="text-muted text-primary">Total a Pagar Detran</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- end total a pagar detran -->

				<!-- total receber instituição -->
				<div class="col-md-6">
					<div class="card">
						<div class="card-body">
							<div class="summary">
								<div class="summary-info">
									<h4>{{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totalValorResult)
									}}
									</h4>
									<div class="text-muted text-primary">Total a Receber Instituição</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- end total receber instituição -->

			</div>
			<!-- dashboard end -->

			<!-- filter start -->
			<div class="card col-12">
				<div class="card-header">
					<!-- estado -->
					<div class="card-header-action dropdown btn-filter col-md-2 col-lg-2 col-sm-12 mt-2">
						<a href="#" data-toggle="dropdown" class="btn btn-light dropdown-toggle">Detrans</a>
						<ul class="dropdown-menu dropdown-menu-sm dropdown-menu-left dropdown-filter">
							<li class="dropdown-title">Selecione os Detrans</li>
							<li v-if="!estadoOptions.length">
								<div class="text-center">
									<div class="spinner-border spinner-border-sm text-danger mt-1 mb-1" role="status">
										<span class="sr-only">Loading...</span>
									</div>
								</div>
							</li>
							<li v-for="option in estadoOptions" :key="option.id">
								<div class="form-check custom-checkbox custom-control ml-3">
									<input class="form-check-input" type="checkbox" :value="option.id" v-model="checkedDetrans"
										:id="option.id" />
									<label class="form-check-label" :for="option.id">
										{{ option.label }}
									</label>
								</div>
							</li>
							<div class="text-center mt-2">
								<a href="#" class="btn btn-danger col-md-3 btn-filter text-center" data-toggle="tooltip"
									data-placement="top" title="Filtrar" id="inicial" @click="updateItems">
									<i class="fas fa-filter"></i>
								</a>
							</div>
						</ul>
					</div>
					<!-- end estado -->

					<!--init filter instituicao-->
					<div class="card-header-action dropdown btn-filter col-md-2 col-lg-2 col-sm-12 mt-2">
						<a href="#" data-toggle="dropdown" class="btn btn-light dropdown-toggle">Instituições</a>
						<ul class="dropdown-menu dropdown-menu-sm dropdown-menu-left dropdown-filter">
							<li class="dropdown-title">Selecione as Instituições</li>
							<li v-if="!bancoOptions.length">
								<div class="text-center">
									<div class="spinner-border spinner-border-sm text-danger mt-1 mb-1" role="status">
										<span class="sr-only">Loading...</span>
									</div>
								</div>
							</li>
							<li v-for="option in bancoOptions" :key="option.id">
								<div class="form-check custom-checkbox custom-control ml-3">
									<input class="form-check-input" type="checkbox" :value="option.id" v-model="checkedInstituicoes"
										:id="option.id" />
									<label class="form-check-label" :for="option.id">
										{{ option.label }}
									</label>
								</div>
							</li>
							<div class="text-center mt-2">
								<a href="#" class="btn btn-danger col-md-3 btn-filter text-center" data-toggle="tooltip"
									data-placement="top" title="Filtrar" id="inicial" @click="updateItems">
									<i class="fas fa-filter"></i>
								</a>
							</div>
						</ul>
					</div>
					<!--end filter instituicao-->

					<!--filtro status-->
					<div class="card-header-action dropdown btn-filter col-md-2 col-lg-2 col-sm-12 mt-2">
						<a href="#" data-toggle="dropdown" class="btn btn-light dropdown-toggle">Status do Faturamento</a>
						<ul class="dropdown-menu dropdown-menu-sm dropdown-menu-left dropdown-filter">
							<li class="dropdown-title">Selecione o Status do Faturamento</li>
							<li v-if="!filterStatus.length">
								<div class="text-center">
									<div class="spinner-border spinner-border-sm text-danger mt-1 mb-1" role="status">
										<span class="sr-only">Loading...</span>
									</div>
								</div>
							</li>
							<li v-for="item in filterStatus" :key="item.id">
								<div class="form-check custom-checkbox custom-control ml-3">
									<input class="form-check-input" type="checkbox" :value="item.id" v-model="checkedStatusPagamentosDetran"
										:id="item.id" />
									<label class="form-check-label" :for="item.id">
										{{
											item.desc ? item.desc : "Erro"
										}}
									</label>
								</div>
							</li>
							<div class="text-center">
								<a href="#" class="btn btn-danger col-md-2 col-lg-2 col-sm-4 mt-2 btn-filter" data-toggle="tooltip"
									data-placement="top" title="Filtrar" id="inicial" @click="updateItems">
									<i class="fas fa-filter"></i>
								</a>
							</div>
						</ul>
					</div>
					<!--end filtro status-->

					<!-- filtro período -->
					<div class="card-header-action dropdown btn-filter col-md-2 col-lg-2 col-sm-12 mt-2">
						<a href="#" data-toggle="dropdown" class="btn btn-light dropdown-toggle">Periodo</a>
						<ul class="dropdown-menu dropdown-menu-sm  dropdown-filter">
							<li class="dropdown-title">Selecione o Período</li>
							<v-select v-model="selectedMonth" :options="months" placeholder="Selecione um mês" label="mesAno"
								:clearable="false" :value="selectedMonth" class="px-2" />
							<div class="text-center mt-2">
								<a href="#" class="btn btn-danger col-md-3 btn-filter text-center" data-toggle="tooltip"
									data-placement="top" title="Filtrar" id="inicial" @click="updateItems">
									<i class="fas fa-filter"></i>
								</a>
							</div>
						</ul>
					</div>
					<!-- end filtro período -->


					<!-- filtro desativado -->
					<div class="card-header-action dropdown btn-filter col-md-2 col-lg-2 col-sm-12 mt-2">

					</div>
					<!-- end filtro desativado

          <!-filter button -->
					<div class="col-md-2 col-lg-2 col-sm-12 text-center">
						<a href="#" class="btn btn-danger col-md-5 col-lg-5 col-sm-12 mt-2 btn-filter" data-toggle="tooltip"
							data-placement="top" title="Filtrar" id="inicial" @click="updateItems">
							<i class="fas fa-filter"></i>
						</a>
						<a href="#" class="btn btn-danger col-md-5 col-lg-5 col-sm-12 mt-2 btn-filter" data-toggle="tooltip"
							data-placement="top" title="Limpar Filtro" @click="clearFilter">
							<i class="fas fa-times"></i>
						</a>
					</div>
					<!-- end filter button -->
				</div>
			</div>
			<!-- filter end -->


			<!-- LIST FATURAMENTOS -->
			<div class="card">
				<div class="card-body">

					<!-- table -->
					<div class="pb-5" v-if="!loading && faturamentos.length > 0">

						<!-- export -->
						<div class="export-options w-auto">
							<a href="#" class="btn btn-danger btn-filter export-button" @click="exportDataGrid('pdf', 'Faturamento')">
								<i class="far fa-file-pdf"></i> PDF
							</a>
							<a href="#" class="btn btn-success btn-filter export-button" @click="exportDataGrid('xlsx', 'Faturamento')">
								<i class="far fa-file-excel"></i> EXCEL
							</a>
							<a class="btn btn-success btn-filter export-button" title="Gerar Remessa"
								:href="'/admin/relatorios/faturamentos/gerarremessa'">
								<i class="far fa-file-pdf"></i> REMESSA
							</a>
							<a href="#" class="btn btn-success btn-filter export-button" title="Gerar Remessa"
								@click="viewUploadRemessa()" style="width:180px;">
								<i class="far fa-file-pdf"></i> UPLOAD REMESSA
							</a>
						</div>
						<!-- end export -->

						<vue-good-table :columns="columns" :rows="faturamentos" :pagination-options="tablePagination" :search-options="{
							enabled: true,
							placeholder: 'Search this table',
						}" styleClass="vgt-table">

							<template slot="table-row" slot-scope="props">
								<span v-if="props.column.field == '_contratos'">
									<div>{{ props.row.contratos.length }}</div>
								</span>
								<span v-if="props.column.field == '_fechamento'">
									{{ formatShortDate(props.row.periodo_inicial) }} - {{ formatShortDate(props.row.periodo_final) }}
								</span>
								<span v-if="props.column.field == 'action'">
									<div class="dropdown">
										<button class="btn btn-outline-primary dropdown-toggle" type="button" id="dropdownActions"
											data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="Botão de ações">
											<span>Ações</span>
										</button>
										<ul class="dropdown-menu" aria-labelledby="dropdownActions">
											<a class="dropdown-item" title="Reenviar Contrato" @click="viewContracts(props.row)">
												<i class="fas fa-file-alt"></i>&nbsp;
												<span>Ver Contratos</span>
											</a>
											<a class="dropdown-item" title="Reenviar Contrato" @click="changeStatus(props.row)">
												<i class="fas fa-info"></i>&nbsp;&nbsp;
												<span>Alterar Status</span>
											</a>
											<a class="dropdown-item" title="Gerar Fatura" v-if="props.row.status == 1 || props.row.status == 4"
												@click="gerarFatura(props.row)">
												<i class="fas fa-file-alt"></i>&nbsp;
												<span>Gerar Fatura</span>
											</a>


											<hr />
											<a class="dropdown-item" title="Anexar Nota Fiscal" @click="changeAttach(props.row, 'notafiscal')">
												<i class="fas fa-barcode"></i>&nbsp;
												<span>Nota Fiscal</span>
											</a>
											<a class="dropdown-item" title="Visualizar Arquivo"
												:href="'/admin/relatorios/faturamentos/' + props.row.id + '/download/notafiscal'" target="_blank"
												v-if="props.row.nota_fiscal">
												<i class="fas fa-eye"></i>&nbsp;
												<span>Visualizar Nota Fiscal</span>
											</a>
											<a class="dropdown-item" title="Anexar Boleto" @click="changeAttach(props.row, 'boleto')">
												<i class="fas fa-barcode"></i>&nbsp;
												<span>Boleto Bancário</span>
											</a>
											<a class="dropdown-item" title="Visualizar Arquivo"
												:href="'/admin/relatorios/faturamentos/' + props.row.id + '/download/boleto'" target="_blank"
												v-if="props.row.boleto">
												<i class="fas fa-eye"></i>&nbsp;
												<span>Visualizar Boleto</span>
											</a>
											<hr />
											<span v-if="props.row.status == 3">
												<a class="dropdown-item" title="Gerar Cancelamento" @click="viewCancelamento(props.row)">
													<i class="fas fa-ban"></i>&nbsp;
													<span>Visualizar Cancelamento</span>
												</a>
											</span>
											<span v-if="props.row.status == 2">
												<a class="dropdown-item" title="Gerar Cancelamento" @click="cancelFatura(props.row)">
													<i class="fas fa-ban"></i>&nbsp;
													<span>Cancelar Fatura</span>
												</a>
											</span>
											<span v-if="props.row.status == 5">
												<a class="dropdown-item" title="Confirmar Pagamento" @click="viewPagamentoEfetuado(props.row)">
													<i class="fas fa-solid fa-file-invoice-dollar"></i>&nbsp;
													<span>Pagamento Realizado</span>
												</a>
											</span>
										</ul>
									</div>
								</span>
								<span v-else>
									{{ props.formattedRow[props.column.field] }}
								</span>
							</template>
						</vue-good-table>
					</div>
					<!-- end table -->

					<div v-if="!faturamentos.length && !loading" class="text-center p-3 text-muted">
						<h5>Nenhum faturamento encontrado!</h5>
					</div>

					<div class="text-center p-4 text-muted" v-if="loading">
						<h5>Carregando...</h5>
						<p>Aguarde até que os dados sejam carregados...</p>
					</div>

				</div>
			</div>
			<!-- END LIST CONTRACTS -->

			<!-- MODAL CONTRACTS -->
			<div class="modal fade" id="modalContracts" tabindex="-1" aria-labelledby="modalContractsLabel" aria-hidden="true">
				<div class="modal-dialog modal-lg modal-dialog-centered">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="modalContractsLabel">Contratos</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">

							<vue-good-table :columns="columnsContracts" :rows="contracts" :pagination-options="tablePagination"
								:search-options="{
									enabled: true,
									placeholder: 'Search this table',
								}" styleClass="vgt-table">

								<template slot="table-row" slot-scope="props">
									<span>{{ props.formattedRow[props.column.field] }}</span>
									<span v-if="props.column.field == '_status'">
										<div v-if="props.row.status_pagamento == 0">Novo</div>
										<div v-if="props.row.status_pagamento == 1">Previsão</div>
										<div v-if="props.row.status_pagamento == 2">Conciliado</div>
										<div v-if="props.row.status_pagamento == 3">Pendente</div>
										<div v-if="props.row.status_pagamento == 4">Cancelado</div>
										<div v-if="props.row.status_pagamento == 5">Pago</div>
									</span>

									<span v-if="props.column.field == 'action'">
										<div class="dropdown">
											<button class="btn btn-outline-primary dropdown-toggle" type="button" id="dropdownActions"
												data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="Botão de ações"
												@click="resized()">
												<span>Ações</span>
											</button>
											<ul class="dropdown-menu" aria-labelledby="dropdownActions">
												<span
													v-if="props.row.status_pagamento != 2 && props.row.status_pagamento != 5 && props.row.status_pagamento != 4 && props.row.status_pagamento != 3">
													<a class="dropdown-item" title="Gerar Conciliamento" @click="conciliarContratos(props.row, '1')"
														target="_blank">
														<i class="fas fa-file-download"></i>&nbsp;
														<span>Conciliar Contrato</span>
													</a>
												</span>
												<span v-if="props.row.status_pagamento == 2">
													<a class="dropdown-item" title="Desconciliar Contrato"
														@click="conciliarContratos(props.row, '2')">
														<i class="fas fa-ban"></i>&nbsp;
														<span>Desconciliar Contrato</span>
													</a>
												</span>
												<span
													v-if="props.row.status_pagamento == 2 || props.row.status_pagamento == 5 || props.row.status_pagamento == 4 || props.row.status_pagamento == 3">
													<a class="dropdown-item" title="Desconciliar contrato" disabled>
														<i class="fas fa-ban"></i>&nbsp;
														<span>Contrato Finalizado</span>
													</a>
												</span>
											</ul>
										</div>
									</span>
								</template>
							</vue-good-table>

						</div>
					</div>
				</div>
			</div>
			<!-- END MODAL CONTRACTS -->

			<!-- MODAL Cancelamento -->
			<div class="modal fade" id="modalCancelamento" tabindex="-1" aria-labelledby="modalCancelamentoLabel"
				aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered">
					<div class="modal-content">
						<div class="modal-header">
							Cancelar Fatura
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<div class="col-md-12">
								<div class="form-group">
									<label class="form-control-label">Motivo do Cancelamento</label>
									<textarea style="height: 90px" id="input-observacao" class="form-control"
										placeholder="Motivo do Cancelamento" v-model="observacao" maxlength="255">
                  </textarea>
								</div>
							</div>
							<div class="col-md-12 text-right">
								<button type="submit" class="btn btn-primary" @click="aprovarCancelamento(0)">REPROVAR</button>
								<button type="submit" class="btn btn-success" @click="aprovarCancelamento(1)">APROVAR</button>
							</div>

						</div>
					</div>
				</div>
			</div>
			<!-- END MODAL Cancelamento -->

			<!-- MODAL ALTERAR STATUS -->
			<div class="modal fade" id="modalAlterarStatus" tabindex="-1" aria-labelledby="modalAlterarStatusLabel"
				aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="modalAlterarStatusLabel">Alterar Status</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">

							<div class="row">
								<div class="col-md-12">
									<div class="form-group">
										<label>Escolha o status</label>
										<v-select v-model="newStatus" :options="filterStatus" label="desc"
											:reduce="desc => desc.id"></v-select>
									</div>
								</div>

								<div class="col-md-12 text-right">
									<a href="#" @click="saveStatus()" class="btn btn-primary">SALVAR</a>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
			<!-- END MODAL ALTERAR STATUS -->

			<!-- MODAL NOTA FISCAL -->
			<div class="modal fade" id="modalNotaFiscal" tabindex="-1" aria-labelledby="modalNotaFiscalLabel"
				aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="modalNotaFiscalLabel">Nota Fiscal</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">

							<div class="row">

								<div class="col-md-12">
									<div class="form-group">
										<label class="form-control-label">Selecione a Nota Fiscal</label>
										<input type="file" class="form-control" id="fileNotaFiscal" name="fileNotaFiscal" ref="fileNotaFiscal"
											lang="pt" v-on:change="handleFileUpload('notafiscal')" />
									</div>
								</div>

								<div class="col-md-12 text-right">
									<!-- <a href="#" class="btn btn-primary" @click="saveAttach('notafiscal')">SALVAR</a> -->
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
			<!-- END MODAL NOTA FISCAL -->

			<!-- MODAL BOLETO -->
			<div class="modal fade" id="modalBoleto" tabindex="-1" aria-labelledby="modalBoletoLabel" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="modalBoletoLabel">Boleto</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">

							<div class="row">

								<div class="col-md-12">
									<div class="form-group">
										<label class="form-control-label">Digite o Código de Barras</label>
										<input class="form-control" type="text" id="codigoBarras" name="codigoBarras" v-model="codigoBarras"
											required />
									</div>
								</div>

								<div class="col-md-12">
									<div class="form-group">
										<label class="form-control-label">Selecione o Boleto</label>
										<input type="file" class="form-control" id="fileBoleto" name="fileBoleto" ref="fileBoleto" lang="pt"
											v-on:change="handleFileUpload('boleto')" />
									</div>
								</div>

								<div class="col-md-12 text-right">
									<a href="#" class="btn btn-primary" @click="saveAttach('boleto')">SALVAR</a>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
			<!-- END MODAL BOLETO -->

			<!-- MODAL PAGAMENTOS -->
			<div class="modal fade" id="modalPagamentos" tabindex="-1" aria-labelledby="modalPagamentosLabel"
				aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="modalPagamentosLabel">Digite a data da realização do pagamento</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<div class="col-md-12">
								<input type="date" id="input-data_pagamento" class="form-control" placeholder="Data Pagamento"
									v-model="data_pagamento">

							</div>
							<br>
							<div class="col-md-12 text-right" v-if="contracts != ''">
								<div class="col-md-12 text-right" v-if="contracts[0]['status_pagamento'] != 5">
									<button type="submit" class="btn btn-primary" @click="vincularPagamentos(contracts)">ENVIAR
										PAGAMENTO</button>
								</div>
								<div class="col-md-12 text-right" v-if="contracts[0]['status_pagamento'] == 5">
									<button type="submit" class="btn btn-primary" disabled>PAGAMENTO EFETUADO</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- END MODAL PAGAMENTOS -->

			<!-- MODAL REMESSA -->
			<div class="modal fade" id="modalRemessa" tabindex="-1" aria-labelledby="modalRemessaLabel" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="modalRemessaLabel">Importar Arquivo de Retorno</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<form method="POST" v-on:submit.prevent="remessaUpload">
								<div class="row">

									<!-- arquivo -->
									<div class="col-md-12">
										<div class="form-group">
											<label class="form-control-label">Upload Remessa</label>
											<input class="form-control" type="file" accept="application/pdf" id="remessa" name="remessa"
												required ref="remessa" lang="pt" v-on:change="handleFileUpload('remessa')" />
										</div>
									</div><!-- col-md-6 -->
									<!-- end arquivo  -->

									<div class="col-md-12 text-right">
										<button type="submit" class="btn btn-primary">SALVAR</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<!-- END MODAL REMESSA -->

			<vue-loading color="#ec2423" :active.sync="loading" :can-cancel="false"></vue-loading>

		</div>
	</div>
</template>
<script>
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table';
import Datepicker from 'vuejs-datepicker';
import { ptBR } from 'vuejs-datepicker/dist/locale'

export default {
	data() {
		return {
			contracts: [],
			faturamentos: [],
			totalValorResult: 0,
			totalValorDetran: 0,
			filterStatus: [{
				desc: 'Cancelado',
				id: 4
			}, {
				desc: 'Cancelamento Solicitado',
				id: 3
			}, {
				desc: 'Conciliado',
				id: 1
			}, {
				desc: 'Faturado',
				id: 2
			}, {
				desc: 'Previsao',
				id: 0
			}, {
				desc: 'Pago',
				id: 6
			}, {
				desc: 'Pendente',
				id: 5
			}],
			checkedDetrans: [],
			checkedInstituicoes: [],
			observacao: '',
			checkedBancos: [],
			data_pagamento: "",
			checkedStatusPagamentosDetran: [],
			checkedStatusPagamentosResult1: [],
			dataInicial: "",
			dataFinal: "",
			loading: false,
			newStatus: "",
			faturamentoID: "",
			faturamentoSpecify: "",
			calendarLanguage: ptBR,
			codigoBarras: '',
			fileNotaFiscal: '',
			fileBoleto: '',
			fileRemessa: '',
			columns: [
				{
					label: 'Tipo',
					field: 'tipo'
				},
				{
					label: 'Detran',
					field: 'detran.estado'
				},
				{
					label: 'Instituição',
					field: 'banco.nome'
				},
				{
					label: 'Contratos',
					field: 'qtd_contratos',
					sortable: false
				},
				{
					label: "Tipo Fechamento",
					field: "detran.payment_method",
				},
				{
					label: "Período Fechamento",
					field: "_fechamento",
					sortable: false
				},
				{
					label: "Valor Total",
					field: "valor_total",
					formatFn: this.formatCurrency,
					sortable: false
				},
				{
					label: "Vencimento",
					field: "data_vencimento",
					formatFn: this.formatDate,
				},
				{
					label: "Status Pagamento",
					field: "status_nome",
				},
				{
					label: "Ações",
					field: "action",
					sortable: false,
					html: true,
				},
			],
			columnsContracts: [
				{
					label: 'Gravame',
					field: 'gravame'
				},
				{
					label: 'Chassi',
					field: 'chassi'
				},
				{
					label: 'Status',
					field: 'status'
				},
				{
					label: "Criado em",
					field: "created_at",
					formatFn: this.formatDate,
					sortable: false
				},
				{
					label: "Registrado no Detran em",
					field: "dt_sucesso_reg_detran",
					formatFn: this.formatDate,
					sortable: false
				},
			],
			tablePagination: {
				enabled: true,
				mode: 'records',
				position: 'bottom',
				dropdownAllowAll: true,
				perPageDropdown: [10, 30, 50],
				nextLabel: 'Próximo',
				prevLabel: 'Anterior',
				rowsPerPageLabel: '',
				ofLabel: 'de',
				allLabel: 'Todos',
				pageLabel: '',
				perPageDropdownEnabled: true,
				compactMode: true
			},
			estadoOptions: [],
			bancoOptions: [],
			bancos: [],
			selectedMonth: "",
			months: [],
			dataSelecionada: "",
		};
	},
	mounted() {
		this.loadItems();
		this.loadStates();
		this.loadInstituicao();
		this.getMonths();
	},
	methods: {
		getMonths() {
			let now = new Date();
			let months = [];

			for (let i = 0; i < 12; i++) {
				let date = new Date(now.getFullYear(), i);
				let monthYear = `${date.toLocaleString("default", { month: "2-digit" })}/${date.getFullYear()}`;
				months.push({ mesAno: monthYear, disabled: false });
			}

			this.months = months;
			this.selectedMonth = null;
		},
		setData(item) {
			let dateXpl = item.mesAno.split('/');
			this.dataSelecionada = dateXpl[1] + '-' + dateXpl[0];
		},

		loadInstituicao() {
			axios.get("/api/portal/getAllBancos").then(res => {
				this.bancos = res.data.bancos;
				this.bancoOptions = this.bancos.map(element => ({
					label: element.nome,
					id: element.id,
					selected: false
				}));
				this.bancos = this.sortVector(this.bancoOptions.map(banco => banco.label));
			}).catch((err) => {
				this.loading = false;
				_this.$swal({
					title: "Erro",
					text: "Erro ao carregar as insituições financeiras, recarregue a pagina ou entre em contato com o suporte.",
					icon: "error"
				});
			});
		},

		loadStates() {
			axios.get("/api/portal/getAllDetrans").then(res => {
				this.detrans = res.data.detrans;
				this.estadoOptions = this.detrans.map(element => ({
					label: element.estado,
					id: element.id,
					selected: false
				}));
				this.states = this.sortVector(this.estadoOptions.map(state => state.label));
			});
		},
		sortVector(vector) {
			if (vector.length) {
				return vector.sort(function (a, b) {
					return a.localeCompare(b);
				});
			}
			return vector;
		},
		handleFileUpload(tipo) {
			if (tipo == 'notafiscal') {
				this.fileNotaFiscal = this.$refs.fileNotaFiscal.files[0];
			} else if ('boleto') {
				this.fileBoleto = this.$refs.fileBoleto.files[0];
			} else if ('remessa') {
				this.fileRemessa = this.$refs.remessa.files[0];
			}
		},
		loadItems() {
			let _this = this;
			_this.faturamentos = [];
			_this.loading = true;
			axios
				.post("faturamentos/search ")
				.then((res) => {
					for (let key in res.data) {
						if (typeof res.data[key] === 'object') {
							const faturamento = res.data[key];
							faturamento.vgt_id = key;
							_this.faturamentos.push(faturamento);
						}
					}

					_this.totalValorResult = res.data.valorTotalResult;
					_this.totalValorDetran = res.data.valorTotalDetran;

					_this.loading = false;
				})
				.catch((err) => {
					_this.loading = false;
					_this.$swal({
						title: "Erro",
						text: "Erro ao carregar as informações de contratos, entre em contato com o suporte.",
						icon: "error"
					});
				});
		},
		cancelFatura(item) {
			let _this = this;

			//show alert confirmation
			_this.$swal({
				title: "Cancelar Fatura",
				text: "Tem certeza que deseja cancelar a Fatura?",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "Sim",
				cancelButtonText: "Não"
			}).then((alertConfirm) => {

				//validate isConfirmed
				if (alertConfirm.isConfirmed) {
					_this.loading = true;
					//expressão de atribuição da vareavel _this.contracts.observacao
					var data = {
						decision: 1
					};

					axios.post('/admin/relatorios/faturamentos/' + item.id + '/aprovarCancelamento', data).then((res) => {
						_this.loading = false;
						_this.observacao = '';
						_this.$swal({
							title: "Sucesso",
							text: "Fatura cancelada com Sucesso!",
							icon: "success"
						}).then((res) => {
							location.reload();
						});
					})
						.catch((err) => {

							_this.loading = false;
							$("#modalCancelamento").modal('hide');
							_this.$swal({
								title: "Erro",
								text: "Falha na Solicitação de cancelamento. Tente novamente!",
								icon: "error"
							});
						});

				}//end validate isConfirmed

			});
			//end alert confirmation
		},
		updateItems() {
			let _this = this;
			_this.loading = true;
			var filters = {
				search: {
					detrans: _this.checkedDetrans,
					status: _this.checkedStatusPagamentosDetran,
					instituicao: _this.checkedInstituicoes,
					periodo: _this.selectedMonth && _this.selectedMonth.mesAno ? _this.selectedMonth.mesAno.split('/').reverse().join('-') : ''
				}
			};
			axios.post("faturamentos/search ")
				.then((res) => {
					_this.faturamentos = [];
					_this.faturamentos = _this.faturamentos.concat(res.data);

					for (let i = 0; i < res.data.length; i++) {
						_this.totalValorResult += res.data[i].totalValorResult
						_this.totalValorDetran += res.data[i].totalValorDetran
					}

					_this.loading = false;
				})
				.catch((err) => {
					_this.loading = false;
					_this.$swal({
						title: "Erro",
						text: "Erro ao carregar as informações de contratos, entre em contato com o suporte.",
						icon: "error"
					});
				});
		},
		gerarGuia(item) {
			let _this = this;
			_this.loading = true;
			let tempData = item?.periodo_inicial.split(" ");
			let _tempData = tempData[0].split("-");

			const arrayData = {
				cnpj_agente: item.banco?.cnpj,
				mes: _tempData[1],
				ano: _tempData[2]
			};

			axios.post(`/api/contract-es/guiapagamento/`, arrayData).then((res) => {
				window.open(res.config.url, "_blank");
				location.reload();
				_this.loading = false;
			})
				.catch((err) => {
					_this.loading = false;
				});
		},

		gerarFatura(item) {

			let _this = this;
			_this.loading = true;
			axios.get('/admin/fatura/' + item.id + '/' + item.detran.estado + '/gerarfatura', item).then((res) => {
				window.open(res.config.url, "_blank");
				location.reload();
				_this.loading = false;
			}).catch((err) => {
				_this.loading = false;
				_this.$swal({
					title: "Erro",
					text: "Erro ao gerar fatura, entre em contato comm o suporte.",
					icon: "error"
				});
			});
		},
		viewCancelamento(item) {
			this.contracts = [];
			this.contracts = item;
			this.observacao = this.contracts.observacao;
			$("#modalCancelamento").modal('show');
		},
		aprovarCancelamento(decision) {
			//instancia o objeto wrap para _this
			let _this = this;

			//expressão de atribuição da vareavel _this.contracts.observacao
			_this.contracts.decision = decision
			axios.post('/admin/relatorios/faturamentos/' + _this.contracts.id + '/aprovarCancelamento', _this.contracts).then((res) => {
				$("#modalCancelamento").modal('hide');
				_this.observacao = '';
				_this.$swal({
					title: "Sucesso",
					text: "Fatura cancelada com Sucesso!",
					icon: "success"
				});
				_this.loadItems();
			})
				.catch((err) => {
					_this.loading = false;
					$("#modalCancelamento").modal('hide');
					_this.$swal({
						title: "Erro",
						text: "Falha na Solicitação de cancelamento. Tente novamente!",
						icon: "error"
					});
				});
		},
		clearFilter() {
			let _this = this;
			_this.checkedDetrans = [];
			_this.checkedStatusPagamentosDetran = [],
				_this.checkedStatusPagamentosResult1 = [],
				_this.dataInicial = "";
			_this.dataFinal = "";
			_this.totalValorDetran = 0;
			_this.totalValorResult = 0;
			_this.faturamentos = [];
			_this.loadItems();
		},
		formatCurrency: function (value) {
			return Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
		},
		formatDate: function (value) {
			return moment(String(value)).format('DD/MM/YYYY HH:mm:ss');
		},
		formatShortDate: function (value) {
			return moment(String(value)).format('DD/MM/YYYY');
		},
		formatFilterDate(date) {
			let d = new Date(date);
			let dateString = moment(String(d)).format('YYYY-MM-DD');
			return dateString;
		},
		changeAttach(item, tipo) {
			this.faturamentoID = item.id;
			this.faturamentoSpecify = item;
			if (tipo == 'notafiscal') {
				$("#modalNotaFiscal").modal('show');
			} else {
				$("#modalBoleto").modal('show');
			}
		},
		changeStatus(item) {
			this.faturamentoID = item.id;
			this.faturamentoSpecify = item;
			$("#modalAlterarStatus").modal('show');
		},
		saveAttach(tipo) {
			let _this = this;
			_this.loading = true;

			//valida arquivo
			var arquivo = "";
			if (tipo == 'notafiscal') {
				arquivo = _this.fileNotaFiscal;
			} else {
				arquivo = _this.fileBoleto;
			}
			//fim valida arquivo

			let formData = new FormData();
			formData.append('arquivo', arquivo);
			formData.append('codigo_barras', _this.codigoBarras);
			formData.append('tipo', tipo);
			formData.append('estado', _this.faturamentoSpecify.detranbanco.detran.estado);
			formData.append('periodo_final', _this.faturamentoSpecify.periodo_final);
			formData.append('periodo_inicial', _this.faturamentoSpecify.periodo_inicial);

			axios.post("/admin/relatorios/faturamentos/" + _this.faturamentoID + "/update/attach", formData, { headers: { 'Content-Type': 'multipart/form-data' } })
				.then((res) => {
					_this.loading = false;
					$("#modalNotaFiscal").modal('hide');
					$("#modalBoleto").modal('hide');
					_this.$swal({
						title: "Sucesso",
						text: res.data.message,
						icon: "success"
					});
				})
				.catch((err) => {
					_this.loading = false;
				});
		},
		saveStatus() {
			let _this = this;
			_this.loading = true;

			let formData = new FormData();

			formData.append('status', _this.newStatus)
			formData.append('_method', 'PATCH')

			axios.post("/admin/relatorios/faturamentos/" + _this.faturamentoID + "/update/status", formData,
				{ headers: { 'Content-Type': 'multipart/form-data' } }).then((res) => {
					_this.loading = false;
					$("#modalAlterarStatus").modal('hide');
					_this.$swal({
						title: "Sucesso",
						text: res.data.message,
						icon: "success"
					});
					location.reload();
				}).catch((err) => {
					_this.loading = false;

					if (err.response.status == 422) {
						let errors = err.response.data.errors
						var errorFields = Object.keys(errors).map((key) => {
							return `${errors[key][0]}`;
						}).join(' <br> ');

						_this.$swal({
							title: 'Erro',
							html: errorFields,
							icon: 'error'
						});
					} else if (err.response.status == 400) {
						_this.$swal({
							title: "Erro",
							text: err.response.data,
							icon: "error"
						});
					} else {
						_this.$swal({
							title: "Erro",
							text: "Erro ao alterar status, entre em contato comm o suporte.",
							icon: "error"
						});
					}
				});
		},
		viewContracts(item) {
			this.contracts = [];
			this.contracts = item.contratos;

			this.faturamento_contratos = item;
			$("#modalContracts").modal('show');
		},
		conciliarContratos(item, type) {
			let _this = this;
			let faturamento = item
			faturamento.faturamento_id = _this.faturamento_contratos.id
			faturamento.type = type

			axios.post('/admin/relatorios/faturamentos/' + faturamento.id + '/conciliarContratos', faturamento).then((res) => {
				$("#modalCancelamento").modal('hide');
				_this.observacao = '';
				_this.$swal({
					title: "Sucesso",
					text: res.data.message,
					icon: "success"
				});
				_this.loadItems();
			})
				.catch((err) => {
					_this.loading = false;
					$("#modalCancelamento").modal('hide');
					_this.$swal({
						title: "Erro",
						text: "Falha na Conciliação dos Contratos. Tente novamente!",
						icon: "error"
					});
				});

		},
		exportDataGrid(type, tableName) {
			let params = type == 'pdf' ?
				{
					type: 'pdf',
					fileName: tableName,
					htmlContent: false,
					onCellHtmlData: (cell, row, col, htmlContent) => htmlContent,
					jspdf: {
						orientation: 'l',
						margins: { right: 10, left: 10, top: 40, bottom: 40 },
						autotable: {
							styles: {
								fillColor: 'inherit',
								textColor: 'inherit',
								halign: 'center',
								fillStyle: 'DF',
								lineColor: 200,
								lineWidth: 0.1
							},
							headerStyles: { halign: 'left' },
							tableWidth: 'auto'
						}
					}
				} :
				{
					type: 'excel',
					htmlContent: false,
					fileName: tableName,
					mso: { fileFormat: 'xlsx' }
				};

			let options = { tableName: tableName };

			jQuery.extend(true, options, params);

			$('#vgt-table').tableExport(options);
		},
		viewPagamentoEfetuado(item) {
			this.contracts = [];
			this.contracts = item.contratos;
			this.faturamento_contratos = item;
			$("#modalPagamentos").modal('show');
		},
		viewUploadRemessa(item) {
			$("#modalRemessa").modal('show');
		},
		vincularPagamentos(item) {
			let _this = this;
			let faturamento = _this.faturamento_contratos
			let formData = new FormData();
			formData.append('id', faturamento.id);
			formData.append('estado', faturamento.detranbanco.detran.estado);
			formData.append('periodo_final', faturamento.periodo_final);
			formData.append('periodo_inicial', faturamento.periodo_inicial);
			formData.append('data_pagamento', _this.data_pagamento);
			faturamento.data_pagamento = _this.data_pagamento;

			axios.post('/admin/relatorios/faturamentos/' + faturamento.id + '/vincularpagamento', formData).then((res) => {

				$("#modalCancelamento").modal('hide');
				_this.observacao = '';
				_this.$swal({
					title: "Sucesso",
					text: "Pagamento Vinculado",
					icon: "success"
				});
				_this.loadItems();
			})
				.catch((err) => {
					_this.loading = false;
					$("#modalCancelamento").modal('hide');
					_this.$swal({
						title: "Erro",
						text: "Falha ao Vincular Pagamento. Tente novamente!",
						icon: "error"
					});
				});

		},
		remessaUpload(item) {

		},
	},
	components: {
		VueGoodTable,
		Datepicker
	},
};
</script>
