<template>
	<div class="row">
		<div class="col-12">
			<!-- dashboard cards -->
			<div class="row">
				<div class="col-lg-3 col-md-6 col-sm-6 col-12">
					<div class="card card-statistic-1">
						<div class="card-icon bg-secondary">
							<i class="fas fa-file-alt"></i>
						</div>
						<div class="card-wrap">
							<div class="card-header">
								<h4>Contratos Enviados</h4>
							</div>
							<div class="card-body">
								<div v-if="total === -1" class="spinner-border spinner-border spinner-border-sm"
									role="status"></div>
								<div v-else>{{ total }}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-3 col-md-6 col-sm-6 col-12">
					<div class="card card-statistic-1">
						<div class="card-icon bg-success">
							<i class="fas fa-clipboard-check"></i>
						</div>
						<div class="card-wrap">
							<div class="card-header">
								<h4>Contratos Concluídos</h4>
							</div>
							<div class="card-body">
								<div v-if="concluido === -1" class="spinner-border spinner-border spinner-border-sm"
									role="status">
								</div>
								<div v-else>{{ concluido }}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-3 col-md-6 col-sm-6 col-12">
					<div class="card card-statistic-1">
						<div class="card-icon bg-warning">
							<i class="fas fa-hourglass-half"></i>
						</div>
						<div class="card-wrap">
							<div class="card-header">
								<h4>Contratos em Processamento</h4>
							</div>
							<div class="card-body">
								<div v-if="processando === -1" class="spinner-border spinner-border spinner-border-sm"
									role="status">
								</div>
								<div v-else>{{ processando }}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-3 col-md-6 col-sm-6 col-12">
					<div class="card card-statistic-1">
						<div class="card-icon bg-primary">
							<i class="fas fa-history"></i>
						</div>
						<div class="card-wrap">
							<div class="card-header">
								<h4>Contratos Pendentes</h4>
							</div>
							<div class="card-body">
								<div v-if="pendente === -1" class="spinner-border spinner-border spinner-border-sm"
									role="status"></div>
								<div v-else>{{ pendente }}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- end dashboard cards -->

			<!-- filter start -->
			<div class="card col-12">
				<div class="card-header">
					<div class="card-header-action dropdown btn-filter col-md-2 col-lg-2 col-sm-12 mt-2">
						<a href="#" data-toggle="dropdown" class="btn btn-light dropdown-toggle">Detrans</a>
						<ul class="dropdown-menu dropdown-menu-sm dropdown-menu-left dropdown-filter">
							<li class="dropdown-title">Selecione os Detrans</li>
							<li v-if="!detrans.length">
								<div class="text-center">
									<div class="spinner-border spinner-border-sm text-danger mt-1 mb-1" role="status">
										<span class="sr-only">Loading...</span>
									</div>
								</div>
							</li>
							<li v-for="item in detrans" :key="item.id">
								<div class="form-check custom-checkbox custom-control ml-3">
									<input class="form-check-input" type="checkbox" :value="item.id"
										v-model="checkedDetrans" :id="item.id" />
									<label class="form-check-label" :for="item.id">
										{{
											item.estado ? item.estado : "Erro"
										}}
									</label>
								</div>
							</li>
							<div class="text-center">
								<a href="#" class="btn btn-danger col-md-2 col-lg-2 col-sm-4 mt-2 btn-filter"
									data-toggle="tooltip" data-placement="top" title="Filtrar" id="inicial"
									@click="updateItems">
									<i class="fas fa-filter"></i>
								</a>
							</div>
						</ul>
					</div>

					<div class="card-header-action dropdown btn-filter col-md-2 col-lg-2 col-sm-12 mt-2">
						<a href="#" data-toggle="dropdown" class="btn btn-light dropdown-toggle">Instituiçoes</a>
						<ul class="dropdown-menu dropdown-menu-sm dropdown-menu-left dropdown-filter">
							<li class="dropdown-title">Selecione as Instituições</li>
							<li v-if="!filterInstituicoes.length">
								<div class="text-center">
									<div class="spinner-border spinner-border-sm text-danger mt-1 mb-1" role="status">
										<span class="sr-only">Loading...</span>
									</div>
								</div>
							</li>
							<li v-for="item in filterInstituicoes" :key="item.id">
								<div class="form-check custom-checkbox custom-control ml-3">
									<input class="form-check-input" type="checkbox" :value="item.id"
										v-model="checkedInstituicoes" :id="item.id" />
									<label class="form-check-label" :for="item.id">
										{{ item.nome_fantasia ? item.nome_fantasia : item.nome }}
									</label>
								</div>
							</li>
							<div class="text-center mt-2">
								<a href="#" class="btn btn-danger col-md-3 btn-filter text-center" data-toggle="tooltip"
									data-placement="top" title="Filtrar" id="inicial" @click="updateItems">
									<i class="fas fa-filter"></i>
								</a>
							</div>
						</ul>
					</div>
					<div class="card-header-action dropdown btn-filter col-md-2 col-lg-2 col-sm-12 mt-2">
						<a href="#" data-toggle="dropdown" class="btn btn-light dropdown-toggle">Status</a>
						<ul class="dropdown-menu dropdown-menu-sm dropdown-menu-left dropdown-filter">
							<li class="dropdown-title">Selecione os Status</li>
							<li v-if="!contratoStatus.length">
								<div class="text-center">
									<div class="spinner-border spinner-border-sm text-danger mt-1 mb-1" role="status">
										<span class="sr-only">Loading...</span>
									</div>
								</div>
							</li>
							<li v-for="item in contratoStatus" :key="item.id">
								<div class="form-check custom-checkbox custom-control ml-3">
									<input class="form-check-input" type="checkbox" :value="item.id"
										v-model="checkedStatus" :id="item.id" />
									<label class="form-check-label" :for="item.id">
										{{ item.nome ? item.nome : "Indisponível" }}
									</label>
								</div>
							</li>
							<div class="text-center">
								<a href="#" class="btn btn-danger col-md-2 col-lg-2 col-sm-4 mt-2 btn-filter"
									data-toggle="tooltip" data-placement="top" title="Filtrar" id="inicial"
									@click="updateItems">
									<i class="fas fa-filter"></i>
								</a>
							</div>
						</ul>
					</div>

					<!-- filtro período -->
					<div class="col-md-2 col-lg-2 col-sm-12 btn-filter mt-2">
						<div class="form-gr date-custom">
							<datepicker :language="calendarLanguage" v-model="dataInicial" placeholder="Data Inicial"
								class="btn-filter" />
						</div>
					</div>
					<div class="col-md-2 col-lg-2 col-sm-12 btn-filter mt-2">
						<div class="form-gr date-custom">
							<datepicker :language="calendarLanguage" v-model="dataFinal" placeholder="Data Final"
								class="btn-filter" />
						</div>
					</div>
					<!-- end filtro período -->

					<!-- filter button -->
					<div class="col-md-2 col-lg-2 col-sm-12 text-center">
						<a href="#" class="btn btn-danger col-md-5 col-lg-5 col-sm-12 mt-2 btn-filter"
							data-toggle="tooltip" data-placement="top" title="Filtrar" @click="updateItems">
							<i class="fas fa-filter"></i>
						</a>
						<a href="#" class="btn btn-danger col-md-5 col-lg-5 col-sm-12 mt-2 btn-filter"
							data-toggle="tooltip" data-placement="top" title="Limpar Filtro" @click="limparFiltros">
							<i class="fas fa-times"></i>
						</a>
					</div>
					<!-- end filter button -->
				</div>
			</div>
			<!-- filter end -->

			<!-- card -->
			<div class="card">
				<!-- card body -->
				<div class="card-body">
					<!-- table -->
					<div class="pb-4" v-if="!loading && contratos.length > 0">
						<div class="col-md-3 col-lg-3 col-sm-12 float-right text-right" style="z-index: 1 !important">
							<a href="#" class="btn btn-danger col-md-5 col-lg-5 col-sm-12 mt-2 btn-filter export-button"
								@click="exportDataPdf('Contratos')">
								<i class="far fa-file-pdf"></i> PDF
							</a>
							<a href="#"
								class="btn btn-success col-md-5 col-lg-5 col-sm-12 mt-2 btn-filter export-button"
								@click="exportDataXls('Contratos')">
								<i class="far fa-file-excel"></i> EXCEL
							</a>

						</div>


						<!-- <vue-good-table mode="remote" :columns="columns" :rows="contratos" :globalSearch="true"
							:isLoading.sync="isLoading" :search-options="{
								enabled: true,
								skipDiacritics: true,
								placeholder: 'Digite para buscar',
							}" :sort-options="{ enabled: true, }" @on-search="onSearch" @on-sort-change="onSortChange"
							styleClass="vgt-table"> -->

						<vue-good-table mode="remote" :columns="columns" :rows="contratos" :globalSearch="true"
							:isLoading.sync="isLoading" :search-options="{
								enabled: true,
								skipDiacritics: true,
								placeholder: 'Digite para buscar',
							}" :sort-options="{ enabled: true, }" @on-search="onSearch" @on-sort-change="onSortChange"
							styleClass="vgt-table" @on-page-change="handlePageChange">

							<div slot="emptystate" class="d-flex justify-content-center">Carregando Contratos...</div>

							<template slot="table-row" slot-scope="props">
								<span v-if="props.column.field === '#'">
									{{ (contratosPagination.per_page * (contratosPagination.current_page - 1)) +
										(props.index +
											1) }}
								</span>
								<span v-if="props.column.field === 'visualizar'">
									<div class="text-center">
										<button class="btn btn-sm btn-outline-danger" @click="downloadJsonXML(props)"
											title="Download do XML/JSON">
											<i class="fas fa-cloud-download-alt fa-2x"></i>
										</button>
										<button class="btn btn-sm btn-outline-danger" @click="viewContract(props)"
											title="Visualizar contrato">
											<i class="fas fa-eye fa-2x"></i>
										</button>
										<button class="btn btn-sm btn-outline-danger" @click="resendContract(props.row)"
											title="Reenviar contrato">
											<i class="fas fa-sync"></i>
										</button>
									</div>
								</span>

								<span v-if="props.column.field === 'arquivo'">
									<span v-if="props.row.arquivo !== null && props.row.arquivo !== ''"
										title="Registro de contrato com arquivo">
										<i class="fa fa-file-pdf fa-1x" style="color:red"></i>
									</span>
									<span v-else title="Registro de contrato sem aquivo enviado">
										<i class="fa fa-file-pdf fa-1x"></i>
									</span>
								</span>

								<div v-else-if="props.column.label === 'Status'" class="badge badge-light"
									@click="verDetalheStatus(props.row)">
									<a href="#"><i class="fas fa-eye fa-2x"></i>
										<span>&nbsp;&nbsp;</span>
										{{ props.row.nome_status }}
									</a>
								</div>

								<span v-else>
									{{ props.formattedRow[props.column.field] }}
								</span>
								<span v-if="props.column.label == 'Instituição'">
									<div v-if="props.row.nome_fantasia === true">{{ props.row.nome_fantasia }}</div>
									<div v-else>{{ props.row.nome }}</div>
								</span>
							</template>


						</vue-good-table>

						<div class="d-flex justify-content-between">
							<div class="pt-2 pr-2 pl-0">
								<div class="input-group">
									<div class="input-group-prepend">
										<label for="perPage" class="input-group-text">Linhas</label>
									</div>
									<select class="custom-select" @change="perPageChange" v-model="selectedPerPage">
										<option v-for="PerPagesValue in PerPagesValues"
											v-bind:value="PerPagesValue.value"
											:selected="selectedPerPage === contratosPagination.per_page">
											{{ PerPagesValue.name }}
										</option>
									</select>
								</div>
							</div>

							<div class="pt-2 pr-0">
								<Pagination :pagination="contratosPagination" @paginate="handlePageChange"></Pagination>
							</div>
						</div>

						<sweet-modal ref="modal" heigth="60px" :hide-close-button="true">
							<div class="row">
								<div class="col-12">
									<div class="activities" v-if="datalheStatus.length > 0">
										<div class="activity" v-for="detalhe in datalheStatus" v-bind:key="detalhe.id">
											<div class="activity-icon bg-primary text-white shadow-primary">
												<i class="fas fa-comment-alt"></i>
											</div>
											<div class="activity-detail">
												<div class="mb-2">
													<span class="text-job text-primary text-center"
														v-if="detalhe.detran_retorno_api_id != undefined">
														Status API: {{ detalhe.detran_retorno_apis.status_api }}
														<div class="bullet"></div>
														{{ detalhe.created_at | moment("DD/MM/YYYY HH:mm:ss") }}
														<div class="bullet"></div> {{ 'Ip do usuário: ' +
															detalhe.ip_user }}
													</span>
													<span class="text-job text-primary text-center" v-else>
														Status Atividade:
														<div class="bullet"></div> {{ detalhe.created_at
															| moment("DD/MM/YYYY HH: mm: ss") }}
													</span>
												</div>
												<p v-if="detalhe.detran_retorno_api_id != undefined">
													{{ detalhe.detran_retorno_apis.descricao_retorno }}
												</p>
												<p v-else>{{ detalhe.action }}</p>
											</div>
										</div>
									</div>
									<div v-else-if="!detalheStatusLoading && !datalheStatus.length"
										class="text-center p-3 text-muted">
										<h5>Nenhuma informação de retorno</h5>
									</div>
									<div class="d-flex justify-content-center" v-else>
										<div class="spinner-border text-danger" role="status"></div>
									</div>
								</div>
							</div>
							<button slot="button" class="btn btn-danger" @click="fecharModal">Fechar</button>
						</sweet-modal>

					</div>
					<sweet-modal ref="modal" :hide-close-button="true">
						<div class="row">
							<div class="col-12">
								<div class="activities" v-if="datalheStatus.length > 0">
									<div class="activity" v-for="detalhe in datalheStatus" v-bind:key="detalhe.id">
										<div class="activity-icon bg-primary text-white shadow-primary">
											<i class="fas fa-comment-alt"></i>
										</div>
										<div class="activity-detail">
											<div class="mb-2">
												<span class="text-job text-primary text-center">Status API:
													{{
														detalhe.detran_retorno_apis.status_api
													}}<div class="bullet"></div> {{ detalhe.created_at |
														moment("DD/MM/YYYY HH:mm:ss") }}</span>
											</div>
											<p>
												{{
													detalhe.detran_retorno_apis
														.descricao_retorno
												}}
											</p>
										</div>
									</div>
								</div>
								<div v-else-if="!detalheStatusLoading && !datalheStatus.length"
									class="text-center p-3 text-muted">
									<h5>Nenhuma informação de retorno</h5>
								</div>
								<div class="d-flex justify-content-center" v-else>
									<div class="spinner-border text-danger" role="status"></div>
								</div>
							</div>
						</div>
						<button name='oi' slot="button" class="btn btn-danger" @click="fecharModal">Fechar</button>
					</sweet-modal>
				</div>
				<div v-if="!contratos.length && !loading" class="text-center p-3 text-muted">
					<h5>Nenhum contrato encontrado!</h5>
				</div>
				<!-- end table -->
				<div class="text-center p-4 text-muted" v-if="loading">
					<h5>Carregando...</h5>
					<p>Aguarde até que os dados sejam carregados...</p>
				</div>
				<!-- end table -->
			</div>
			<!-- end card body -->
		</div>
		<vue-loading color="#ec2423" :active.sync="loading" :can-cancel="false"></vue-loading>
	</div>
</div></template>
<script>
import { VueGoodTable } from "vue-good-table";
import Pagination from './Pagination.vue';
import Datepicker from "vuejs-datepicker";
import { ptBR } from "vuejs-datepicker/dist/locale";
import { SweetModal, SweetModalTab } from "sweet-modal-vue";
import _ from 'lodash';
import 'vue-select/dist/vue-select.css';
import * as XLSX from 'xlsx';


export default {
	props: ['instituicoes'],
	data() {
		return {
			navigate_validation: false,
			pageDefault: 1,
			isLoading: false,
			contratos: [],
			detrans: [],
			filterInstituicoes: JSON.parse(this.instituicoes),
			contratoStatus: [],
			checkedDetrans: [],
			checkedStatus: [],
			checkedInstituicoes: [],
			dataInicial: "",
			dataFinal: "",
			calendarLanguage: ptBR,
			total: -1,
			concluido: -1,
			processando: -1,
			pendente: -1,
			loading: false,
			datalheStatus: false,
			detalheStatusLoading: false,
			disabledLoading: false,
			selectedPerPage: '10',
			contratosPagination: {
				searchTerm: '',
				current_page: 1,
				last_page: 1,
				total: 0,
				per_page: 10,
				from: 1,
				to: 0,
			},
			paginationOptions: {
				enabled: false,
				mode: 'remote',
				position: 'bottom',
				dropdownAllowAll: true,
				perPageDropdown: [],
				nextLabel: 'Próximo',
				prevLabel: 'Anterior',
				rowsPerPageLabel: 'Por página:',
				ofLabel: 'de',
				allLabel: 'Todos',
				pageLabel: '',
				perPageDropdownEnabled: false,
				compactMode: true
			},
			searchOptions: {
				enabled: true,
				placeholder: 'Buscar nesta tabela'
			},
			sortOptions: {
				enabled: true
			},
			pagination: {
				current_page: 1,
				last_page: 1,

			},
			PerPagesValues: [
				{ value: "10", name: '10' },
				{ value: "20", name: '20' },
				{ value: "30", name: '30' },
				{ value: "50", name: '50' },
				{ value: "0", name: 'Todos' },
			],
			columns: [
				{
					label: '#',
					field: '#',
					type: "number",
					sortable: false
				},
				{
					label: 'Id',
					field: 'id',
					sortable: true
				},
				{
					label: "DETRAN",
					field: "uf_licenciamento",
					sortable: true
				},
				{
					label: "Instituição",
					field: "nome_banco",
					sortable: true
				},
				{
					label: "Nº Contrato",
					field: "num_contrato",
					type: "number",
					sortable: true
				},
				{
					label: "Chassi",
					field: "chassi",
					sortable: true
				},
				{
					label: "Nº Gravame",
					field: "num_gravame",
					type: "number",
					sortable: true
				},
				{
					label: "Custo Registro",
					field: 'custo_contrato',
					sortable: true,
					formatFn: this.formatCurrency,
					type: "number",

				},
				{
					label: "Valor Registro",
					field: 'valor_contrato',
					sortable: true,
					formatFn: this.formatCurrency,
					type: "number",
				},
				{
					label: "Status",
					field: "contrato_status",
					type: 'string',
					sortable: true
				},
				{
					label: "Dt. Registro Detran",
					field: "dt_sucesso_reg_detran",
					type: "date",
					formatFn: this.formatDate,
					sortable: true
				},
				{
					label: "Dt. Criação",
					field: "created_at",
					type: "date",
					formatFn: this.formatDate,
					sortable: true
				},
				{
					label: " ",
					field: "arquivo",
					tdClass: 'text-center',
					sortable: true
				},
				{
					label: "Visualizar",
					field: "visualizar",
					html: true,
					sortable: false
				},
			],
		};
	},
	mounted() {
		this.loadItems();
		this.loadFilters();
		this.loadDashboardData();
	},
	methods: {
		loadDashboardData() {
			this.loadTotalContratos();
			this.loadConcluidoContratos();
			this.loadProcessandoContratos();
			this.loadPendenteContratos();
		},
		loadTotalContratos() {
			let url = `/api/total-contratos/admin/0/all/${new Date().getFullYear()}-01-01`
			axios.get(url)
				.then(res => {
					this.total = res.data;
				})
				.catch(error => {
					console.error(error);
				});
		},
		loadConcluidoContratos() {
			let url = `/api/total-contratos-concluido/admin/0/all/${new Date().getFullYear()}-01-01`
			axios.get(url)
				.then(res => {
					this.concluido = res.data;
				})
				.catch(error => {
					console.error(error);
				});
		},
		loadProcessandoContratos() {
			let url = `/api/total-contratos-processando/admin/0/all/${new Date().getFullYear()}-01-01`
			axios.get(url)
				.then(res => {
					this.processando = res.data;
				})
				.catch(error => {
					console.error(error);
				});
		},
		loadPendenteContratos() {
			let url = `/api/total-contratos-pendente/admin/0/all/${new Date().getFullYear()}-01-01`
			axios.get(url)
				.then(res => {
					this.pendente = res.data;
				})
				.catch(error => {
					console.error(error);
				});
		},
		selectPage() {
			this.disabledLoading = true;
			this.loadContratos();
		},
		loadItems() {
			let _this = this;
			_this.isLoading = true;
			_this.loading = true;

			axios.get(`/api/admin/contratos`, {
				params: {
					sort: _this.sortField,
					searchTerm: _this.contratosPagination.searchTerm,
					page: _this.contratosPagination.current_page,
					per_page: _this.selectedPerPage
				}
			})
				.then((res) => {
					_this.loading = false;

					if (this.contratosPagination.searchTerm && res.data.contratos.data.length <= 0) {
						this.contratosPagination.searchTerm = ''
						this.loadContratos()
					} else {
						_this.contratos = res.data.contratos.data;
						_this.contratosPagination.total = res.data.contratos.total;
						_this.contratosPagination.last_page = res.data.contratos.last_page;
					}
				})
				.catch((err) => {
					_this.loading = false;
					console.error(err)
				});
		},
		updateParams(newProps) {
			this.contratosPagination = Object.assign({}, this.contratosPagination, newProps);
		},
		onSearch: _.debounce(function (params) {
			this.updateParams(params);
			this.changeDisabledLoading();
			return false;
		}, 500),
		onSortChange(params) {
			this.sortField = `${params[0].type === 'desc' ? '-' : ""}${params[0].field}`
			this.changeDisabledLoading()
		},
		changeDisabledLoading() {
			this.pageDefault = 1;
			this.navigate_validation = 1;
			this.disabledLoading = true;
			this.loadItems();
			this.showSelectedFilters = true;
		},
		handlePageChange(page) {
			this.contratosPagination.current_page = page
			this.updateItems();
		},
		viewContract(item) {
			location.href = "/admin/relatorios/contratos/" + item.row.detrans.estado + "/" + item.row.id + "/view";
		},
		validaStatus(id) {
			var nome = "";
			let _this = this;
			_this.contratoStatus.map((row) => {
				if (row.id === id) {
					nome = row.nome;
				}
			});
			return nome;
		},
		perPageChange(event) {
			event.preventDefault();
			this.contratosPagination.per_page = event.target.value;
			this.contratosPagination.current_page = 1;
			this.updateItems();
		},
		updateParams(newProps) {
			this.contratosPagination = Object.assign({}, this.contratosPagination, newProps);
		},
		resendContract(item) {
			let _this = this;

			_this.$swal({
				title: "Reenviar Contrato",
				text: "Tem certeza que deseja enviar novamente o Contrato para o Detran?",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "Enviar",
				cancelButtonText: "Cancelar"
			}).then((alertConfirm) => {

				if (alertConfirm.isConfirmed) {

					axios.get("/api/admin/contratos/" + item.detran_id + "/" + item.id + "/resend").then((res) => {
						_this.$swal({
							title: "Sucesso",
							text: "Contrato reenviado com sucesso!",
							icon: "success"
						});

					}).catch(error => {
						_this.$swal({
							title: "Erro",
							text: "Falha ao reenviar o contrato. Tente novamente!",
							icon: "error"
						});
					});
				}
			});
		},
		updateItems() {
			let _this = this;
			_this.loading = true;

			let formData = new FormData();

			for (var i = 0; i < _this.checkedDetrans.length; i++) {
				formData.append(`detrans[${i}]`, _this.checkedDetrans[i]);
			}

			for (var i = 0; i < _this.checkedInstituicoes.length; i++) {
				formData.append(`instituicoes[${i}]`, _this.checkedInstituicoes[i]);
			}

			for (var i = 0; i < _this.checkedStatus.length; i++) {
				formData.append(`contrato_status[${i}]`, _this.checkedStatus[i]);
			}

			if (_this.dataInicial != '' && _this.dataInicial != null) {
				formData.append('data_inicial', _this.formatFilterDate(_this.dataInicial));
			}

			if (_this.dataFinal != '' && _this.dataFinal != null) {
				formData.append('data_final', _this.formatFilterDate(_this.dataFinal));
			}

			formData.append('per_page', _this.selectedPerPage);
			formData.append('page', _this.contratosPagination.current_page);

			axios
				.post("/api/admin/contratos", formData, { headers: { 'Content-Type': 'multipart/form-data' } })
				.then((res) => {
					_this.loading = false;

					if (this.contratosPagination.searchTerm && res.data.contratos.data.length <= 0) {
						this.contratosPagination.searchTerm = ''
						this.loadContratos()
					} else {
						_this.contratos = res.data.contratos.data;
						_this.contratosPagination.total = res.data.contratos.total;
						_this.contratosPagination.last_page = res.data.contratos.last_page;
					}
				})
				.catch(() => {
					_this.loading = false;
					console.error(err)
				});
		},
		loadFilters() {
			let _this = this;
			_this.loadDetrans();
			_this.loadStatusContratos();
		},
		loadDetrans() {
			let _this = this;
			axios
				.get("/admin/detrans")
				.then((res) => {
					_this.detrans = res.data;
				})
				.catch((err) => {
					console.error(); (err);
				});
		},
		loadStatusContratos() {
			let _this = this;
			axios
				.get("/admin/contratostatus")
				.then((res) => {
					_this.contratoStatus = res.data;
				})
		},
		limparFiltros() {
			let _this = this;
			_this.checkedDetrans = [];
			_this.checkedStatus = [];
			_this.checkedInstituicoes = [];
			_this.dataInicial = "";
			_this.dataFinal = "";
			_this.updateItems();
		},
		formatDate: function (value) {
			return moment(String(value)).format("DD/MM/YYYY HH:mm:ss");
		},
		formatCurrency(value) {
			return `R$ ${parseFloat(value.replace(/,/g, '.')).toFixed(2)}`; // Formata o valor com 2 casas decimais
		},
		formatFilterDate(date) {
			let d = new Date(date);
			return moment(String(d)).format("YYYY-MM-DD");
		},
		verDetalheStatus(item) {

			let _this = this,
				estado = item.detrans.estado;

			_this.detalheStatusLoading = true;
			axios
				.get(
					`/admin/log/api/detalhe/${item.id}/${estado}`
				)
				.then((res) => {
					_this.detalheStatusLoading = false;
					if (Object.keys(res.data).length === 0) {
						_this.$swal({
							icon: 'info',
							title: 'Sem Atualizações',
							text: 'Não há informações disponíveis para exibir',
							confirmButtonText: 'OK'
						});
					} else {
						_this.datalheStatus = res.data;
						_this.$refs.modal.open("modal");
					}
				}).catch((error) => {
					console.error('Erro ao buscar os dados:', error);
				});
		},
		fecharModal() {
			let _this = this;
			_this.datalheStatus = false;
			_this.$refs.modal.close("modal");
		},
		exportDataXls(fileName) {
			// Define as colunas a serem exportadas e seus respectivos nomes
			const formattedData = this.contratos.map(contrato => ({
				'Id': contrato['id'],
				'DETRAN': contrato['uf_licenciamento'],
				'Instituição': contrato['nome_fantasia'],
				'Nº Contrato': contrato['num_contrato'],
				'Chassi': contrato['chassi'],
				'Nº Gravame': contrato['num_gravame'],
				'Custo Registro': parseFloat(contrato['custo_contrato'].replace(/,/g, '.')),
				'Valor Registro': parseFloat(contrato['valor_contrato'].replace(/,/g, '.')),
				'Status': contrato['nome_status'],
				'Dt. Registro Detran': contrato['dt_sucesso_reg_detran'],
				'Dt. Criação': contrato['created_at']
			}));

			console.log(formattedData);
			// Cria uma planilha a partir dos dados formatados
			const ws = XLSX.utils.json_to_sheet(formattedData);

			// Cria um novo livro de trabalho
			const wb = XLSX.utils.book_new();

			// Configura colunas a serem formatadas (Custo Registro e Valor Registro)			
			const columnWidths = [
				{ width: 10 },
				{ width: 15 },
				{ width: 20 },
				{ width: 25 },
				{ width: 15 },
				{ width: 20 },
				{ width: 15 },
				{ width: 20 },
				{ width: 20 },
				{ width: 15 },
				{ width: 25 },
				{ width: 25 },
			];

			// Ajusta a largura das colunas
			ws['!cols'] = columnWidths;

			// Adiciona a planilha ao livro
			XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

			// Salva o arquivo Excel
			XLSX.writeFile(wb, fileName + '.xlsx');
		},
		exportDataPdf(tableName) {
			const params = {
				type: 'pdf',
				ignoreColumn: [12, 13, 14],
				fileName: tableName,
				htmlContent: false,
				onCellHtmlData: (cell, row, col, htmlContent) => {
					// Ignore colunas específicas com base no índice
					const ignoredColumns = [12, 13, 14];
					if (ignoredColumns.includes(col)) {
						return ''; // Retorna vazio para ignorar a coluna
					}
					// Remove texto de ordenação da tabela
					return htmlContent.replace(/ Sort table by .+ in (descending|ascending) order/, '');
				},
				jspdf: {
					orientation: 'l',
					margins: { right: 10, left: 10, top: 40, bottom: 40 },
					autotable: {
						styles: {
							fillColor: 'inherit',
							textColor: 'inherit',
							halign: 'center',
							fillStyle: 'DF',
							lineColor: 200,
							lineWidth: 0.1,
						},
						headerStyles: { halign: 'left' },
						tableWidth: 'auto',
					},
				},
			};

			const options = { tableName: tableName };

			jQuery.extend(true, options, params);

			$('#vgt-table').tableExport(options);
		},

		downloadJsonXML(item) {
			const detran = item.row.detran_id;
			const contrato = item.row.id;

			axios
				.get(`/api/admin/contratos/xmljson/${detran}/${contrato}`,
					{
						responseType: 'blob'
					}).then(
						response => {
							const regex = /filename="(?<file>.*?)"/;
							const filename = response.headers["content-disposition"].match(regex)[1] ?? '';
							const href = URL.createObjectURL(response.data);
							// create "a" HTML element with href to file & click
							const link = document.createElement('a');
							link.href = href;
							link.setAttribute('download', filename); //or any other extension
							document.body.appendChild(link);
							link.click();
							document.body.removeChild(link);
							URL.revokeObjectURL(href);
							this.$swal({
								title: 'Sucesso',
								text: 'Arquivo baixado com sucesso',
								icon: 'success'
							});
						},
						reason => {
							this.$swal({
								title: 'Erro',
								text: 'Ocorreu um erro ao fazer o download. ' + reason.message,
								icon: 'error'
							});
						});
		}
	},

	components: {
		VueGoodTable,
		Datepicker,
		SweetModal,
		SweetModalTab,
		Pagination,
	},
};
</script>
