<template>
	<div class="row">
		<div class="col-12">
			<div class="card">

				<!-- card header -->
				<div class="card-header">
					<h4>Preencha os dados abaixo</h4>

					<a @click="updateData()" href="#" class="btn btn-sm btn-primary text-white btn-right">SALVAR</a>
				</div>
				<!-- end card header -->

				<!-- card body -->
				<div class="card-body">

					<!-- form -->
					<h6 class="heading-small text-muted mb-4">Dados</h6>
					<div class="">
						<div class="row">

							<!-- estado -->
									<div class="col-md-2">
										<div class="form-group">
											<label class="form-control-label" for="input-uf">Estado</label>
											<select type="text" id="input-uf" v-bind:class="{'is-invalid': errors.uf}" class="form-control" placeholder="estado" v-model="uf" required="true">
												<option v-for="item, index in states" :key="index">{{item}}</option>
											</select>
											<div class="invalid-feedback" v-if="errors.uf">
												<p>{{ errors.uf[0] }}</p>
											</div>
										</div><!-- form group -->
									</div><!-- col-md-6 -->
							<!-- end estado -->

							<!-- Municipio -->
									<div class="col-md-8">
										<div class="form-group">
											<label class="form-control-label" for="input-municipio">Município</label>
											<input type="text" id="input-municipio" v-bind:class="{'is-invalid': errors.municipio}" class="form-control" placeholder="Município" v-model="municipio" required="true">
											<div class="invalid-feedback" v-if="errors.municipio">
												<p>{{ errors.municipio[0] }}</p>
											</div>
										</div>
									</div>
							<!-- end Municipio -->

							<!-- Código serpro -->
									<div class="col-md-2">
										<div class="form-group">
											<label class="form-control-label" for="input-codigo_origem">Codigo Origem</label>
											<input type="number" id="input-codigo_origem" v-bind:class="{'is-invalid': errors.codigo_origem}" class="form-control" placeholder="Codigo origem" v-model="codigo_origem" required="true">
											<div class="invalid-feedback" v-if="errors.codigo_origem">
												<p>{{ errors.codigo_origem[0] }}</p>
											</div>
										</div>
									</div>
							<!-- end Código serpro -->

						</div>
					</div>
					<!-- end form -->

				</div><!-- card body -->
			</div><!-- card -->
		</div><!-- col-12 -->

		<vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
	</div><!-- row -->
</template>

<script>
  export default {
    props: ["detrancidade"],
    data() {
      return {
        uf: this.getData('uf'),
        municipio: this.getData('municipio'),
        codigo_origem: this.getData('codigo_origem'),
        loading: false,
        errors:[],
        fullPage: true,
		states: ['AC','AL','AM','AP','BA','CE','DF','ES','GO','MA','MT','MS','MG','PA','PB','PR','PE','PI','RJ','RN','RO','RS','RR','SC','SE','SP','TO']
      }
    },
    mounted() {
        // console.log(this.detrancidade);
    },
    methods: {
        
      getData(key) {
        return JSON.parse(this.detrancidade)[key];
      },
      updateData() {
        let _this = this;
        _this.errors = [];
        _this.message = '';
        _this.loading = true;
        let dataCidade = {
          'uf': _this.uf,
          'municipio': _this.municipio,
          'codigo_origem': _this.codigo_origem,
          '_method': 'PATCH'
        };
        axios.post('/admin/detranCidades/'+this.getData('id'), dataCidade).then((res) => {
            console.log(res)
          _this.loading = false;

          //show confirm alert
          _this.$swal({
            title: 'Sucesso',
            text: 'Usuário(a) atualizado com sucesso!',
            icon: 'success'
          });
        }).then(() => location.href="/admin/detranCidades")
        .catch((err) => {

          _this.loading = false;

          //show error alert
          _this.$swal({
            title: 'Erro',
            text: 'Falha ao atualizar usuário(a). Tente novamente!',
            icon: 'error'
          });
        });
      }
    }
  }
</script>
