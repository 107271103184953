<template>
	<div class="row">
		<div class="col-12">
			<div class="card">

				<!-- card header -->
				<div class="card-header">
					<h4>Preencha os dados abaixo</h4>

					<a @click="newData()" href="#" class="btn btn-sm btn-primary text-white btn-right">SALVAR</a>
				</div>
				<!-- end card header -->

				<!-- card body -->
				<div class="card-body">

					<!-- form -->
					<h6 class="heading-small text-muted mb-4">Dados</h6>
					<div class="">
						<div class="row">

							<!-- estado -->
							<div class="col-md-2">
								<div class="form-group">
									<label class="form-control-label" for="input-uf">Estado</label>
									<select type="text" id="input-uf" v-bind:class="{'is-invalid': errors.uf}" class="form-control" placeholder="uf" v-model="uf" required="true">
										<option v-for="item, index in states" :key="index">{{item}}</option>
									</select>
									<div class="invalid-feedback" v-if="errors.uf">
										<p>{{ errors.uf[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-6 -->
							<!-- end estado -->

							<!-- Municipio -->
							<div class="col-md-8">
								<div class="form-group">
									<label class="form-control-label" for="input-municipio">Municípios</label>
									<input type="text" id="input-municipio" v-bind:class="{'is-invalid': errors.municipio}" class="form-control" placeholder="Municípios" v-model="municipio" required="true">
									<div class="invalid-feedback" v-if="errors.municipio">
										<p>{{ errors.municipio[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-6 -->
							<!-- end Municipio -->

							<!-- Código serpro -->
							<div class="col-md-2">
								<div class="form-group">
									<label class="form-control-label" for="input-codigo_origem">Codigo serpro</label>
									<input type="number" id="input-codigo_origem" v-bind:class="{'is-invalid': errors.codigo_origem}" class="form-control" placeholder="Código Serpro" v-model="codigo_origem" required="true">
									<div class="invalid-feedback" v-if="errors.codigo_origem">
										<p>{{ errors.codigo_origem[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-6 -->
							<!-- end Código serpro -->

						</div>
					</div>
					<!-- end form -->

				</div><!-- card body -->
			</div><!-- card -->
		</div><!-- col-12 -->

		<vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
	</div><!-- row -->
</template>

<script>
	import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

	export default {
		data() {
			return {
				detranCidade: [],
				uf: "",
				municipio: "",
				codigo_origem: "",
				errors: [],
				message: "",
				loading: false,
				fullPage: true,
				states: ['AC','AL','AM','AP','BA','CE','DF','ES','GO','MA','MT','MS','MG','PA','PB','PR','PE','PI','RJ','RN','RO','RS','RR','SC','SE','SP','TO']
			}
		},
		mounted() {
			// this.loadDetranCidade();
		},
		methods: {
			newData() {
				let _this = this;
				_this.errors = [];
				_this.message = "";
				_this.loading = true;
				let dataInfo = {
					"uf" : _this.uf,
					"municipio" : _this.municipio,
					"codigo_origem" : _this.codigo_origem,
				};
				axios.post("/admin/detranCidades", dataInfo).then((res) => {
                    // console.log(res);
					_this.loading = false;

					//show confirm alert
						if(!res.data.erro){
							_this.$swal({
								title: 'Sucesso',
								text: 'Municipios salvos com sucesso',
								icon: 'success'
							});
						}else{
							_this.$swal({
								title: 'Erro',
								text: res.data.erro,
								icon: 'error'
							});
						}
				}).then(() => location.href="/admin/detranCidades")
                .catch((err) => {

					console.log(err);

					_this.loading = false;

					//show error alert
					_this.$swal({
						title: "Erro",
						text: "Falha ao cadastrar o município. Tente novamente!",
						icon: "error"
					});
				});
			},
			// loadDetranCidade() {
			// 	let _this = this;
			// 	_this.loading = true;
			// 	axios.get("/admin/detranCidade").then((res) => {
			// 		_this.detranCidade = _this.detranCidade.concat(res.data);
			// 		_this.loading = false;
			// 	}).catch((err) => {
			// 		_this.loading = false;
			// 	});
			// },
			resetForm() {
				this.uf = '';
				this.municipio = '';
				this.codigo_origem = '';
			}
			},
		}
</script>
