<template>
    <div class="row">
        <div class="col-12">
            <div class="card pt-1 pb-1" style="margin-top:-35px; margin-bottom: 5px;">
                <div class="wizard-form" id="grad1">
                    <div class="row justify-content-center mt-0">
                        <div class="col-12">
                            <div class="card mt-1 mb-1 bxShadow">
                                <h5 class="text-center"><strong>{{ titleform }}</strong></h5>
                                <div class="row">
                                    <div class="col-md-12 mx-0 mb-1">
                                        <form id="msform" class="mt-2">

                                            <!-- progressbar -->
                                            <ul id="progressbar" class="text-center">
                                                <a @click="changeStep(1)">
                                                    <li :class="step >= 1 ? 'active' : ''" id="geral"><strong>Gerais e
                                                            Veículo</strong></li>
                                                </a>
                                                <a @click="changeStep(2)">
                                                    <li :class="step >= 2 ? 'active' : ''" id="financeiro"><strong>Dados
                                                            Financeiros</strong></li>
                                                </a>
                                                <a v-show="false" @click="changeStep(4)">
                                                    <li :class="step >= 4 ? 'active' : ''" id="agente">
                                                        <strong>Agente</strong>
                                                    </li>
                                                </a>
                                                <a @click="changeStep(3)">
                                                    <li :class="step >= 3 ? 'active' : ''" id="comprador">
                                                        <strong>Comprador</strong>
                                                    </li>
                                                </a>
                                            </ul>
                                            <!-- end progressbar -->

                                            <!-- gerais e veículos -->
                                            <fieldset v-if="step == 1">
                                                <div class="form-card">
                                                    <div class="row form-distance" style="margin-top:-15px">

                                                        <!-- tipo_operacao -->
                                                        <div class="col-md-3">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-tipo_operacao">Tipo de Operação</label>
                                                                <select id="input-tipo_operacao"
                                                                    v-bind:class="{ 'is-invalid': errors.tipo_operacao }"
                                                                    v-model="tipo_operacao" class="form-control"
                                                                    placeholder="Tipo de Operação">
                                                                    <option value="1">1 - Inclusão do registro de
                                                                        contrato
                                                                    </option>
                                                                    <option value="2">2 - Alteração do registro de
                                                                        contrato
                                                                    </option>
                                                                    <option value="3">3 - Inclusão do aditivo de
                                                                        contrato
                                                                    </option>
                                                                    <option value="4">4 - Alteração do aditivo de
                                                                        contrato
                                                                    </option>
                                                                </select>
                                                                <div v-if="errors.tipo_operacao"
                                                                    class="invalid-feedback">
                                                                    <p>{{ errors.tipo_operacao }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-3 -->
                                                        <!-- end tipo_operacao -->

                                                        <!-- num_contrato_origem -->
                                                        <div class="col-md-2">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-num_contrato_origem">Nº Contrato
                                                                    Origem</label>
                                                                <input id="input-num_contrato_origem"
                                                                    v-model="num_contrato_origem" class="form-control"
                                                                    maxlength="14" placeholder="Nº Contrato Origem"
                                                                    required="true" type="text"
                                                                    v-bind:class="{ 'is-invalid': errors.num_contrato_origem }"
                                                                    @input="validarCampos('type_numContrato', 'num_contrato_origem')">
                                                                <div v-if="errors.num_contrato_origem"
                                                                    class="invalid-feedback">
                                                                    <p>{{ errors.num_contrato_origem }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-3 -->
                                                        <!-- end num_contrato_origem -->

                                                        <!-- num_aditivo_origem -->
                                                        <div class="col-md-2">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-num_aditivo_origem">Nº Aditivo
                                                                    Origem</label>
                                                                <input id="input-num_aditivo_origem"
                                                                    v-model="num_aditivo_origem" class="form-control"
                                                                    maxlength="14" placeholder="Nº Aditivo Origem"
                                                                    type="text"
                                                                    v-bind:class="{ 'is-invalid': errors.num_aditivo_origem }"
                                                                    :disabled="tipo_operacao === '1' || tipo_operacao === '2'"
                                                                    @input="validarCampos('type_numContrato', 'num_aditivo_origem')">
                                                                <div v-if="errors.num_aditivo_origem"
                                                                    class="invalid-feedback">
                                                                    <p>{{ errors.num_aditivo_origem }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-3 -->
                                                        <!-- end num_aditivo_origem -->

                                                        <!-- chassi -->
                                                        <div class="col-md-3">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-chassi">Chassi</label>
                                                                <input id="input-chassi" v-model="chassi"
                                                                    class="form-control" maxlength="25"
                                                                    placeholder="Chassi" required="true" type="text"
                                                                    v-bind:class="{ 'is-invalid': errors.chassi }"
                                                                    @input="validarCampos('type_number_Lyrics', 'chassi')">
                                                                <div v-if="errors.chassi" class="invalid-feedback">
                                                                    <p>{{ errors.chassi }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-4 -->
                                                        <!-- end chassi -->

                                                        <!--condicao do veiculo-->
                                                        <div class="col-md-2">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-indicador_novo_usado">Condição do
                                                                    Veículo</label>
                                                                <select id="input-indicador_novo_usado"
                                                                    class="form-control" v-model="indicador_novo_usado">
                                                                    <option value="N">Novo</option>
                                                                    <option value="U">Seminovo</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <!--end condicao do veiculo-->

                                                        <!-- remarcacao -->
                                                        <div class="col-md-2">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-remarcacao">Remarcação</label>
                                                                <select type="text" id="input-remarcacao"
                                                                    v-bind:class="{ 'is-invalid': errors.remarcacao }"
                                                                    class="form-control" placeholder="Remarcação"
                                                                    v-model="remarcacao" required="true"
                                                                    :disabled="indicador_novo_usado === 'N'">
                                                                    <option value="1">Remarcado</option>
                                                                    <option value="2">Não Remarcado</option>
                                                                </select>
                                                                <div class="invalid-feedback" v-if="errors.remarcacao">
                                                                    <p>{{ errors.remarcacao }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-2 -->
                                                        <!-- end remarcacao -->

                                                        <!-- uf_licenciamento -->
                                                        <div class="col-md-2" style="display:none;">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-uf_licenciamento">UF
                                                                    Licenciamento</label>
                                                                <select id="input-uf_licenciamento"
                                                                    v-model="uf_licenciamento" class="form-control"
                                                                    placeholder="UF Placa"
                                                                    v-bind:class="{ 'is-invalid': errors.uf_licenciamento }"
                                                                    :disabled=true>
                                                                    <option v-for="item in states">{{ item }}</option>
                                                                </select>
                                                                <div v-if="errors.uf_licenciamento"
                                                                    class="invalid-feedback">
                                                                    <p>{{ errors.uf_licenciamento }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-6 -->
                                                        <!-- end uf_placa -->

                                                        <!-- uf_placa -->
                                                        <div class="col-md-2">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-uf_placa">UF
                                                                    Placa</label>
                                                                <select id="input-uf_placa" v-model="uf_placa"
                                                                    class="form-control" placeholder="UF Placa"
                                                                    required="true"
                                                                    v-bind:class="{ 'is-invalid': errors.uf_placa }"
                                                                    :disabled="indicador_novo_usado === 'N'">
                                                                    <option v-for="item in states">{{ item }}</option>
                                                                </select>
                                                                <div v-if="errors.uf_placa" class="invalid-feedback">
                                                                    <p>{{ errors.uf_placa }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-6 -->
                                                        <!-- end uf_placa -->

                                                        <!-- placa -->
                                                        <div class="col-md-3">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-placa">Placa</label>
                                                                <input id="input-placa" v-model="placa"
                                                                    class="form-control" maxlength="7"
                                                                    placeholder="Placa" required="true" type="text"
                                                                    v-bind:class="{ 'is-invalid': errors.placa }"
                                                                    :disabled="indicador_novo_usado === 'N'"
                                                                    @input="validarCampos('type_number_Lyrics', 'placa')">
                                                                <div v-if="errors.placa" class="invalid-feedback">
                                                                    <p>{{ errors.placa }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-4 -->
                                                        <!-- end placa -->

                                                        <!-- renavam -->
                                                        <div class="col-md-3">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-renavam">Renavam</label>
                                                                <input type="tel" id="input-renavam"
                                                                    v-bind:class="{ 'is-invalid': errors.renavam }"
                                                                    class="form-control" placeholder="Renavam"
                                                                    v-model="renavam" required="true" maxlength="20"
                                                                    :disabled="indicador_novo_usado === 'N'"
                                                                    @input="validarCampos('type_numbers', 'renavam')">
                                                                <div class="invalid-feedback" v-if="errors.renavam">
                                                                    <p>{{ errors.renavam }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-2 -->
                                                        <!-- end renavam -->

                                                        <!-- ano_fabricacao -->
                                                        <div class="col-md-2">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-ano_fabricacao">Ano Fabricação</label>
                                                                <input id="input-ano_fabricacao"
                                                                    v-model="ano_fabricacao" class="form-control"
                                                                    maxlength="4" placeholder="Ano Fabricação"
                                                                    required="true" type="tel"
                                                                    v-bind:class="{ 'is-invalid': errors.ano_fabricacao }"
                                                                    @input="validarCampos('type_numbers', 'ano_fabricacao')">
                                                                <div v-if="errors.ano_fabricacao"
                                                                    class="invalid-feedback">
                                                                    <p>{{ errors.ano_fabricacao }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-2 -->
                                                        <!-- end ano_fabricacao -->

                                                        <!-- ano_modelo -->
                                                        <div class="col-md-2">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-ano_modelo">Ano
                                                                    Modelo</label>
                                                                <input id="input-ano_modelo" v-model="ano_modelo"
                                                                    class="form-control" maxlength="4"
                                                                    placeholder="Ano Modelo" required="true" type="text"
                                                                    v-bind:class="{ 'is-invalid': errors.ano_modelo }"
                                                                    @input="validarCampos('type_numbers', 'ano_modelo')">
                                                                <div v-if="errors.ano_modelo" class="invalid-feedback">
                                                                    <p>{{ errors.ano_modelo }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-2 -->
                                                        <!-- end ano_modelo -->

                                                        <!-- Tipo de veiculos -->
                                                        <div class="col-md-2">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-tipo_veiculo">Tipo de Veículo</label>
                                                                <v-select v-model="tipo_id" :options="tipos"
                                                                    :reduce="nome => nome.id" label="nome"
                                                                    @change="getMarcas" @input="getMarcas"></v-select>
                                                                <div v-if="errors.tipo_id" class="invalid-feedback">
                                                                    <p>{{ errors.tipo_id }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div>
                                                        <!-- Tipo de veiculos -->

                                                        <!-- marcas -->
                                                        <div class="col-md-3">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-marcas">Marcas
                                                                    do veículo</label>
                                                                <v-select v-model="marca_id" :options="marcas"
                                                                    :reduce="nome => nome.id" label="nome"
                                                                    @change="getModelos" @input="getModelos"></v-select>
                                                                <div v-if="errors.marca" class="invalid-feedback">
                                                                    <p>{{ errors.marca }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-2 -->
                                                        <!-- end marcas -->

                                                        <!-- Modelos -->
                                                        <div class="col-md-3">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-modelos">Modelos</label>
                                                                <v-select v-model="modelo_id" :options="modelos"
                                                                    :reduce="nome => nome.id" label="nome"></v-select>
                                                                <div v-if="errors.modelos" class="invalid-feedback">
                                                                    <p>{{ errors.modelos }}</p>
                                                                </div>
                                                            </div><!-- form group modelos-->
                                                        </div><!-- col-md-2 modelos-->

                                                        <!-- cores -->
                                                        <div class="col-md-1">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-modelos">Cores</label>
                                                                <v-select v-model="cor_id" :options="cores"
                                                                    :reduce="nome => nome.id" label="nome"></v-select>
                                                                <div v-if="errors.cores" class="invalid-feedback">
                                                                    <p>{{ errors.cores }}</p>
                                                                </div>
                                                            </div><!-- form group cores-->
                                                        </div><!-- col-md-2 cores-->

                                                        <div class="col-md-12">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-modelos">Observações</label>
                                                                <textarea id="input-observacao" v-model="observacao"
                                                                    class="form-control" maxlength="255"
                                                                    placeholder="Observações" style="height: 90px"
                                                                    v-bind:class="{ 'is-invalid': errors.observacao }">
		                                                             </textarea>
                                                            </div>
                                                        </div>

                                                    </div> <!--row p-4-->
                                                </div> <!--form card-->
                                                <a class="btn-next" @click="changeStep(2)">Próximo</a>
                                            </fieldset>
                                            <!-- end gerais e veículos -->

                                            <!--- dados financeiros -->
                                            <fieldset v-if="step == 2">
                                                <div class="form-card">
                                                    <div class="row form-distance" style="margin-top:-15px">

                                                        <!-- num_contrato -->
                                                        <div class="col-md-3">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-num_contrato">Nº Contrato</label>
                                                                <input id="input-num_contrato" v-model="num_contrato"
                                                                    class="form-control" maxlength="20"
                                                                    placeholder="Nº Contrato" required="true"
                                                                    type="text"
                                                                    v-bind:class="{ 'is-invalid': errors.num_contrato }"
                                                                    @input="validarCampos('type_numContrato', 'num_contrato')">
                                                                <div v-if="errors.num_contrato"
                                                                    class="invalid-feedback">
                                                                    <p>{{ errors.num_contrato }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-7 -->
                                                        <!-- end num_contrato -->

                                                        <!-- data_contrato -->
                                                        <div class="col-md-2">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-data_contrato">Data Contrato</label>
                                                                <input id="input-data_contrato" v-model="data_contrato"
                                                                    class="form-control" placeholder="Data Contrato"
                                                                    required="true" type="date"
                                                                    v-bind:class="{ 'is-invalid': errors.data_contrato }">
                                                                <div v-if="errors.data_contrato"
                                                                    class="invalid-feedback">
                                                                    <p>{{ errors.data_contrato }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-3 -->
                                                        <!-- end data_contrato -->

                                                        <!-- qtd_parcelas -->
                                                        <div class="col-md-1">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-qtd_parcelas">Qtd Parcelas</label>
                                                                <input id="input-qtd_parcelas" v-model="qtd_parcelas"
                                                                    class="form-control" maxlength="3"
                                                                    placeholder="Qtd Parcelas" required="true"
                                                                    type="tel"
                                                                    v-bind:class="{ 'is-invalid': errors.qtd_parcelas }"
                                                                    @input="validarCampos('type_numbers', 'qtd_parcelas')">
                                                                <div v-if="errors.qtd_parcelas"
                                                                    class="invalid-feedback">
                                                                    <p>{{ errors.qtd_parcelas }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-2 -->
                                                        <!-- end qtd_parcelas -->

                                                        <!-- num_gravame -->
                                                        <div class="col-md-2">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-num_gravame">Nº
                                                                    Gravame</label>
                                                                <input id="input-num_gravame" v-model="num_gravame"
                                                                    class="form-control" maxlength="22"
                                                                    placeholder="Nº Gravame" required="true" type="tel"
                                                                    v-bind:class="{ 'is-invalid': errors.num_gravame }"
                                                                    @input="validarCampos('type_gravame', 'num_gravame')">
                                                                <div v-if="errors.num_gravame" class="invalid-feedback">
                                                                    <p>{{ errors.num_gravame }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-3 -->
                                                        <!-- end num_gravame -->

                                                        <!-- tipo_gravame -->
                                                        <div class="col-md-2">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-tipo_gravame">Tipo Gravame</label>
                                                                <select type="text" id="input-tipo_gravame"
                                                                    v-bind:class="{ 'is-invalid': errors.tipo_gravame }"
                                                                    class="form-control" placeholder="Tipo Gravame"
                                                                    v-model="tipo_gravame" required="true">
                                                                    <option value="1">Arrendamento Mercantil</option>
                                                                    <option value="2">Reserva de Domínio</option>
                                                                    <option value="3">Alienação Fidunciária</option>
                                                                    <option value="9">Penhor</option>
                                                                </select>
                                                                <div class="invalid-feedback"
                                                                    v-if="errors.tipo_gravame">
                                                                    <p>{{ errors.tipo_gravame }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-3 -->
                                                        <!-- end tipo_gravame -->

                                                        <!-- taxa_juro_mes -->
                                                        <div class="col-md-2">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-taxa_juro_mes">Taxa Juros Mês</label>
                                                                <money v-model="taxa_juro_mes" class="form-control"
                                                                    maxlength="9" v-bind="money">
                                                                </money>
                                                                <div v-if="errors.taxa_juro_mes"
                                                                    class="invalid-feedback">
                                                                    <p>{{ errors.taxa_juro_mes }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-3 -->
                                                        <!-- end taxa_juro_mes -->

                                                        <!-- taxa_juro_ano -->
                                                        <div class="col-md-2">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-taxa_juro_ano">Taxa Juros Ano</label>
                                                                <money v-model="taxa_juro_ano" class="form-control"
                                                                    maxlength="9" v-bind="money">
                                                                </money>
                                                                <div v-if="errors.taxa_juro_ano"
                                                                    class="invalid-feedback">
                                                                    <p>{{ errors.taxa_juro_ano }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-3 -->
                                                        <!-- end taxa_juro_ano -->

                                                        <!-- taxa_juro_multa -->
                                                        <div class="col-md-2">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-taxa_juro_multa">Tx. Juros
                                                                    Multa?</label>
                                                                <select type="text" id="input-taxa_juro_multa"
                                                                    @change="disableMulta(taxa_juro_multa, 'taxa_juro_multa')"
                                                                    v-bind:class="{ 'is-invalid': errors.taxa_juro_multa }"
                                                                    class="form-control"
                                                                    placeholder="Taxa de Juros Multa"
                                                                    v-model="taxa_juro_multa" required="true">
                                                                    <option value="Não">Não</option>
                                                                    <option value="Sim">Sim</option>
                                                                </select>
                                                                <div class="invalid-feedback"
                                                                    v-if="errors.taxa_juro_multa">
                                                                    <p>{{ errors.taxa_juro_multa }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-3 -->
                                                        <!-- end taxa_juro_multa -->

                                                        <!-- taxa_multa -->
                                                        <div class="col-md-2">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-taxa_multa">
                                                                    Taxa Juros Multa</label>
                                                                <money v-model="taxa_multa"
                                                                    class="form-control taxa_juro_multa" v-bind="money"
                                                                    maxlength="9"
                                                                    v-bind:class="{ 'is-invalid': errors.taxa_multa }">
                                                                </money>
                                                                <div class="invalid-feedback" v-if="errors.taxa_multa">
                                                                    <p>{{ errors.taxa_multa }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-3 -->
                                                        <!-- end taxa_multa -->

                                                        <!-- taxa_mora_dia -->
                                                        <div class="col-md-1">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-taxa_mora_dia">Tx. Mora Dia?</label>
                                                                <select type="text" id="input-taxa_mora_dia"
                                                                    @change="disableMora(taxa_mora_dia, 'taxa_mora_dia')"
                                                                    v-bind:class="{ 'is-invalid': errors.taxa_mora_dia }"
                                                                    class="form-control" placeholder=""
                                                                    v-model="taxa_mora_dia" required="true">
                                                                    <option value="Não">Não</option>
                                                                    <option value="Sim">Sim</option>
                                                                </select>
                                                                <div class="invalid-feedback"
                                                                    v-if="errors.taxa_mora_dia">
                                                                    <p>{{ errors.taxa_mora_dia }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-3 -->
                                                        <!-- end taxa_mora_dia -->

                                                        <!-- taxa_mora -->
                                                        <div class="col-md-2">
                                                            <div class="form-group">
                                                                <label class="form-control-label" for="input-taxa_mora">
                                                                    Taxa Mora Dia</label>
                                                                <money v-model="taxa_mora"
                                                                    class="form-control taxa_mora_dia" v-bind="money"
                                                                    maxlength="13"
                                                                    v-bind:class="{ 'is-invalid': errors.taxa_mora }">
                                                                </money>
                                                                <div class="invalid-feedback" v-if="errors.taxa_mora">
                                                                    <p>{{ errors.taxa_mora }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-3 -->
                                                        <!-- end taxa_mora -->

                                                        <!-- indicativo_penalidade -->
                                                        <div class="col-md-1">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-indicativo_penalidade">Penalidade?</label>
                                                                <select type="text" id="input-indicativo_penalidade"
                                                                    @change="disablePenalidade(indicativo_penalidade, 'indicativo_penalidade')"
                                                                    v-bind:class="{ 'is-invalid': errors.indicativo_penalidade }"
                                                                    class="form-control" placeholder=""
                                                                    v-model="indicativo_penalidade" required="true">
                                                                    <option value="Não">Não</option>
                                                                    <option value="Sim">Sim</option>
                                                                </select>
                                                                <div class="invalid-feedback"
                                                                    v-if="errors.indicativo_penalidade">
                                                                    <p>{{ errors.indicativo_penalidade }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-3 -->
                                                        <!-- end indicativo_penalidade -->

                                                        <!-- penalidade -->
                                                        <div class="col-md-2">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-penalidade">Penalidade</label>
                                                                <input type="text" id="input-penalidade"
                                                                    v-bind:class="{ 'is-invalid': errors.penalidade }"
                                                                    class="form-control penalidade"
                                                                    placeholder="Penalidade" v-model="penalidade"
                                                                    required="true" maxlength="50">
                                                                <div class="invalid-feedback" v-if="errors.penalidade">
                                                                    <p>{{ errors.penalidade }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-6 -->
                                                        <!-- end penalidade -->

                                                        <!-- indicativo_comissao -->
                                                        <div class="col-md-2">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-indicativo_comissao">Comissão?</label>
                                                                <select type="text" id="input-indicativo_comissao"
                                                                    @change="disableComissao(indicativo_comissao, 'indicativo_comissao')"
                                                                    v-bind:class="{ 'is-invalid': errors.indicativo_comissao }"
                                                                    class="form-control" placeholder=""
                                                                    v-model="indicativo_comissao" required="true">
                                                                    <option value="Não">Não</option>
                                                                    <option value="Sim">Sim</option>
                                                                </select>
                                                                <div class="invalid-feedback"
                                                                    v-if="errors.indicativo_comissao">
                                                                    <p>{{ errors.indicativo_comissao }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-3 -->
                                                        <!-- end indicativo_comissao -->

                                                        <!-- comissao -->
                                                        <div class="col-md-2">
                                                            <div class="form-group">
                                                                <label class="form-control-label" for="input-comissao">
                                                                    Comissão</label>
                                                                <money v-model="comissao"
                                                                    class="form-control indicativo_comissao"
                                                                    v-bind="valueMaskD3" maxlength="11"
                                                                    v-bind:class="{ 'is-invalid': errors.comissao }">
                                                                </money>
                                                                <div class="invalid-feedback" v-if="errors.comissao">
                                                                    <p>{{ errors.comissao }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-3 -->
                                                        <!-- end comissao -->

                                                        <!-- valor_taxa_contrato -->
                                                        <div class="col-md-2">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-valor_taxa_contrato">Valor Taxa
                                                                    Contrato</label>
                                                                <money v-model="valor_taxa_contrato"
                                                                    class="form-control" maxlength="14"
                                                                    v-bind="SevenMoney"></money>
                                                                <div v-if="errors.valor_taxa_contrato"
                                                                    class="invalid-feedback">
                                                                    <p>{{ errors.valor_taxa_contrato }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-3 -->
                                                        <!-- end valor_taxa_contrato -->

                                                        <!-- valor_total_financiamento -->
                                                        <div class="col-md-2">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-valor_total_financiamento">Valor Total do
                                                                    Financiamento</label>
                                                                <money v-model="valor_total_financiamento"
                                                                    class="form-control" maxlength="14" required
                                                                    v-bind="SevenMoney"></money>
                                                                <div v-if="errors.valor_total_financiamento"
                                                                    class="invalid-feedback">
                                                                    <p>{{ errors.valor_total_financiamento }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-3 -->
                                                        <!-- end valor_total_financiamento -->

                                                        <!-- valor_parcela -->
                                                        <div class="col-md-2">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-valor_parcela">Valor da Parcela</label>
                                                                <money v-model="valor_parcela" class="form-control"
                                                                    maxlength="14" v-bind="SevenMoney">
                                                                </money>
                                                                <div v-if="errors.valor_parcela"
                                                                    class="invalid-feedback">
                                                                    <p>{{ errors.valor_parcela }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-3 -->
                                                        <!-- end valor_parcela -->

                                                        <!-- valor_iof -->
                                                        <div class="col-md-2">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-valor_iof">Valor IOF</label>
                                                                <money v-model="valor_iof" class="form-control"
                                                                    maxlength="14" v-bind="SevenMoney">
                                                                </money>
                                                                <div v-if="errors.valor_iof" class="invalid-feedback">
                                                                    <p>{{ errors.valor_iof }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-3 -->
                                                        <!-- end valor_iof -->

                                                        <!-- data_vecto_primeira_parcela -->
                                                        <div class="col-md-2">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-data_vecto_primeira_parcela">Dt.
                                                                    Venc. 1ª Parcela</label>
                                                                <input id="input-data_vecto_primeira_parcela"
                                                                    v-model="data_vecto_primeira_parcela"
                                                                    class="form-control"
                                                                    placeholder="data_vecto_primeira_parcela"
                                                                    required="true" type="date"
                                                                    v-bind:class="{ 'is-invalid': errors.data_vecto_primeira_parcela }">
                                                                <div v-if="errors.data_vecto_primeira_parcela"
                                                                    class="invalid-feedback">
                                                                    <p>{{ errors.data_vecto_primeira_parcela }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-3 -->
                                                        <!-- end data_vecto_primeira_parcela -->

                                                        <!-- data_vecto_ultima_parcela -->
                                                        <div class="col-md-2">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-data_vecto_ultima_parcela">Dt. de
                                                                    Venc. Última Parcela</label>
                                                                <input id="input-data_vecto_ultima_parcela"
                                                                    v-model="data_vecto_ultima_parcela"
                                                                    class="form-control"
                                                                    placeholder="data_vecto_ultima_parcela"
                                                                    required="true" type="date"
                                                                    v-bind:class="{ 'is-invalid': errors.data_vecto_ultima_parcela }">
                                                                <div v-if="errors.data_vecto_ultima_parcela"
                                                                    class="invalid-feedback">
                                                                    <p>{{ errors.data_vecto_ultima_parcela }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-4 -->
                                                        <!-- end data_vecto_ultima_parcela -->

                                                        <!-- data_liberacao_credito -->
                                                        <div class="col-md-2">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-data_liberacao_credito">Data de Liberação
                                                                    do Crédito</label>
                                                                <input id="input-data_liberacao_credito"
                                                                    v-model="data_liberacao_credito"
                                                                    class="form-control"
                                                                    placeholder="data_liberacao_credito" required="true"
                                                                    type="date"
                                                                    v-bind:class="{ 'is-invalid': errors.data_liberacao_credito }">
                                                                <div v-if="errors.data_liberacao_credito"
                                                                    class="invalid-feedback">
                                                                    <p>{{ errors.data_liberacao_credito }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-4 -->
                                                        <!-- end data_liberacao_credito -->

                                                        <!-- uf_liberacao_credito -->
                                                        <div class="col-md-2">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-uf_liberacao_credito">UF de Liberação do
                                                                    Crédito</label>
                                                                <select id="input-uf_liberacao_credito"
                                                                    v-model="uf_liberacao_credito" class="form-control"
                                                                    placeholder="UF Placa" required="true"
                                                                    v-bind:class="{ 'is-invalid': errors.uf_liberacao_credito }">
                                                                    <option v-for="item in states">{{ item }}</option>
                                                                </select>
                                                                <div v-if="errors.uf_liberacao_credito"
                                                                    class="invalid-feedback">
                                                                    <p>{{ errors.uf_liberacao_credito }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-3 -->
                                                        <!-- end uf_liberacao_credito -->

                                                        <!-- municipio_liberacao_credito -->
                                                        <div class="col-md-4">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-municipio_liberacao_credito">Município de
                                                                    Liberação do Crédito</label>
                                                                <v-select label="municipio"
                                                                    id="input-municipio_liberacao_credito"
                                                                    :options="municipios_lb"
                                                                    :reduce="municipio => municipio.municipio"
                                                                    v-model="municipio_liberacao_credito"
                                                                    @input="settedMunicipioCredito"
                                                                    v-bind:class="{ 'is-invalid': errors.municipio_liberacao_credito }">
                                                                </v-select>
                                                                <div v-if="errors.municipio_liberacao_credito"
                                                                    class="invalid-feedback">
                                                                    <p>{{ errors.municipio_liberacao_credito }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-6 -->
                                                        <!-- end municipio_liberacao_credito -->

                                                        <!-- indice -->
                                                        <div class="col-md-3">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-indice">Indíce</label>
                                                                <input id="input-indice" v-model="indice"
                                                                    class="form-control" maxlength="10"
                                                                    placeholder="Índice. Ex: SELIC, IGPM, IPCA, entre outros."
                                                                    required="true" type="text"
                                                                    v-bind:class="{ 'is-invalid': errors.indice }"
                                                                    @input="validarCampos('type_indice', 'indice')">
                                                                <div v-if="errors.indice" class="invalid-feedback">
                                                                    <p>{{ errors.indice }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-2 -->
                                                        <!-- end indice -->

                                                        <!-- num_grupo_consorcio -->
                                                        <div class="col-md-2">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-num_grupo_consorcio">Nº Grupo
                                                                    Consórcio</label>
                                                                <input id="input-num_grupo_consorcio"
                                                                    v-model="num_grupo_consorcio" class="form-control"
                                                                    maxlength="6" placeholder="Nº Grupo Consórcio"
                                                                    required="true" type="text"
                                                                    v-bind:class="{ 'is-invalid': errors.num_grupo_consorcio }"
                                                                    @input="validarCampos('type_numContrato', 'num_grupo_consorcio')">
                                                                <div v-if="errors.num_grupo_consorcio"
                                                                    class="invalid-feedback">
                                                                    <p>{{ errors.num_grupo_consorcio }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-2 -->
                                                        <!-- end num_grupo_consorcio -->

                                                        <!-- num_cota_consorcio -->
                                                        <div class="col-md-2">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-num_cota_consorcio">Nº Cota
                                                                    Consórcio</label>
                                                                <input id="input-num_cota_consorcio"
                                                                    v-model="num_cota_consorcio" class="form-control"
                                                                    maxlength="6" placeholder="Nº Cota Consórcio"
                                                                    required="false" type="tel"
                                                                    v-bind:class="{ 'is-invalid': errors.num_cota_consorcio }"
                                                                    @input="validarCampos('type_numContrato', 'num_cota_consorcio')">
                                                                <div v-if="errors.num_cota_consorcio"
                                                                    class="invalid-feedback">
                                                                    <p>{{ errors.num_cota_consorcio }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-2 -->
                                                        <!-- end num_cota_consorcio -->

                                                        <!-- num_aditivo -->
                                                        <div class="col-md-3">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-num_aditivo">Nº
                                                                    Aditivo</label>
                                                                <input id="input-num_aditivo" v-model="num_aditivo"
                                                                    class="form-control" maxlength="20"
                                                                    placeholder="Nº Aditivo" required="true" type="text"
                                                                    v-bind:class="{ 'is-invalid': errors.num_aditivo }"
                                                                    :disabled="tipo_operacao === '1' || tipo_operacao === '2'"
                                                                    @input="validarCampos('type_numContrato', 'num_aditivo')">
                                                                <div v-if="errors.num_aditivo" class="invalid-feedback">
                                                                    <p>{{ errors.num_aditivo }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-3 -->
                                                        <!-- end num_aditivo -->

                                                        <!-- data_aditivo -->
                                                        <div class="col-md-2">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-data_aditivo">Data Aditivo</label>
                                                                <input id="input-data_aditivo" v-model="data_aditivo"
                                                                    class="form-control" placeholder="data_aditivo"
                                                                    required="true" type="date"
                                                                    v-bind:class="{ 'is-invalid': errors.data_aditivo }"
                                                                    :disabled="tipo_operacao === '1' || tipo_operacao === '2'">
                                                                <div v-if="errors.data_aditivo"
                                                                    class="invalid-feedback">
                                                                    <p>{{ errors.data_aditivo }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-3 -->
                                                        <!-- end data_aditivo -->
                                                    </div>
                                                </div>
                                                <a class="btn-previous" @click="changeStep(1)">Anterior</a>
                                                <a class="btn-next" @click="changeStep(3)">Próximo</a>
                                            </fieldset>
                                            <!-- end dados financeiros -->

                                            <!-- agente -->
                                            <fieldset v-if="step == 4">
                                                <div class="form-card">
                                                    <div class="row form-distance">
                                                        <!-- nome_agente -->
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-nome_agente">Nome do Agente</label>
                                                                <input id="input-nome_agente" v-model="nome_agente"
                                                                    class="form-control" maxlength="40"
                                                                    placeholder="Nome do Agente" required="true"
                                                                    type="text"
                                                                    v-bind:class="{ 'is-invalid': errors.nome_agente }">
                                                                <div v-if="errors.nome_agente" class="invalid-feedback">
                                                                    <p>{{ errors.nome_agente }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-6 -->
                                                        <!-- end nome_agente -->

                                                        <!-- cnpj_agente -->
                                                        <div class="col-md-4">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-cnpj_agente">CNPJ do Agente</label>
                                                                <input id="input-cnpj_agente" v-model="cnpj_agente"
                                                                    v-mask="'##.###.###/####-##'" class="form-control"
                                                                    maxlength="18" placeholder="CNPJ do Agente"
                                                                    required="true" type="text"
                                                                    v-bind:class="{ 'is-invalid': errors.cnpj_agente }">
                                                                <div v-if="errors.cnpj_agente" class="invalid-feedback">
                                                                    <p>{{ errors.cnpj_agente }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-6 -->
                                                        <!-- end cnpj_agente -->

                                                        <!--codigo agente-->
                                                        <div class="col-md-2">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-codigo_agente">Código do Agente</label>
                                                                <input id="input-codigo_agente" v-model="codigo_agente"
                                                                    class="form-control disabled" disabled
                                                                    maxlength="10" placeholder="Código do Agente"
                                                                    style="cursor: not-allowed;" type="text"
                                                                    v-bind:class="{ 'is-invalid': errors.codigo_agente }">
                                                                <div v-if="errors.codigo_agente"
                                                                    class="invalid-feedback">
                                                                    <p>{{ errors.codigo_agente }}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!--end codigio agente-->

                                                        <!-- nome_logradouro_agente -->
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-nome_logradouro_agente">Logradouro</label>
                                                                <input id="input-nome_logradouro_agente"
                                                                    v-model="nome_logradouro_agente"
                                                                    class="form-control" maxlength="30"
                                                                    placeholder="Logradouro" required="true" type="text"
                                                                    v-bind:class="{ 'is-invalid': errors.nome_logradouro_agente }">
                                                                <div v-if="errors.nome_logradouro_agente"
                                                                    class="invalid-feedback">
                                                                    <p>{{ errors.nome_logradouro_agente }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-6 -->
                                                        <!-- end nome_logradouro_agente -->

                                                        <!-- num_imovel_agente -->
                                                        <div class="col-md-2">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-num_imovel_agente">Nº</label>
                                                                <input id="input-num_imovel_agente"
                                                                    v-model="num_imovel_agente" class="form-control"
                                                                    maxlength="5" placeholder="Nº" required="true"
                                                                    type="text"
                                                                    v-bind:class="{ 'is-invalid': errors.num_imovel_agente }">
                                                                <div v-if="errors.num_imovel_agente"
                                                                    class="invalid-feedback">
                                                                    <p>{{ errors.num_imovel_agente }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-2 -->
                                                        <!-- end num_imovel_agente -->

                                                        <!-- complemento_imovel_agente -->
                                                        <div class="col-md-4">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-complemento_imovel_agente">Complemento</label>
                                                                <input id="input-complemento_imovel_agente"
                                                                    v-model="complemento_imovel_agente"
                                                                    class="form-control" maxlength="20"
                                                                    placeholder="Complemento" required="true"
                                                                    type="text"
                                                                    v-bind:class="{ 'is-invalid': errors.complemento_imovel_agente }">
                                                                <div v-if="errors.complemento_imovel_agente"
                                                                    class="invalid-feedback">
                                                                    <p>{{ errors.complemento_imovel_agente }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-4 -->
                                                        <!-- end complemento_imovel_agente -->

                                                        <!-- bairro_agente -->
                                                        <div class="col-md-3">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-bairro_agente">Bairro</label>
                                                                <input id="input-bairro_agente" v-model="bairro_agente"
                                                                    class="form-control" maxlength="20"
                                                                    placeholder="Bairro" required="true" type="text"
                                                                    v-bind:class="{ 'is-invalid': errors.bairro_agente }">
                                                                <div v-if="errors.bairro_agente"
                                                                    class="invalid-feedback">
                                                                    <p>{{ errors.bairro_agente }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-3 -->
                                                        <!-- end bairro_agente -->

                                                        <!-- uf_agente -->
                                                        <div class="col-md-2">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-uf_agente">UF</label>
                                                                <select id="input-uf_agente" v-model="uf_agente"
                                                                    class="form-control" placeholder="UF"
                                                                    required="true"
                                                                    v-bind:class="{ 'is-invalid': errors.uf_agente }"
                                                                    @change="onChangeUf('agente', true)">
                                                                    <option v-for="item in states">{{ item }}</option>
                                                                </select>
                                                                <div v-if="errors.uf_agente" class="invalid-feedback">
                                                                    <p>{{ errors.uf_agente }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-2 -->
                                                        <!-- end uf_agente -->

                                                        <!-- nome_municipio_agente -->
                                                        <div class="col-md-4">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-nome_municipio_agente">Município</label>
                                                                <v-select v-model="municipio_ag"
                                                                    :options="municipios_ag"
                                                                    :reduce="municipio => municipio.codigo_origem"
                                                                    label="municipio"
                                                                    v-bind:class="{ 'is-invalid': errors.nome_municipio_agente }"
                                                                    @input="setSelected"></v-select>
                                                                <div v-if="errors.nome_municipio_agente"
                                                                    class="invalid-feedback d-block">
                                                                    <p>{{ errors.nome_municipio_agente }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-4 -->
                                                        <!-- end nome_municipio_agente -->

                                                        <!-- cep_agente -->
                                                        <div class="col-md-3">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-cep_agente">CEP</label>
                                                                <input id="input-cep_agente" v-model="cep_agente"
                                                                    v-mask="'#####-###'" class="form-control"
                                                                    maxlength="9" placeholder="CEP" required="true"
                                                                    type="text"
                                                                    v-bind:class="{ 'is-invalid': errors.cep_agente }">
                                                                <div v-if="errors.cep_agente" class="invalid-feedback">
                                                                    <p>{{ errors.cep_agente }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-3 -->
                                                        <!-- end cep_agente -->

                                                        <!-- ddd_agente -->
                                                        <div class="col-md-2">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-ddd_agente">DDD</label>
                                                                <input id="input-ddd_agente" v-model="ddd_agente"
                                                                    class="form-control" maxlength="4" placeholder="DDD"
                                                                    required="true" type="tel"
                                                                    v-bind:class="{ 'is-invalid': errors.ddd_agente }">
                                                                <div v-if="errors.ddd_agente" class="invalid-feedback">
                                                                    <p>{{ errors.ddd_agente }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-2 -->
                                                        <!-- end ddd_agente -->

                                                        <!-- telefone_agente -->
                                                        <div class="col-md-3">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-telefone_agente">Telefone</label>
                                                                <div
                                                                    v-if="this.telefone_agente.length != 9 || this.telefone_agente.length != 10">
                                                                    <input id="input-telefone_agente"
                                                                        v-model="telefone_agente" v-mask="'#####-####'"
                                                                        class="form-control" maxlength="10"
                                                                        placeholder="Telefone" required="true"
                                                                        type="text"
                                                                        v-bind:class="{ 'is-invalid': errors.telefone_agente }">
                                                                    <div v-if="this.telefone_agente.length == 8"
                                                                        style="display: hidden;">
                                                                        <input id="input-telefone_agente"
                                                                            v-model="telefone_agente"
                                                                            v-mask="'####-####'" class="form-control"
                                                                            disabled="true" maxlength="10"
                                                                            placeholder="Telefone" required="true"
                                                                            type="hidden"
                                                                            v-bind:class="{ 'is-invalid': errors.telefone_agente }">
                                                                    </div>
                                                                    <div v-if="this.telefone_agente.length == 10"
                                                                        style="display: hidden;">
                                                                        <input id="input-telefone_agente"
                                                                            v-model="telefone_agente"
                                                                            v-mask="'#####-####'" class="form-control"
                                                                            disabled="true" maxlength="10"
                                                                            placeholder="Telefone" required="true"
                                                                            type="hidden"
                                                                            v-bind:class="{ 'is-invalid': errors.telefone_agente }">
                                                                    </div>
                                                                </div>
                                                                <div v-if="errors.telefone_agente"
                                                                    class="invalid-feedback">
                                                                    <p>{{ errors.telefone_agente }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-3 -->
                                                        <!-- end telefone_agente -->
                                                    </div>
                                                </div>
                                                <a class="btn-previous" @click="changeStep(3)">Anterior</a>
                                                <a class="btn-next" @click="changeStep(4)">Próximo</a>
                                            </fieldset>
                                            <!-- end agente -->

                                            <!-- comprador -->
                                            <fieldset v-if="step == 3">
                                                <div class="form-card">
                                                    <div class="row form-distance" style="margin-top:-15px">
                                                        <!-- cpf_cnpj_devedor -->
                                                        <div class="col-md-2">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-cpf_cnpj_devedor">CPF / CNPJ</label>
                                                                <div
                                                                    v-if="this.cpf_cnpj_devedor.length != 14 || this.cpf_cnpj_devedor.length != 18">
                                                                    <input id="input-cpf_cnpj_devedor"
                                                                        v-model="cpf_cnpj_devedor" class="form-control"
                                                                        maxlength="18" placeholder="CPF / CNPJ"
                                                                        required="true" type="text"
                                                                        v-bind:class="{ 'is-invalid': errorMessages.cpf_cnpj_devedor || errors.cpf_cnpj_devedor }"
                                                                        @input="validateCpfCnpj(cpf_cnpj_devedor)"
                                                                        @blur="validateCpfCnpj($event.target.value)"
                                                                        @keydown.space.prevent="preventSpace">
                                                                    <div class="invalid-feedback"
                                                                        v-if="errorMessages.cpf_cnpj_devedor">
                                                                        <p>{{ errorMessages.cpf_cnpj_devedor }}</p>
                                                                    </div>
                                                                    <div v-if="this.cpf_cnpj_devedor.length == 10"
                                                                        style="display: hidden;">
                                                                        <input id="input-cpf_cnpj_devedor"
                                                                            v-model="cpf_cnpj_devedor"
                                                                            v-mask="'###.###.###-##'"
                                                                            class="form-control" disabled="true"
                                                                            maxlength="18" placeholder="CPF / CNPJ"
                                                                            required="true" type="hidden"
                                                                            v-bind:class="{ 'is-invalid': errorMessages.cpf_cnpj_devedor || errors.cpf_cnpj_devedor }"
                                                                            @input="validateCpfCnpj(cpf_cnpj_devedor)"
                                                                            @blur="validateCpfCnpj($event.target.value)"
                                                                            @keydown.space.prevent="preventSpace">
                                                                        <div class="invalid-feedback"
                                                                            v-if="errorMessages.cpf_cnpj_devedor">
                                                                            <p>{{ errorMessages.cpf_cnpj_devedor }}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div v-if="this.cpf_cnpj_devedor.length == 16"
                                                                        style="display: hidden;">
                                                                        <input id="input-cpf_cnpj_devedor"
                                                                            v-model="cpf_cnpj_devedor"
                                                                            v-mask="'##.###.###/####-##'"
                                                                            class="form-control" disabled="true"
                                                                            maxlength="18" placeholder="CPF / CNPJ"
                                                                            required="true" type="hidden"
                                                                            v-bind:class="{ 'is-invalid': errorMessages.cpf_cnpj_devedor || errors.cpf_cnpj_devedor }"
                                                                            @input="validateCpfCnpj(cpf_cnpj_devedor)"
                                                                            @blur="validateCpfCnpj($event.target.value)"
                                                                            @keydown.space.prevent="preventSpace">
                                                                        <div class="invalid-feedback"
                                                                            v-if="errorMessages.cpf_cnpj_devedor">
                                                                            <p>{{ errorMessages.cpf_cnpj_devedor }}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div v-if="errors.cpf_cnpj_devedor"
                                                                    class="invalid-feedback">
                                                                    <p>{{ errors.cpf_cnpj_devedor }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-6 -->
                                                        <!-- end cpf_cnpj_devedor -->

                                                        <!-- nome_devedor -->
                                                        <div class="col-md-5">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-nome_devedor">Nome</label>
                                                                <input id="input-nome_devedor" v-model="nome_devedor"
                                                                    class="form-control" maxlength="40"
                                                                    placeholder="Nome" required="true" type="text"
                                                                    v-bind:class="{ 'is-invalid': errors.nome_devedor }"
                                                                    @input="validarCampos('type_lyrics', 'nome_devedor')">
                                                                <div v-if="errors.nome_devedor"
                                                                    class="invalid-feedback">
                                                                    <p>{{ errors.nome_devedor }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-6 -->
                                                        <!-- end nome_devedor -->

                                                        <!-- ddd_devedor -->
                                                        <div class="col-md-2">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-ddd_devedor">DDD</label>
                                                                <input id="input-ddd_devedor" v-model="ddd_devedor"
                                                                    class="form-control" maxlength="2" placeholder="DDD"
                                                                    required="true" type="tel"
                                                                    v-bind:class="{ 'is-invalid': errors.ddd_devedor }"
                                                                    @input="validarCampos('type_numbers', 'ddd_devedor')">
                                                                <div v-if="errors.ddd_devedor" class="invalid-feedback">
                                                                    <p>{{ errors.ddd_devedor }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-2 -->
                                                        <!-- end ddd_devedor -->

                                                        <!-- telefone_devedor -->
                                                        <div class="col-md-3">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-telefone_devedor">Telefone</label>
                                                                <div
                                                                    v-if="this.telefone_devedor.length != 9 || this.telefone_devedor.length != 10">
                                                                    <input id="input-telefone_devedor"
                                                                        v-model="telefone_devedor" class="form-control"
                                                                        maxlength="10" placeholder="Telefone"
                                                                        required="true" type="text"
                                                                        v-bind:class="{ 'is-invalid': errors.telefone_devedor }"
                                                                        @input="validarCampos('type_mask', 'telefone_devedor')">
                                                                    <div v-if="this.telefone_devedor.length == 8"
                                                                        style="display: hidden;">
                                                                        <input id="input-telefone_devedor"
                                                                            v-model="telefone_devedor"
                                                                            v-mask="'####-####'" class="form-control"
                                                                            disabled="true" maxlength="10"
                                                                            placeholder="Telefone" required="true"
                                                                            type="hidden"
                                                                            v-bind:class="{ 'is-invalid': errors.telefone_devedor }"
                                                                            @input="validarCampos('type_mask', 'telefone_devedor')">
                                                                    </div>
                                                                    <div v-if="this.telefone_devedor.length == 10"
                                                                        style="display: hidden;">
                                                                        <input id="input-telefone_devedor"
                                                                            v-model="telefone_devedor"
                                                                            v-mask="'#####-####'" class="form-control"
                                                                            disabled="true" maxlength="10"
                                                                            placeholder="Telefone" required="true"
                                                                            type="hidden"
                                                                            v-bind:class="{ 'is-invalid': errors.telefone_devedor }"
                                                                            @input="validarCampos('type_mask', 'telefone_devedor')">
                                                                    </div>
                                                                </div>
                                                                <div v-if="errors.telefone_devedor"
                                                                    class="invalid-feedback">
                                                                    <p>{{ errors.telefone_devedor }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-3 -->
                                                        <!-- end telefone_devedor -->

                                                        <!-- cep_devedor -->
                                                        <div class="col-md-2">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-cep_devedor">CEP</label>
                                                                <input type="text" id="input-cep_devedor"
                                                                    v-bind:class="{ 'is-invalid': errors.cep_devedor }"
                                                                    class="form-control" placeholder="CEP"
                                                                    v-model="cep_devedor" v-mask="'#####-###'"
                                                                    required="true" maxlength="9" @blur="verificarCEP"
                                                                    @input="validarCampos('type_mask', 'cep_devedor')">
                                                                <div class="invalid-feedback" v-if="errors.cep_devedor">
                                                                    <p>{{ errors.cep_devedor }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-3 -->
                                                        <!-- end cep_devedor -->

                                                        <!-- uf_devedor -->
                                                        <div class="col-md-2">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-uf_devedor">UF</label>
                                                                <select id="input-uf_devedor"
                                                                    v-bind:class="{ 'is-invalid': errors.uf_devedor }"
                                                                    class="form-control" placeholder="UF"
                                                                    v-model="uf_devedor" required
                                                                    @change="onChangeUf('devedor')"
                                                                    :disabled="ufBloqueado">
                                                                    <option v-for="item in states">{{ item }}</option>
                                                                </select>
                                                                <div class="invalid-feedback" v-if="errors.uf_devedor">
                                                                    <p>{{ errors.uf_devedor }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-2 -->
                                                        <!-- end uf_devedor -->

                                                        <!-- nome_municipio_devedor -->
                                                        <div class="col-md-3">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-nome_municipio_devedor">Município</label>
                                                                <!--Aplicado um if/else para caso o cep nao seja encontrado abra o campo para digitar
																o municipio  -->
                                                                <template v-if="!cepValido">
                                                                    <!-- Digitar manualmente -->
                                                                    <input type="text" id="input_municipio_dev_manual"
                                                                        v-bind:class="{ 'is-invalid': errors.nome_municipio_devedor }"
                                                                        class="form-control municipio_dev_manual"
                                                                        placeholder="Insira a cidade Manualmente"
                                                                        v-model="municipio_dev_manual" required="true"
                                                                        maxlength="60"
                                                                        @input="validarCampos('type_lyrics', 'municipio_dev_manual')">
                                                                </template>
                                                                <!-- fim -->
                                                                <template v-else>
                                                                    <!-- Vai ser preenchido manualmente pela API -->
                                                                    <v-select id="mySelect" label="municipio"
                                                                        @input="setSelectedDevedor"
                                                                        :options="municipios_dev"
                                                                        :reduce="municipio => municipio.codigo_origem"
                                                                        v-bind:class="{ 'is-invalid': errors.nome_municipio_devedor }"
                                                                        v-model="municipio_dev"
                                                                        :disabled="municipioBloqueado"></v-select>
                                                                </template>
                                                                <!-- Fim -->
                                                                <div class="invalid-feedback d-block"
                                                                    v-if="errors.nome_municipio_devedor">
                                                                    <p>{{ errors.nome_municipio_devedor }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-4 -->
                                                        <!-- end nome_municipio_devedor -->

                                                        <!-- nome_logradouro_devedor -->
                                                        <div class="col-md-5">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-nome_logradouro_devedor">Logradouro</label>
                                                                <input id="input-nome_logradouro_devedor"
                                                                    v-model="nome_logradouro_devedor"
                                                                    class="form-control" maxlength="100"
                                                                    placeholder="Logradouro" required="true" type="text"
                                                                    v-bind:class="{ 'is-invalid': errors.nome_logradouro_devedor }"
                                                                    @input="validarCampos('type_lyrics', 'nome_logradouro_devedor')">
                                                                <div v-if="errors.nome_logradouro_devedor"
                                                                    class="invalid-feedback">
                                                                    <p>{{ errors.nome_logradouro_devedor }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-6 -->
                                                        <!-- end nome_logradouro_devedor -->

                                                        <!-- num_imovel_devedor -->
                                                        <div class="col-md-1">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-num_imovel_devedor">Nº</label>
                                                                <input id="input-num_imovel_devedor"
                                                                    v-model="num_imovel_devedor" class="form-control"
                                                                    maxlength="5" placeholder="Nº" required="true"
                                                                    type="text"
                                                                    v-bind:class="{ 'is-invalid': errors.num_imovel_devedor }">
                                                                <div v-if="errors.num_imovel_devedor"
                                                                    class="invalid-feedback">
                                                                    <p>{{ errors.num_imovel_devedor }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-2 -->
                                                        <!-- end num_imovel_devedor -->

                                                        <!-- bairro_devedor -->
                                                        <div class="col-md-3">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-bairro_devedor">Bairro</label>
                                                                <input id="input-bairro_devedor"
                                                                    v-model="bairro_devedor" class="form-control"
                                                                    maxlength="100" placeholder="Bairro" required="true"
                                                                    type="text"
                                                                    v-bind:class="{ 'is-invalid': errors.bairro_devedor }"
                                                                    @input="validarCampos('type_lyrics', 'bairro_devedor')">
                                                                <div v-if="errors.bairro_devedor"
                                                                    class="invalid-feedback">
                                                                    <p>{{ errors.bairro_devedor }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-3 -->
                                                        <!-- end bairro_devedor -->

                                                        <!-- complemento_imovel_devedor -->
                                                        <div class="col-md-3">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-complemento_imovel_devedor">Complemento</label>
                                                                <input id="input-complemento_imovel_devedor"
                                                                    v-model="complemento_imovel_devedor"
                                                                    class="form-control" maxlength="20"
                                                                    placeholder="Complemento" required="true"
                                                                    type="text"
                                                                    v-bind:class="{ 'is-invalid': errors.complemento_imovel_devedor }">
                                                                <div v-if="errors.complemento_imovel_devedor"
                                                                    class="invalid-feedback">
                                                                    <p>{{ errors.complemento_imovel_devedor }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-4 -->
                                                        <!-- end complemento_imovel_devedor -->

                                                        <!-- codigo SERPRO-->
                                                        <div class="col-md-2">
                                                            <div class="form-group">
                                                                <!-- <div class="form-group" v-if="!cepValido"></div> -->
                                                                <label class="form-control-label"
                                                                    for="input_cod_serpro_devedor">Cód PRODESP</label>
                                                                <input type="text" id="input_cod_serpro_devedor"
                                                                    v-bind:class="{ 'is-invalid': errors.cod_serpro_devedor }"
                                                                    class="form-control" placeholder="Código"
                                                                    v-model="cod_serpro_devedor" required="true"
                                                                    maxlength="4" :disabled="serproBloqueado"
                                                                    @input="validarCampos('type_numbers', 'cod_serpro_devedor')">
                                                                <div class="invalid-feedback" v-if="errors.cep_devedor">
                                                                    <p>{{ errors.cod_serpro_devedor }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-2 -->
                                                        <!-- end codigo SERPRO -->

                                                        <!-- arquivo contrato -->
                                                        <div class="col-md-9">
                                                            <div class="form-group-contract">
                                                                <span class="span-text">Tamanho máximo permitido:
                                                                    5mb</span>
                                                                <br />
                                                                <label class="import-contract custom-label"
                                                                    for="attach">
                                                                    Anexe o PDF do Contrato
                                                                </label>
                                                                <input id="attach" ref="attach" accept="application/pdf"
                                                                    class="form-control" lang="pt" name="attach"
                                                                    type="file" v-on:change="handleFileUpload()"
                                                                    style="display: none;" />
                                                                <span v-if="attach" class="span-name-arq">Arquivo
                                                                    selecionado: {{ attach.name }}</span>
                                                                <span v-else class="span-name-arq">Nenhum arquivo
                                                                    selecionado</span>
                                                            </div>
                                                        </div>
                                                        <!-- end arquivo contrato -->
                                                    </div>
                                                </div>
                                                <a class="btn-previous" @click="changeStep(2)">Anterior</a>
                                                <a class="btn-next btn-enviar"
                                                    @click="newData(); gravaInfoMunicipio()">ENVIAR CONTRATO</a>
                                            </fieldset>
                                            <!-- end comprador -->
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!-- card -->
        </div><!-- col-12 -->
        <vue-loading :active.sync="loading" :can-cancel="false" :is-full-page="fullPage" color="#ec2423"></vue-loading>
    </div><!-- row -->
</template>

<script>
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import Money from 'v-money';
import VMask from 'v-mask';
import { fieldNames } from '../fieldNames/fieldNames';
import { validateCpfCnpj, validateCampo } from '../../../validarCampos/validations';

export default {
    props: ['auth', 'permissions', 'user'],
    data() {
        return {
            userGpEcon: JSON.parse(this.user),
            instituicao: '',
            instituicaoSelecionada: JSON.parse(this.auth),
            titleform: "",
            municipios_lb: [], // municipios liberacao credito
            tipo_operacao: "",
            num_contrato_origem: "",
            num_aditivo_origem: "",
            chassi: "",
            remarcacao: "",
            indicador_novo_usado: "",
            uf_licenciamento: "SP",
            uf_placa: "",
            placa: "",
            renavam: "",
            ano_fabricacao: "",
            ano_modelo: "",
            num_contrato: "",
            correcao_monetaria: "",
            data_contrato: "",
            data_contato: 0,
            qtd_parcelas: "",
            num_gravame: "",
            tipo_gravame: "",
            taxa_juro_mes: "",
            taxa_juro_ano: "",
            taxa_juro_multa: "",
            taxa_mora_dia: "",
            taxa_multa: "",
            taxa_mora: "",
            indicativo_penalidade: "",
            penalidade: "",
            indicativo_comissao: "",
            comissao: "",
            valor_taxa_contrato: "",
            valor_total_financiamento: "",
            valor_iof: "",
            valor_parcela: "",
            data_vecto_primeira_parcela: "",
            data_vecto_ultima_parcela: "",
            data_liberacao_credito: "",
            uf_liberacao_credito: "",
            municipio_liberacao_credito: "",
            indice: "",
            num_grupo_consorcio: "",
            num_cota_consorcio: "",
            num_aditivo: "",
            data_aditivo: "",
            cpf_cnpj_devedor: "",
            nome_devedor: "",
            nome_logradouro_devedor: "",
            num_imovel_devedor: "",
            complemento_imovel_devedor: "",
            bairro_devedor: "",
            nome_municipio_devedor: "",
            uf_devedor: "",
            cep_devedor: "",
            cod_serpro_devedor: "",
            cepValido: false,
            serproBloqueado: true,
            ufBloqueado: false,
            municipioBloqueado: false,
            municipio_dev_manual: "",
            ddd_devedor: "",
            telefone_devedor: "",
            attach: "",
            states: ["", "AC", "AL", "AM", "AP", "BA", "CE", "DF", "ES", "GO", "MA", "MT", "MS", "MG", "PA", "PB", "PR", "PE", "PI", "RJ", "RN", "RO", "RS", "RR", "SC", "SE", "SP", "TO"],
            errors: [],
            step: 1,
            message: "",
            loading: false,
            fullPage: true,
            municipios: [],
            municipio_dev: "",
            municipio_ag: this.getData('cidade'),
            marcas: [],
            tipos: [],
            modelos: [],
            cores: [],
            tipo_id: "",
            marca_id: "",
            modelo_id: "",
            cor_id: "",
            observacao: "",
            valueMaskD3: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                precision: 3,
                masked: false
            },
            money: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                suffix: ' %',
                precision: 3,
                masked: false
            },
            SevenMoney: {
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                suffix: '',
                precision: 2,
                masked: false
            },
            municipios_ag: [],
            municipios_dev: [],
            errorMessages: {},
        }
    },
    mounted() {
        this.onChangeUf('devedor')
        this.loadTipos()
        this.loadCores()
        this.atualizarRemarcacao();
        this.atualizarDadosAditivo();
        this.titleform = 'Preencha as informações abaixo para enviar o Contrato Detran ' + this.uf_licenciamento;
    },
    computed: {

    },
    watch: {
        uf_liberacao_credito: {
            immediate: false,
            handler(uf) {
                this.searchDetranCidades(uf);
            }
        },
        indicador_novo_usado() {
            this.atualizarRemarcacao();
        },
        tipo_operacao() {
            this.atualizarDadosAditivo();
        },
    },

    methods: {
        validarCampos(type, model) {
            this[model] = validateCampo(type, this[model])
        },
        validateCpfCnpj(value) {
            this.errorMessages.cpf_cnpj_devedor = validateCpfCnpj(value);
            this.cpf_cnpj_devedor = this.cpf_cnpj_devedor.replace(/[^\d\/\.\-]/g, '');
        },

        atualizarRemarcacao() {
            if (this.indicador_novo_usado === 'N') {
                this.remarcacao = '2';
                this.placa = '';
                this.renavam = '';
                this.uf_placa = '';
            }
            else {
                this.remarcacao = '';
            }
        },
        atualizarDadosAditivo() {
            if (this.tipo_operacao === '1' || this.tipo_operacao === '2') {
                this.num_aditivo_origem = '';
                this.num_aditivo = '';
                this.data_aditivo = '';
            }
        },
        searchDetranCidades(estado) {

            let _this = this;

            axios.get("/api/portal/detrancidades/search/" + estado).then((res) => {

                _this.municipios_lb = res.data;
                _this.municipio_liberacao_credito = "";

            }).catch((err) => {
                console.warn(err);
            });
        },

        settedMunicipioCredito(item) {
            let _this = this;
            _this.municipio_liberacao_credito = item;
        },

        showCodigoAgente() {

            const detrans = this.getData("detrans");
            let result = "";

            if (detrans) {
                detrans.forEach(function (data, i) {
                    if (data.detran.estado === 'SP') {
                        result = data.codigo_agente;
                    }
                });
            }
            return result;
        },

        getData(key) {
            return JSON.parse(this.auth)[key];
        },

        loadCores() {
            axios.get('/portal/cores').then(response => {
                this.cores = response.data
                this.cor_id = this.cores.id;
            })
        },

        getMarcas(e) {
            if (e == null || e === '' || e === []) {
                this.marcas = [];
                this.modelos = [];
                this.cor_id = '';
                this.marca_id = '';
                this.tipo_id = '';
                this.modelo_id = '';
            }
            let tipo_id = e

            if ("" !== tipo_id) {
                axios.get(`/portal/marcas/${tipo_id}`)
                    .then(response => {
                        this.marcas = response.data;
                    })
                    .catch(error => {

                    });
            } else {
                this.marcas = [];
                this.modelos = [];
                this.cores = []
                this.cor_id = ''
            }
        },

        getModelos(e) {

            if (e == null) {
                this.cores = [];
                this.modelos = [];
                this.marcas = [];
                this.cor_id = '';
                this.marca_id = '';
                this.modelo_id = '';
                this.tipo_id = '';
            }
            let marca_id = e

            if ("" !== marca_id) {

                axios.get(`/portal/modelos/${marca_id}`)
                    .then(response => {
                        this.modelos = response.data;
                        if (this.modelos.id != '') {

                            this.loadCores()
                            this.cor_id = this.cores.id
                        } else {
                            this.cores = []
                            this.cor_id = ''
                        }
                    })
                    .catch(error => {

                    });
            } else {
                this.modelos = [];
                this.cores = []
                this.cor_id = ''
            }
        },

        loadTipos() {
            let _this = this;
            _this.loading = true;
            _this.tipos = [];
            axios.get("/portal/tipos").then((res) => {
                _this.tipos = _this.tipos.concat(res.data)
                _this.loading = false;
            }).catch((err) => {
                _this.loading = false;
            });
        },
        // Função para verificar o cep e realizar os devidos bloqueios de campos e preenchimentos
        async verificarCEP() {
            let cep = this.cep_devedor.replace('-', '');
            try {
                const response = await axios.get(`/portal/consultacep/${cep}`);
                const data = response.data;

                // Se o CEP foi encontrado, preenche os campos de endereço e bloqueia os campo SERPRO,UF e Municipio
                this.cod_serpro_devedor = data.codigo_serpro != "" ? data.codigo_serpro : "";
                this.cepValido = true;
                this.serproBloqueado = data.codigo_serpro != "" ? true : false;
                this.nome_logradouro_devedor = data.logradouro;
                this.bairro_devedor = data.bairro;
                this.municipio_dev = data.localidade;
                this.uf_devedor = data.uf;
                this.ufBloqueado = true;
                this.municipioBloqueado = true;
                this.dadosApi = data.codigo_serpro != "" ? true : false;
                this.onChangeUf('devedor');
            } catch (error) {
                this.cod_serpro_devedor = ''
                this.cepValido = false;
                this.serproBloqueado = false;
                this.nome_logradouro_devedor = '';
                this.bairro_devedor = '';
                this.municipio_dev = '';
                this.uf_devedor = '';
                this.ufBloqueado = false;
                this.municipioBloqueado = false;
            }
        },
        // Fim da Função

        // Função para gravar um cep que nao exista na lista da api, onde sera inserido
        // Codigo SerPro, Cidade e o Estado
        gravaInfoMunicipio() {
            let nomeMunDev = "";

            if (this.municipio_dev_manual != "") { // Se o campo de município foi preenchido manualmente
                nomeMunDev = this.municipio_dev_manual;
            } else { // Caso contrário, encontra o nome do município selecionado no v-select
                let arr = this.municipios_dev;
                for (let i = 0; i < arr.length; i++) {

                    if (arr[i]['municipio'] === this.municipio_dev) {
                        nomeMunDev = arr[i]['municipio'];
                        break;
                    }
                }
            }


            let formData = new FormData();

            formData.append("uf", this.uf_devedor);
            formData.append("municipio", nomeMunDev.toUpperCase());
            formData.append("codigo_origem", this.cod_serpro_devedor);

            axios.post("/portal/consultacep/store", formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then((res) => {
                console.log(res)
            }).catch((err) => {
                console.log(err);
            });
        },
        // Fim
        handleFileUpload() {

            if (this.$refs.attach.files[0].size > 5120 * 5120) {
                this.$swal({
                    title: "Erro",
                    html: "Arquivo precisa ter no máximo 5mb de tamanho!",
                    icon: "error"
                });

                return;
            } else {
                this.attach = this.$refs.attach.files[0];
            }

        },
        newData() {
            let _this = this;
            _this.errors = [];
            _this.message = "";
            _this.loading = true;
            _this.cpf_cnpj_devedor = _this.converteCPFeCnpj(_this.cpf_cnpj_devedor)
            _this.cep_devedor = _this.cep_devedor.replace('-', '')
            _this.telefone_devedor = _this.telefone_devedor.replace('-', '')

            let formData = new FormData();

            formData.append("tipo_operacao", _this.tipo_operacao);
            formData.append("num_contrato_origem", _this.num_contrato_origem);

            if (_this.tipo_operacao == '3' || _this.tipo_operacao == '4') {
                formData.append("num_aditivo_origem", _this.num_aditivo_origem);
            }

            formData.append("chassi", _this.chassi);
            formData.append("remarcacao", _this.remarcacao);
            formData.append("uf_licenciamento", _this.uf_licenciamento);
            formData.append("indicador_novo_usado", _this.indicador_novo_usado);

            if (_this.indicador_novo_usado == "U") {
                formData.append("uf_placa", _this.uf_placa);
                formData.append("placa", _this.placa);
                formData.append("renavam", _this.renavam);
            }

            formData.append("ano_fabricacao", _this.ano_fabricacao);
            formData.append("ano_modelo", _this.ano_modelo);
            formData.append("num_contrato", _this.num_contrato);
            formData.append("data_contrato", _this.data_contrato == "" ? "" : moment(String(_this.data_contrato)).format('YYYY-MM-DD'));
            formData.append("data_contato", _this.data_contato);
            formData.append("qtd_parcelas", _this.qtd_parcelas);
            formData.append("num_gravame", _this.num_gravame);
            formData.append("tipo_gravame", _this.tipo_gravame);
            formData.append("taxa_juro_mes", _this.taxa_juro_mes);
            formData.append("taxa_juro_ano", _this.taxa_juro_ano);
            formData.append("taxa_juro_multa", _this.taxa_juro_multa);

            if (_this.taxa_juro_multa == 'Sim') {
                formData.append("taxa_multa", _this.taxa_multa == '0' ? '' : _this.taxa_multa);
            }

            formData.append("taxa_mora_dia", _this.taxa_mora_dia);

            if (_this.taxa_mora_dia == "Sim") {
                formData.append("taxa_mora", _this.taxa_mora == '0' ? '' : _this.taxa_mora);
            }

            formData.append("indicativo_penalidade", _this.indicativo_penalidade);

            if (_this.indicativo_penalidade == "Sim") {
                formData.append("penalidade", _this.penalidade);
            }

            formData.append("indicativo_comissao", _this.indicativo_comissao);

            if (_this.indicativo_comissao == "Sim") {
                formData.append("comissao", _this.comissao == '0' ? '' : _this.comissao);
            }

            formData.append("valor_taxa_contrato", _this.valor_taxa_contrato);
            formData.append("valor_total_financiamento", _this.valor_total_financiamento);
            formData.append("valor_iof", _this.valor_iof);
            formData.append("valor_parcela", _this.valor_parcela);
            formData.append("data_vecto_primeira_parcela", _this.data_vecto_primeira_parcela == "" ? "" : moment(String(_this.data_vecto_primeira_parcela)).format('YYYY-MM-DD'));
            formData.append("data_vecto_ultima_parcela", _this.data_vecto_ultima_parcela == "" ? "" : moment(String(_this.data_vecto_ultima_parcela)).format('YYYY-MM-DD'));
            formData.append("data_liberacao_credito", _this.data_liberacao_credito == "" ? "" : moment(String(_this.data_liberacao_credito)).format('YYYY-MM-DD'));
            formData.append("uf_liberacao_credito", _this.uf_liberacao_credito);
            formData.append("municipio_liberacao_credito", _this.municipio_liberacao_credito);
            formData.append("indice", _this.indice);
            formData.append("num_grupo_consorcio", _this.num_grupo_consorcio);
            formData.append("num_cota_consorcio", _this.num_cota_consorcio);

            if (_this.tipo_operacao == '3' || _this.tipo_operacao == '4') {
                formData.append("num_aditivo", _this.num_aditivo);
                formData.append("data_aditivo", _this.data_aditivo == "" ? "" : moment(String(_this.data_aditivo)).format('YYYY-MM-DD'));
            }

            formData.append("cpf_cnpj_devedor", _this.cpf_cnpj_devedor);
            formData.append("nome_devedor", _this.nome_devedor);
            formData.append("nome_logradouro_devedor", _this.nome_logradouro_devedor);
            formData.append("num_imovel_devedor", _this.num_imovel_devedor);
            formData.append("complemento_imovel_devedor", _this.complemento_imovel_devedor);
            formData.append("bairro_devedor", _this.bairro_devedor);
            formData.append("nome_municipio_devedor", _this.cod_serpro_devedor);
            formData.append("uf_devedor", _this.uf_devedor);
            formData.append("cep_devedor", _this.cep_devedor);
            formData.append("cod_serpro_devedor", _this.cod_serpro_devedor);
            formData.append("ddd_devedor", _this.ddd_devedor);
            formData.append("telefone_devedor", _this.telefone_devedor);
            formData.append("contrato_arquivo", _this.attach);
            formData.append("tipo_id", _this.tipo_id);
            formData.append("marca_id", _this.marca_id);
            formData.append("modelo_id", _this.modelo_id);
            formData.append("cor_id", _this.cor_id ?? "");
            formData.append("observacao", _this.observacao);

            formData.append("isGroupEcon", this.userGpEcon.is_group_economic)
            if (this.userGpEcon.is_group_economic == 'S') {
                formData.append('insituicaoId', _this.instituicaoSelecionada.id)
            }

            axios.post("/portal/contratodetransp", formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then((res) => {

                _this.loading = false;
                _this.resetForm();

                //show confirm alert
                _this.$swal({
                    title: "Sucesso",
                    text: "Contrato cadastrado com sucesso, em breve será enviado ao Detran!",
                    icon: "success"
                }).then((res) => {
                    // Se o usuário clicar em "Confirmar"
                    if (res.status = 200) {
                        // Redireciona para a página de destino
                        window.location.href = process.env.MIX_URL + 'contratos';
                    }
                });
            }).catch((err) => {
                _this.loading = false;

                if (err.response.status == 422) {

                    this.errors = err.response.data.errors;

                    var errorFields = Object.keys(err.response.data.errors).map((key) => {
                        // Verifica se o mapeamento existe para o nome da variável
                        if (fieldNames.campoObrigatorio.hasOwnProperty(key)) {
                            return fieldNames.campoObrigatorio[key];
                        } else {
                            // Caso o mapeamento não exista, retorna o nome da variável original
                            return key;
                        }
                    }).join(', <br> - ');

                    _this.$swal({
                        title: "Erro",
                        html: `O(s) seguinte(s) campo(s) está(ão) preenchido(s) incorretamente:<br> - ${errorFields}`,
                        icon: "error"
                    });
                } else {

                    if (err.response.status == 400) {

                        _this.$swal({
                            title: "Erro",
                            html: err.response.data.errors,
                            icon: "error"
                        });
                    } else {
                        _this.$swal({
                            title: "Erro",
                            text: "Falha ao cadastrar o contrato. Tente novamente!",
                            icon: "error"
                        });
                    }
                }
            });
        },
        changeStep(option) {
            this.step = option;
        },
        resetForm() {
            this.tipo_operacao = '';
            this.num_contrato_origem = '';
            this.num_aditivo_origem = '';
            this.chassi = '';
            this.remarcacao = '';
            this.indicador_novo_usado = '';
            this.uf_placa = '';
            this.placa = '';
            this.renavam = '';
            this.ano_fabricacao = '';
            this.ano_modelo = '';
            this.num_contrato = '';
            this.data_contrato = '';
            this.data_contato = '';
            this.qtd_parcelas = '';
            this.num_gravame = '';
            this.tipo_gravame = '';
            this.taxa_juro_mes = '';
            this.taxa_juro_ano = '';
            this.taxa_juro_multa = '';
            this.taxa_mora_dia = '';
            this.taxa_multa = '';
            this.taxa_mora = '';
            this.indicativo_penalidade = '';
            this.penalidade = '';
            this.indicativo_comissao = '';
            this.comissao = '';
            this.valor_taxa_contrato = '';
            this.valor_total_financiamento = '';
            this.valor_iof = '';
            this.valor_parcela = '';
            this.data_vecto_primeira_parcela = '';
            this.data_vecto_ultima_parcela = '';
            this.data_liberacao_credito = '';
            this.uf_liberacao_credito = '';
            this.municipio_liberacao_credito = '';
            this.indice = '';
            this.num_grupo_consorcio = '';
            this.num_cota_consorcio = '';
            this.num_aditivo = '';
            this.data_aditivo = '';
            this.cod_serpro_devedor = '';
            this.cpf_cnpj_devedor = '';
            this.nome_devedor = '';
            this.nome_logradouro_devedor = '';
            this.num_imovel_devedor = '';
            this.complemento_imovel_devedor = '';
            this.bairro_devedor = '';
            this.nome_municipio_devedor = '';
            this.uf_devedor = '';
            this.cep_devedor = '';
            this.ddd_devedor = '';
            this.telefone_devedor = '';
            this.marcas = [],
                this.modelos = [],
                this.observacao = ""
        },

        onChangeUf(typeSearch, clickButton = false) {
            let _this = this;
            var estado;

            if (typeSearch == 'agente') {
                estado = _this.uf_agente
            } else {
                estado = _this.uf_devedor
            }

            axios.get("/api/portal/detrancidades/search/" + estado).then((res) => {
                if (typeSearch == 'agente') {
                    _this.municipios_ag = res.data;
                    if (clickButton) {
                        _this.municipio_ag = "";
                    }
                } else {
                    if (!this.ufBloqueado) {
                        this.municipios_dev = res.data;
                        _this.municipios_dev = res.data;
                        _this.municipio_dev = "";
                    }
                }
            }).catch((err) => {

            });
        },

        setSelected(item) {
            let _this = this;
            _this.nome_municipio_agente = item;
            //_this.municipio_ag= _this.municipios_ag.municipio
        },

        setSelectedDevedor(item) {
            let _this = this;
            _this.nome_municipio_devedor = item;
            //_this.municipio_dev = codigo;

        },
        converteCPFeCnpj(e) {
            if (typeof e == 'string') {
                if (e.length == 14) {

                    let cpf = e.replace('.', '')
                    cpf = cpf.replace('.', '')
                    cpf = cpf.replace('-', '')
                    let cpfConvert = cpf
                    return cpfConvert;

                } else {

                    let cpf = e.replace('.', '')
                    cpf = cpf.replace('.', '')
                    cpf = cpf.replace('/', '')
                    cpf = cpf.replace('-', '')
                    let cpfConvert = cpf
                    return cpfConvert;
                }
            }
            return e;
        },
        converteCnpj(e) {
            if (typeof e == 'string') {

                let cpf = e.replace('.', '')
                cpf = cpf.replace('.', '')
                cpf = cpf.replace('/', '')
                cpf = cpf.replace('-', '')
                let cpfConvert = cpf
                return cpfConvert;
            }
            return e;
        },
        disableMulta(v, t) {
            if (v == 'Não' && t == 'taxa_juro_multa') {
                $('.taxa_juro_multa').prop('disabled', true)
                this.taxa_multa = '';
            } else {
                $('.taxa_juro_multa').prop('disabled', false)
            }
        },
        disableMora(v, t) {
            if (v == 'Não' && t == 'taxa_mora_dia') {
                $('.taxa_mora_dia').prop('disabled', true)
                this.taxa_mora = '';
            } else {
                $('.taxa_mora_dia').prop('disabled', false)
            }
        },
        disablePenalidade(v, t) {
            if (v == 'Não' && t == 'indicativo_penalidade') {
                $('.penalidade').prop('disabled', true)
                this.penalidade = '';
            } else {
                $('.penalidade').prop('disabled', false)
            }
        },
        disableComissao(v, t) {
            if (v == 'Não' && t == 'indicativo_comissao') {
                $('.indicativo_comissao').prop('disabled', true)
                this.comissao = '';
            } else {
                $('.indicativo_comissao').prop('disabled', false)
            }
        }
    },
    componentes: {
        vSelect,
        Money,
        VMask,
    }
}
</script>
