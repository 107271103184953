<template>
	<div class="row access-groups">
		<div class="col">

			<!-- top -->
			<div class="row section-top">
				<div class="col-md-12 pad0">
					<p class="section-lead">Preencha os campos abaixo</p>
					<a href="#" @click="saveData()" class="btn btn-primary float-right">SALVAR</a>
					<a href="#" @click="type = 'menu'" class="btn btn-secondary float-right" style="margin-right: 15px;"
						v-if="type != 'menu'">VER MENU</a>
				</div>
			</div>
			<!-- end top -->

			<!-- name -->
			<div class="card">
				<div class="card-body">
					<div class="form-group">
						<label>Nome do Grupo de Acesso</label>
						<input class="form-control" v-model="name" id="name" />
					</div>
				</div>
			</div>
			<!-- end name -->

			<!-- menu -->
			<div class="row menu" v-if="type == 'menu'">
				<div class="col-lg-6">
					<div class="card card-large-icons">
						<div class="card-icon bg-primary text-white">
							<i class="fas fa-cog"></i>
						</div>
						<div class="card-body">
							<h4>Painel Administrativo</h4>
							<p>Permissões relacionadas às funcionalidades do Painel Administrativo</p>
							<a href="#" @click="type = 'admin'" class="card-cta">Ver Permissões <i class="fas fa-chevron-right"></i></a>
						</div>
					</div>
				</div>
				<div class="col-lg-6">
					<div class="card card-large-icons">
						<div class="card-icon bg-primary text-white">
							<i class="fas fa-search"></i>
						</div>
						<div class="card-body">
							<h4>Portal da Instituição</h4>
							<p>Permissões relacionadas às funcionalidades do Portal da Instituição</p>
							<a href="#" @click="type = 'portal'" class="card-cta">Ver Permissões <i
									class="fas fa-chevron-right"></i></a>
						</div>
					</div>
				</div>
			</div>
			<!-- end menu -->

			<!-- itens admin -->
			<div class="row list" v-if="type == 'admin'">

				<!-- list all admin permissions -->
				<div class="col-md-4" v-for="_permission, index in permission.admin" :key="index">
					<div class="card">
						<div class="card-header">
							{{ _permission.title }}
						</div>
						<div class="card-body">
							<div class="item" v-for="_item, itemIndex in _permission.items" :key="itemIndex">
								{{ _item.title }}
								<label class="custom-switch">
									<input type="checkbox" v-model="_item.value" v-on:change="addPermission(_item.name);"
										class="custom-switch-input">
									<span class="custom-switch-indicator"></span>
								</label>
							</div>
						</div>
					</div>
				</div>
				<!-- end list all admin permissions -->

			</div>
			<!-- end itens admin -->

			<!-- itens portal -->
			<div class="row list" v-if="type == 'portal'">

				<!-- list all portal permissions -->
				<div class="col-md-4" v-for="_permission, index in permission.portal" :key="index">
					<div class="card">
						<div class="card-header">
							{{ _permission.title }}
						</div>
						<div class="card-body">
							<div class="item" v-for="_item, itemIndex in _permission.items" :key="itemIndex">
								{{ _item.title }}
								<label class="custom-switch">
									<input type="checkbox" v-model="_item.value" v-on:change="addPermission(_item.name);"
										class="custom-switch-input">
									<span class="custom-switch-indicator"></span>
								</label>
							</div>
						</div>
					</div>
				</div>
				<!-- end list all portal permissions -->

			</div>
			<!-- end itens portal -->

		</div>

		<vue-loading color="#ec2423" :active.sync="loading" :can-cancel="false"></vue-loading>

	</div><!-- row -->
</template>

<script>

import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";

export default {
	props: ['info', 'role'],
	components: {
		VueGoodTable,
	},
	data() {
		return {
			loading: false,
			name: this.getDataRole('name'),
			permission: {
				admin: [
					{
						title: "Bancos",
						items: [
							{
								title: 'Listar',
								name: 'admin-banks-list',
								value: this.getData('admin-banks-list')
							}, {
								title: 'Criar',
								name: 'admin-banks-create',
								value: this.getData('admin-banks-create')
							}, {
								title: 'Editar',
								name: 'admin-banks-edit',
								value: this.getData('admin-banks-edit')
							}, {
								title: 'Deletar',
								name: 'admin-banks-delete',
								value: this.getData('admin-banks-delete')
							}, {
								title: 'Desativar',
								name: 'admin-banks-disable',
								value: this.getData('admin-banks-disable')
							}
						]
					}, {
						title: "Bancos (Detrans)",
						items: [
							{
								title: 'Listar',
								name: 'admin-banks-detrans-list',
								value: this.getData('admin-banks-detrans-list')
							}, {
								title: 'Criar',
								name: 'admin-banks-detrans-create',
								value: this.getData('admin-banks-detrans-create')
							}, {
								title: 'Editar',
								name: 'admin-banks-detrans-edit',
								value: this.getData('admin-banks-detrans-edit')
							}, {
								title: 'Deletar',
								name: 'admin-banks-detrans-delete',
								value: this.getData('admin-banks-detrans-delete')
							}, {
								title: 'Desativar',
								name: 'admin-banks-detrans-disable',
								value: this.getData('admin-banks-detrans-disable')
							}
						]
					}, {
						title: "Bancos (Usuários)",
						items: [
							{
								title: 'Listar',
								name: 'admin-banks-users-list',
								value: this.getData('admin-banks-users-list')
							}, {
								title: 'Criar',
								name: 'admin-banks-users-create',
								value: this.getData('admin-banks-users-create')
							}, {
								title: 'Editar',
								name: 'admin-banks-users-edit',
								value: this.getData('admin-banks-users-edit')
							}, {
								title: 'Deletar',
								name: 'admin-banks-users-delete',
								value: this.getData('admin-banks-users-delete')
							}, {
								title: 'Desativar',
								name: 'admin-banks-users-disable',
								value: this.getData('admin-banks-users-disable')
							}, {
								title: 'Resetar Senha',
								name: 'admin-banks-users-resetpassword',
								value: this.getData('admin-banks-users-resetpassword')
							}
						]
					}, {
						title: "Detrans",
						items: [
							{
								title: 'Listar',
								name: 'admin-detrans-list',
								value: this.getData('admin-detrans-list')
							}, {
								title: 'Criar',
								name: 'admin-detrans-create',
								value: this.getData('admin-detrans-create')
							}, {
								title: 'Editar',
								name: 'admin-detrans-edit',
								value: this.getData('admin-detrans-edit')
							}, {
								title: 'Deletar',
								name: 'admin-detrans-delete',
								value: this.getData('admin-detrans-delete')
							}
						]
					}, {
						title: "Detrans (Cabeçalho)",
						items: [
							{
								title: 'Listar',
								name: 'admin-detrans-header-list',
								value: this.getData('admin-detrans-header-list')
							}, {
								title: 'Criar',
								name: 'admin-detrans-header-create',
								value: this.getData('admin-detrans-header-create')
							}, {
								title: 'Editar',
								name: 'admin-detrans-header-edit',
								value: this.getData('admin-detrans-header-edit')
							}, {
								title: 'Deletar',
								name: 'admin-detrans-header-delete',
								value: this.getData('admin-detrans-header-delete')
							}
						]
					}, {
						title: "Detrans (Retorno)",
						items: [
							{
								title: 'Listar',
								name: 'admin-detrans-return-list',
								value: this.getData('admin-detrans-return-list')
							}, {
								title: 'Criar',
								name: 'admin-detrans-return-create',
								value: this.getData('admin-detrans-return-create')
							}, {
								title: 'Editar',
								name: 'admin-detrans-return-edit',
								value: this.getData('admin-detrans-return-edit')
							}, {
								title: 'Deletar',
								name: 'admin-detrans-return-delete',
								value: this.getData('admin-detrans-return-delete')
							}, {
								title: 'Vincular Contrato Status',
								name: 'admin-detrans-return-link',
								value: this.getData('admin-detrans-return-link')
							}
						]
					}, {
						title: "Detrans (Status)",
						items: [
							{
								title: 'Listar',
								name: 'admin-detrans-status-list',
								value: this.getData('admin-detrans-status-list')
							}, {
								title: 'Criar',
								name: 'admin-detrans-status-create',
								value: this.getData('admin-detrans-status-create')
							}, {
								title: 'Editar',
								name: 'admin-detrans-status-edit',
								value: this.getData('admin-detrans-status-edit')
							}, {
								title: 'Deletar',
								name: 'admin-detrans-status-delete',
								value: this.getData('admin-detrans-status-delete')
							}
						]
					}, {
						title: "Financeiro",
						items: [
							{
								title: 'Faturamento Detran',
								name: 'admin-finance-invoicing-detrans',
								value: this.getData('admin-finance-invoicing-detrans')
							}, {
								title: 'Contratos Enviados',
								name: 'admin-finance-contracts',
								value: this.getData('admin-finance-contracts')
							}, {
								title: 'Contas a Pagar',
								name: 'admin-finance-accounts',
								value: this.getData('admin-finance-accounts')
							}
						]
					}, {
						title: "Grupos de Acesso",
						items: [
							{
								title: 'Listar',
								name: 'admin-permissions-list',
								value: this.getData('admin-permissions-list')
							}, {
								title: 'Criar',
								name: 'admin-permissions-create',
								value: this.getData('admin-permissions-create')
							}, {
								title: 'Editar',
								name: 'admin-permissions-edit',
								value: this.getData('admin-permissions-edit')
							}, {
								title: 'Deletar',
								name: 'admin-permissions-delete',
								value: this.getData('admin-permissions-delete')
							}
						]
					}, {
						title: "Usuários",
						items: [
							{
								title: 'Listar',
								name: 'admin-users-list',
								value: this.getData('admin-users-list')
							}, {
								title: 'Criar',
								name: 'admin-users-create',
								value: this.getData('admin-users-create')
							}, {
								title: 'Editar',
								name: 'admin-users-edit',
								value: this.getData('admin-users-edit')
							}, {
								title: 'Deletar',
								name: 'admin-users-delete',
								value: this.getData('admin-users-delete')
							}, {
								title: 'Desativar',
								name: 'admin-users-disable',
								value: this.getData('admin-users-disable')
							}, {
								title: 'Resetar Senha',
								name: 'admin-users-resetpassword',
								value: this.getData('admin-users-resetpassword')
							}
						]
					}, {
						title: "Logs",
						items: [
							{
								title: 'Log Acesso',
								name: 'admin-logs-access',
								value: this.getData('admin-logs-access')
							}, {
								title: 'Log API',
								name: 'admin-logs-api',
								value: this.getData('admin-logs-api')
							}, {
								title: 'Log Atividade',
								name: 'admin-logs-activity',
								value: this.getData('admin-logs-activity')
							}, {
								title: 'Log Erro',
								name: 'admin-logs-error',
								value: this.getData('admin-logs-error')
							}, {
								title: 'Log de Falhas',
								name: 'admin-logs-failedjobs',
								value: this.getData('admin-logs-failedjobs')
							}
						]
					}
				],
				portal: [
					{
						title: "Contratos",
						items: [
							{
								title: 'Criar',
								name: 'portal-contracts-create',
								value: this.getData('portal-contracts-create')
							}, {
								title: 'Editar',
								name: 'portal-contracts-edit',
								value: this.getData('portal-contracts-edit')
							}, {
								title: 'Visualizar',
								name: 'portal-contracts-view',
								value: this.getData('portal-contracts-view')
							}, {
								title: 'Reenviar',
								name: 'portal-contracts-resend',
								value: this.getData('portal-contracts-resend')
							}
						]
					}, {
						title: "Financeiro",
						items: [
							{
								title: 'Faturamento',
								name: 'portal-finance-invoicing',
								value: this.getData('portal-finance-invoicing')
							}, {
								title: 'Fechamento',
								name: 'portal-finance-closure',
								value: this.getData('portal-finance-closure')
							}
						]
					}, {
						title: "Usuários",
						items: [
							{
								title: 'Listar (Dados da Instituição, Agente e Usuários)',
								name: 'portal-users-list',
								value: this.getData('portal-users-list')
							}, {
								title: 'Criar',
								name: 'portal-users-create',
								value: this.getData('portal-users-create')
							}, {
								title: 'Editar',
								name: 'portal-users-edit',
								value: this.getData('portal-users-edit')
							}, {
								title: 'Deletar',
								name: 'portal-users-delete',
								value: this.getData('portal-users-delete')
							}, {
								title: 'Desativar',
								name: 'portal-users-disable',
								value: this.getData('portal-users-disable')
							}, {
								title: 'Resetar Senha',
								name: 'portal-users-resetpassword',
								value: this.getData('portal-users-resetpassword')
							}
						]
					}, {
						title: "Navegação",
						items: [
							{
								title: 'Dashboard',
								name: 'portal-users-dashboard',
								value: this.getData('portal-users-dashboard')
							}, {
								title: 'Contratos',
								name: 'portal-users-contratos',
								value: this.getData('portal-users-contratos')
							}, {
								title: 'Faturamento',
								name: 'portal-users-financeiro',
								value: this.getData('portal-users-financeiro')
							}, {
								title: 'Logs - Acesso e Auditoria',
								name: 'portal-users-logs',
								value: this.getData('portal-users-logs')
							}, 
						]
					},
					{
						title: "Operações do Contrato",
						items: [
							{
								title: 'Inclusao de Contrato',
								name: 'portal-users-contracts-created',
								value: this.getData('portal-users-contracts-created')
							}, {
								title: 'Update de Contrato',
								name: 'portal-users-contracts-update',
								value: this.getData('portal-users-contracts-update')
							}, {
								title: 'Inclusao de Aditivo de Contrato',
								name: 'portal-users-contracts-create_additive',
								value: this.getData('portal-users-contracts-create_additive')
							}, {
								title: 'Update de Aditivo de Contrato',
								name: 'portal-users-contracts-update_additive',
								value: this.getData('portal-users-contracts-update_additive')
							}
						]
					}
				]
			},
			permissions: [],
			permissionsRemoved: [],
			type: 'menu',
		}
	},
	mounted() {
	},
	methods: {
		getData(key) {
			var temp = JSON.parse(this.info);
			var check = temp.includes(key);
			if (check) {
				return true;
			} else {
				return false;
			}
		},
		getDataRole(key) {
			return JSON.parse(this.role)[key];
		},
		addPermission(permission) {

			let permissaoAtual = JSON.parse(this.info)

			if (!permissaoAtual.includes(permission)) {
				this.permissions.push(permission);
			} else {
				this.permissionsRemoved.push(permission);
			}
		},
		saveData() {
			let _this = this;
			_this.loading = true;

			let formData = new FormData();
			formData.append('name', _this.name);

			if (this.permissions[0] !== "" || this.permissions[0] !== undefined) {
				this.permissions.forEach((perm, i) => {
					formData.append(`permissions[${i}]`, perm);
				});
			} else {
				this.permissions[0] = false;
			}

			this.permissionsRemoved.forEach((removedItem, i) => {
				formData.append(`removed[${i}]`, removedItem);
			});
			formData.append('_method', 'PATCH');

			axios.post('/admin/permissions/' + _this.getDataRole('id'), formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then((res) => {

				_this.loading = false;
				_this.$swal({
					title: 'Sucesso',
					text: 'Grupo de Acesso atualizado com sucesso!',
					icon: 'success'
				}).then(() => {
					location.reload();
				});

			}).catch((err) => {

				_this.loading = false;
				_this.$swal({
					title: 'Erro',
					text: 'Falha ao atualizar o Grupo de Acesso. Tente novamente!',
					icon: 'error'
				});

			});
		}
	}
}
</script>
