<template>
	<div class="row">
		<div class="col-12">
			<div class="card pt-4 pb-4">
				<div id="grad1" class="wizard-form">
					<div class="row justify-content-center mt-0">
						<div class="col-12">
							<div class="card mt-3 mb-3 bxShadow">
								<h5 class="text-center"><strong>Preencha as informações abaixo para enviar o
										Contrato</strong></h5>
								<div class="row">
									<div class="col-md-12 mx-0">
										<form id="msform">

											<!-- progressbar -->
											<ul id="progressbar" class="text-center">
												<a @click="changeStep(1)">
													<li :class="step >= 1 ? 'active' : ''" id="geral"><strong>Gerais e
															Veículo</strong></li>
												</a>
												<a @click="changeStep(2)">
													<li :class="step >= 2 ? 'active' : ''" id="financeiro"><strong>Dados
															Financeiros</strong></li>
												</a>
												<a v-show="false" @click="changeStep(4)">
													<li :class="step >= 4 ? 'active' : ''" id="agente">
														<strong>Agente</strong>
													</li>
												</a>
												<a @click="changeStep(3)">
													<li :class="step >= 3 ? 'active' : ''" id="comprador">
														<strong>Comprador</strong>
													</li>
												</a>
											</ul>
											<!-- end progressbar -->

											<!-- gerais e veículos -->
											<fieldset v-if="step === 1">
												<div class="form-card">
													<div class="row form-distance">
														<!-- tipo_operacao -->
														<div class="col-md-4">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-tipo_operacao">Tipo de Operação</label>
																<select id="input-tipo_operacao" v-model="tipo_operacao"
																	class="form-control" placeholder="Tipo de Operação"
																	v-bind:class="{ 'is-invalid': errors.tipo_operacao }">
																	<option value="1">1 - Inclusão do registro de contrato
																	</option>
																	<option value="2">2 - Alteração do registro de contrato
																	</option>
																	<option value="3">3 - Inclusão do aditivo de contrato
																	</option>
																	<option value="4">4 - Alteração do aditivo de contrato
																	</option>
																</select>
																<div v-if="errors.tipo_operacao" class="invalid-feedback">
																	<p>{{ errors.tipo_operacao }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end tipo_operacao -->

														<!-- sequencia_contrato -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-sequencia_contrato">Sequencial
																	Contrato</label>
																<input id="input-sequencia_contrato"
																	v-model="sequencia_contrato" class="form-control"
																	maxlength="8" placeholder="Sequencial Contrato" required
																	type="tel"
																	v-bind:class="{ 'is-invalid': errors.sequencia_contrato }">
																<div v-if="errors.sequencia_contrato"
																	class="invalid-feedback">
																	<p>{{ errors.sequencia_contrato }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-2 -->
														<!-- end sequencia_contrato -->

														<!-- num_contrato_origem -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-num_contrato_origem">Nº Contrato
																	Origem</label>
																<input id="input-num_contrato_origem"
																	v-model="num_contrato_origem" class="form-control"
																	maxlength="14" placeholder="Nº Contrato Origem" required
																	type="text"
																	v-bind:class="{ 'is-invalid': errors.num_contrato_origem }">
																<div v-if="errors.num_contrato_origem"
																	class="invalid-feedback">
																	<p>{{ errors.num_contrato_origem }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end num_contrato_origem -->

														<!-- chassi -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-chassi">Chassi</label>
																<input id="input-chassi" v-model="chassi"
																	class="form-control" maxlength="21" placeholder="Chassi"
																	required type="text"
																	v-bind:class="{ 'is-invalid': errors.chassi }">
																<div v-if="errors.chassi" class="invalid-feedback">
																	<p>{{ errors.chassi }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-4 -->
														<!-- end chassi -->

														<!-- remarcacao -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-remarcacao">Remarcação</label>
																<select type="text" id="input-remarcacao"
																	v-bind:class="{ 'is-invalid': errors.remarcacao }"
																	class="form-control" placeholder="Remarcação"
																	v-model="remarcacao" required="true">
																	<option value="1">Remarcado</option>
																	<option value="2">Não Remarcado</option>
																</select>
																<div class="invalid-feedback" v-if="errors.remarcacao">
																	<p>{{ errors.remarcacao }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-2 -->
														<!-- end remarcacao -->

														<!--condicao do veiculo-->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-indicador_novo_usado">Condição do
																	Veículo</label>
																<select id="input-indicador_novo_usado" class="form-control"
																	v-model="indicador_novo_usado">
																	<option value="N">Novo</option>
																	<option value="U">Seminovo</option>
																</select>
															</div>
														</div>
														<!--end condicao do veiculo-->

														<!-- uf_licenciamento -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-uf_licenciamento">UF Licenciamento</label>
																<select id="input-uf_licenciamento"
																	v-model="uf_licenciamento" class="form-control"
																	placeholder="UF Placa" required
																	v-bind:class="{ 'is-invalid': errors.uf_licenciamento }">
																	<option v-for="item in states">{{ item }}</option>
																</select>
																<div v-if="errors.uf_licenciamento"
																	class="invalid-feedback">
																	<p>{{ errors.uf_licenciamento }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-6 -->
														<!-- end uf_licenciamento -->

														<!-- uf_placa -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label" for="input-uf_placa">UF
																	Placa</label>
																<select id="input-uf_placa" v-model="uf_placa"
																	class="form-control" placeholder="UF Placa" required
																	v-bind:class="{ 'is-invalid': errors.uf_placa }">
																	<option v-for="item in states">{{ item }}</option>
																</select>
																<div v-if="errors.uf_placa" class="invalid-feedback">
																	<p>{{ errors.uf_placa }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-6 -->
														<!-- end uf_placa -->

														<!-- placa -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-placa">Placa</label>
																<input id="input-placa" v-model="placa" class="form-control"
																	maxlength="7" placeholder="Placa" required type="text"
																	v-bind:class="{ 'is-invalid': errors.placa }">
																<div v-if="errors.placa" class="invalid-feedback">
																	<p>{{ errors.placa }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-4 -->
														<!-- end placa -->

														<!-- renavam -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-renavam">Renavam</label>
																<input type="tel" id="input-renavam"
																	v-bind:class="{ 'is-invalid': errors.renavam }"
																	class="form-control" placeholder="Renavam"
																	v-model="renavam" required="true" maxlength="11">
																<div class="invalid-feedback" v-if="errors.renavam">
																	<p>{{ errors.renavam }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-2 -->
														<!-- end renavam -->

														<!-- especie -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-especie">Especie</label>
																<input type="text" id="input-especie" v-model="especie"
																	class="form-control" maxlength="25"
																	placeholder="Especie" required
																	v-bind:class="{ 'is-invalid': errors.especie }">
																<div v-if="errors.especie" class="invalid-feedback">
																	<p>{{ errors.especie }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-2 -->
														<!-- end ano_fabricacao -->

														<!-- ano_fabricacao -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-ano_fabricacao">Ano Fabricação</label>
																<input id="input-ano_fabricacao" v-model="ano_fabricacao"
																	class="form-control" maxlength="4"
																	placeholder="Ano Fabricação" required type="tel"
																	v-bind:class="{ 'is-invalid': errors.ano_fabricacao }">
																<div v-if="errors.ano_fabricacao" class="invalid-feedback">
																	<p>{{ errors.ano_fabricacao }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-2 -->
														<!-- end ano_fabricacao -->

														<!-- ano_modelo -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label" for="input-ano_modelo">Ano
																	Modelo</label>
																<input id="input-ano_modelo" v-model="ano_modelo"
																	class="form-control" maxlength="4"
																	placeholder="Ano Modelo" required type="text"
																	v-bind:class="{ 'is-invalid': errors.ano_modelo }">
																<div v-if="errors.ano_modelo" class="invalid-feedback">
																	<p>{{ errors.ano_modelo }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-2 -->
														<!-- end ano_modelo -->

														<!-- Combustivel -->
														<div class="col-md-6">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-combustivel_id">Combustível</label>
																<v-select v-model="combustivel_id" :options="combustiveis"
																	:reduce="nome => nome.id" label="nome"></v-select>
																<div v-if="errors.combustivel_id" class="invalid-feedback">
																	<p>{{ errors.combustivel_id }}</p>
																</div>
															</div><!-- form group -->
														</div>
														<!-- Combustivel -->
													</div>

													<div class="row py-0 px-4 pb-0">
														<!-- Tipo de veiculos -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-tipo_veiculo_id">Tipo de Veículo</label>
																<v-select v-model="tipo_veiculo_id" :options="tipos"
																	:reduce="nome => nome.id" label="nome"
																	@change="getMarcas" @input="getMarcas"></v-select>
																<div v-if="errors.tipo_veiculo_id" class="invalid-feedback">
																	<p>{{ errors.tipo_veiculo_id }}</p>
																</div>
															</div><!-- form group -->
														</div>
														<!-- Tipo de veiculos -->

														<!-- marcas -->
														<div class="col-md-4">
															<div class="form-group">
																<label class="form-control-label" for="input-marcas">Marcas
																	do veículo</label>
																<v-select v-model="marca_id" :options="marcas"
																	:reduce="nome => nome.id" label="nome"
																	@change="getModelos" @input="getModelos"></v-select>
																<div v-if="errors.marca" class="invalid-feedback">
																	<p>{{ errors.marca }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-2 -->
														<!-- end marcas -->

														<!-- Modelos -->
														<div class="col-md-4">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-modelos">Modelos</label>
																<v-select v-model="modelo_id" :options="modelos"
																	:reduce="nome => nome.id" label="nome"></v-select>
																<div v-if="errors.modelos" class="invalid-feedback">
																	<p>{{ errors.modelos }}</p>
																</div>
															</div><!-- form group modelos-->
														</div><!-- col-md-2 modelos-->

														<!-- cores -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-modelos">Cores</label>
																<v-select v-model="cor_id" :options="cores"
																	:reduce="nome => nome.id" label="nome"></v-select>
																<div v-if="errors.cores" class="invalid-feedback">
																	<p>{{ errors.cores }}</p>
																</div>
															</div><!-- form group cores-->
														</div><!-- col-md-2 cores-->

														<div class="col-md-12">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-modelos">Observações</label>
																<textarea id="input-observacao" v-model="observacao"
																	class="form-control" maxlength="500"
																	placeholder="Observações" style="height: 90px"
																	v-bind:class="{ 'is-invalid': errors.observacao }">
		                                                  	</textarea>
															</div>
														</div>
													</div>
												</div>
												<div class="btn-next" @click="changeStep(2)">Próximo</div>
											</fieldset>
											<!-- end gerais e veículos -->

											<!--- dados financeiros -->
											<fieldset v-if="step === 2">
												<div class="form-card">
													<div class="row form-distance">
														<!-- num_contrato -->
														<div class="col-md-6">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-num_contrato">Nº Contrato</label>
																<input id="input-num_contrato" v-model="num_contrato"
																	class="form-control" maxlength="20"
																	placeholder="Nº Contrato" required type="text"
																	v-bind:class="{ 'is-invalid': errors.num_contrato }">
																<div v-if="errors.num_contrato" class="invalid-feedback">
																	<p>{{ errors.num_contrato }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-7 -->
														<!-- end num_contrato -->

														<!-- qtd_parcelas -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-qtd_parcelas">Qtd Parcelas</label>
																<input id="input-qtd_parcelas" v-model="qtd_parcelas"
																	class="form-control" maxlength="3"
																	placeholder="Qtd Parcelas" required type="tel"
																	v-bind:class="{ 'is-invalid': errors.qtd_parcelas }">
																<div v-if="errors.qtd_parcelas" class="invalid-feedback">
																	<p>{{ errors.qtd_parcelas }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-2 -->
														<!-- end qtd_parcelas -->

														<!-- num_gravame -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label" for="input-num_gravame">Nº
																	Gravame</label>
																<input id="input-num_gravame" v-model="num_gravame"
																	class="form-control" maxlength="8"
																	placeholder="Nº Gravame" required type="tel"
																	v-bind:class="{ 'is-invalid': errors.num_gravame }">
																<div v-if="errors.num_gravame" class="invalid-feedback">
																	<p>{{ errors.num_gravame }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end num_gravame -->

														<!-- tipo_gravame -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-tipo_gravame">Tipo Gravame</label>
																<select type="text" id="input-tipo_gravame"
																	v-bind:class="{ 'is-invalid': errors.tipo_gravame }"
																	class="form-control" placeholder="Tipo Gravame"
																	v-model="tipo_gravame" required="true">
																	<option value="1">Arrendamento Mercantil</option>
																	<option value="2">Reserva de Domínio</option>
																	<option value="3">Alienação Fidunciária</option>
																	<option value="9">Penhor</option>
																</select>
																<div class="invalid-feedback" v-if="errors.tipo_gravame">
																	<p>{{ errors.tipo_gravame }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end tipo_gravame -->

														<!-- taxa_juro_mes -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-taxa_juro_mes">Taxa de Juros Mês</label>
																<money v-model="taxa_juro_mes" class="form-control"
																	maxlength="9" v-bind="money"></money>
																<div v-if="errors.taxa_juro_mes" class="invalid-feedback">
																	<p>{{ errors.taxa_juro_mes }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end taxa_juro_mes -->

														<!-- taxa_juro_ano -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-taxa_juro_ano">Taxa de Juros Ano</label>
																<money v-model="taxa_juro_ano" class="form-control"
																	maxlength="9" v-bind="money"></money>
																<div v-if="errors.taxa_juro_ano" class="invalid-feedback">
																	<p>{{ errors.taxa_juro_ano }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end taxa_juro_ano -->

														<!-- taxa_multa -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-taxa_multa">Taxa Multa</label>
																<money v-model="taxa_multa"
																	class="form-control taxa_juro_multa" maxlength="9"
																	v-bind="money"></money>
																<div v-if="errors.taxa_multa" class="invalid-feedback">
																	<p>{{ errors.taxa_multa }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end taxa_multa -->


														<!-- taxa_mora -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label" for="input-taxa_mora">Taxa
																	Mora</label>
																<money v-model="taxa_mora"
																	class="form-control taxa_mora_dia" maxlength="13"
																	v-bind="percentmoneyptwo"></money>
																<div v-if="errors.taxa_mora" class="invalid-feedback">
																	<p>{{ errors.taxa_mora }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end taxa_mora -->

														<!-- valor_taxa_contrato_aditivo -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-valor_taxa_contrato_aditivo">Valor Taxa
																	Contrato Aditivo</label>
																<money v-model="valor_taxa_contrato_aditivo"
																	class="form-control taxa_contrato_aditivo"
																	maxlength="13" v-bind="percentmoneyptwo"></money>
																<div v-if="errors.valor_taxa_contrato_aditivo"
																	class="invalid-feedback">
																	<p>{{ errors.valor_taxa_contrato_aditivo }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end valor_taxa_contrato_aditivo -->


														<!-- penalidade -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-penalidade">Penalidade</label>
																<input id="input-penalidade" v-model="penalidade"
																	class="form-control penalidade" maxlength="50"
																	placeholder="Penalidade" required type="text"
																	v-bind:class="{ 'is-invalid': errors.penalidade }">
																<div v-if="errors.penalidade" class="invalid-feedback">
																	<p>{{ errors.penalidade }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-6 -->
														<!-- end penalidade -->

														<!-- comissao -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-comissao">Comissão</label>
																<money v-model="comissao"
																	class="form-control indicativo_comissao" maxlength="13"
																	v-bind="SevenMoney"></money>
																<div v-if="errors.comissao" class="invalid-feedback">
																	<p>{{ errors.comissao }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end comissao -->

														<!-- valor_taxa_contrato -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-valor_taxa_contrato">Valor Taxa
																	Contrato</label>
																<money v-model="valor_taxa_contrato" class="form-control"
																	maxlength="14" v-bind="SevenMoney"></money>
																<div v-if="errors.valor_taxa_contrato"
																	class="invalid-feedback">
																	<p>{{ errors.valor_taxa_contrato }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end valor_taxa_contrato -->

														<!-- valor_total_financiamento -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-valor_total_financiamento">Valor Total do
																	Financiamento</label>
																<money v-model="valor_total_financiamento"
																	class="form-control" maxlength="15" required
																	v-bind="SevenMoney"></money>
																<div v-if="errors.valor_total_financiamento"
																	class="invalid-feedback">
																	<p>{{ errors.valor_total_financiamento }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end valor_total_financiamento -->

														<!-- valor_iof -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-valor_iof">Valor IOF</label>
																<money v-model="valor_iof" class="form-control"
																	maxlength="14" v-bind="SevenMoney">
																</money>
																<div v-if="errors.valor_iof" class="invalid-feedback">
																	<p>{{ errors.valor_iof }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end valor_iof -->

														<!-- valor_parcela -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-valor_parcela">Valor da Parcela</label>
																<money v-model="valor_parcela" class="form-control"
																	maxlength="14" v-bind="SevenMoney">
																</money>
																<div v-if="errors.valor_parcela" class="invalid-feedback">
																	<p>{{ errors.valor_parcela }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end valor_parcela -->

														<!-- codigo_local_pagamento -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-codigo_local_pagamento">Código Local de
																	Pagamento</label>
																<input type="text" id="input-codigo_local_pagamento"
																	v-model="codigo_local_pagamento" class="form-control"
																	required
																	v-bind:class="{ 'is-invalid': errors.codigo_local_pagamento }">
																<div v-if="errors.codigo_local_pagamento"
																	class="invalid-feedback">
																	<p>{{ errors.codigo_local_pagamento }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end data_pagamento -->


														<!-- data_pagamento -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-data_pagamento">Data de Pagamento</label>
																<input type="date" id="input-data_pagamento"
																	v-model="data_pagamento" class="form-control" required
																	v-bind:class="{ 'is-invalid': errors.data_pagamento }">
																<div v-if="errors.data_pagamento" class="invalid-feedback">
																	<p>{{ errors.data_pagamento }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end data_pagamento -->

														<!-- data_vecto_primeira_parcela -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-data_vecto_primeira_parcela">Data de
																	Vencimeto da Primeira Parcela</label>
																<input id="input-data_vecto_primeira_parcela"
																	v-model="data_vecto_primeira_parcela"
																	class="form-control"
																	placeholder="data_vecto_primeira_parcela" required
																	type="date"
																	v-bind:class="{ 'is-invalid': errors.data_vecto_primeira_parcela }">
																<div v-if="errors.data_vecto_primeira_parcela"
																	class="invalid-feedback">
																	<p>{{ errors.data_vecto_primeira_parcela }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end data_vecto_primeira_parcela -->



														<!-- data_liberacao_credito -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-data_liberacao_credito">Data de Liberação do
																	Crédito</label>
																<input id="input-data_liberacao_credito"
																	v-model="data_liberacao_credito" class="form-control"
																	placeholder="data_liberacao_credito" required
																	type="date"
																	v-bind:class="{ 'is-invalid': errors.data_liberacao_credito }">
																<div v-if="errors.data_liberacao_credito"
																	class="invalid-feedback">
																	<p>{{ errors.data_liberacao_credito }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-4 -->
														<!-- end data_liberacao_credito -->

														<!-- indice -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-indice">Indíce</label>
																<input type="text" id="input-indice" v-model="indice"
																	class="form-control" maxlength="10"
																	placeholder="Índice. Ex: SELIC, IGPM, IPCA, entre outros."
																	required v-bind:class="{ 'is-invalid': errors.indice }">
																<div v-if="errors.indice" class="invalid-feedback">
																	<p>{{ errors.indice }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-2 -->
														<!-- end indice -->

														<!-- num_aditivo -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label" for="input-num_aditivo">Nº
																	Aditivo</label>
																<input id="input-num_aditivo" v-model="num_aditivo"
																	class="form-control" maxlength="20"
																	placeholder="Nº Aditivo" required type="text"
																	v-bind:class="{ 'is-invalid': errors.num_aditivo }">
																<div v-if="errors.num_aditivo" class="invalid-feedback">
																	<p>{{ errors.num_aditivo }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end num_aditivo -->

														<!-- situacao_aditivo -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-situacao_aditivo">Situação Aditivo</label>
																<input type="text" id="input-situacao_aditivo"
																	v-model="situacao_aditivo" class="form-control"
																	maxlength="100" placeholder="Situação Aditivo" required
																	v-bind:class="{ 'is-invalid': errors.situacao_aditivo }">
																<div v-if="errors.situacao_aditivo"
																	class="invalid-feedback">
																	<p>{{ errors.situacao_aditivo }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end situacao_aditivo -->

														<!-- data_aditivo -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-data_aditivo">Data Aditivo</label>
																<input id="input-data_aditivo" v-model="data_aditivo"
																	class="form-control" placeholder="data_aditivo" required
																	type="date"
																	v-bind:class="{ 'is-invalid': errors.data_aditivo }">
																<div v-if="errors.data_aditivo" class="invalid-feedback">
																	<p>{{ errors.data_aditivo }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end data_aditivo -->
													</div>
												</div>
												<div class="btn-previous" @click="changeStep(1)">Anterior</div>
												<div class="btn-next" @click="changeStep(3)">Próximo</div>
											</fieldset>
											<!-- end dados financeiros -->

											<!-- agente -->
											<fieldset v-if="step === 4">
												<div class="form-card">
													<div class="row form-distance">
														<!-- nome_agente -->
														<div class="col-md-6">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-nome_agente">Nome do Agente</label>
																<input id="input-nome_agente" v-model="nome_agente"
																	class="form-control" maxlength="40"
																	placeholder="Nome do Agente" required type="text"
																	v-bind:class="{ 'is-invalid': errors.nome_agente }">
																<div v-if="errors.nome_agente" class="invalid-feedback">
																	<p>{{ errors.nome_agente }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-6 -->
														<!-- end nome_agente -->

														<!-- cnpj_agente -->
														<div class="col-md-4">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-cnpj_agente">CNPJ do Agente</label>
																<input id="input-cnpj_agente" v-model="cnpj_agente"
																	v-mask="'##.###.###/####-##'" class="form-control"
																	maxlength="18" placeholder="CNPJ do Agente" required
																	type="text"
																	v-bind:class="{ 'is-invalid': errors.cnpj_agente }">
																<div v-if="errors.cnpj_agente" class="invalid-feedback">
																	<p>{{ errors.cnpj_agente }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-6 -->
														<!-- end cnpj_agente -->

														<!--codigo agente-->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-codigo_agente">Código do Agente</label>
																<input id="input-codigo_agente" v-model="codigo_agente"
																	class="form-control disabled" disabled maxlength="10"
																	placeholder="Código do Agente"
																	style="cursor: not-allowed;" type="text"
																	v-bind:class="{ 'is-invalid': errors.codigo_agente }">
																<div v-if="errors.codigo_agente" class="invalid-feedback">
																	<p>{{ errors.codigo_agente }}</p>
																</div>
															</div>
														</div>
														<!--end codigio agente-->
														<!-- nome_logradouro_agente -->
														<div class="col-md-6">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-nome_logradouro_agente">Logradouro</label>
																<input id="input-nome_logradouro_agente"
																	v-model="nome_logradouro_agente" class="form-control"
																	maxlength="30" placeholder="Logradouro" required="true"
																	type="text"
																	v-bind:class="{ 'is-invalid': errors.nome_logradouro_agente }">
																<div v-if="errors.nome_logradouro_agente"
																	class="invalid-feedback">
																	<p>{{ errors.nome_logradouro_agente }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-6 -->
														<!-- end nome_logradouro_agente -->

														<!-- num_imovel_agente -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-num_imovel_agente">Nº</label>
																<input id="input-num_imovel_agente"
																	v-model="num_imovel_agente" class="form-control"
																	maxlength="5" placeholder="Nº" required="true"
																	type="text"
																	v-bind:class="{ 'is-invalid': errors.num_imovel_agente }">
																<div v-if="errors.num_imovel_agente"
																	class="invalid-feedback">
																	<p>{{ errors.num_imovel_agente }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-2 -->
														<!-- end num_imovel_agente -->

														<!-- complemento_imovel_agente -->
														<div class="col-md-4">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-complemento_imovel_agente">Complemento</label>
																<input id="input-complemento_imovel_agente"
																	v-model="complemento_imovel_agente" class="form-control"
																	maxlength="20" placeholder="Complemento" required="true"
																	type="text"
																	v-bind:class="{ 'is-invalid': errors.complemento_imovel_agente }">
																<div v-if="errors.complemento_imovel_agente"
																	class="invalid-feedback">
																	<p>{{ errors.complemento_imovel_agente }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-4 -->
														<!-- end complemento_imovel_agente -->

														<!-- bairro_agente -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-bairro_agente">Bairro</label>
																<input id="input-bairro_agente" v-model="bairro_agente"
																	class="form-control" maxlength="20" placeholder="Bairro"
																	required="true" type="text"
																	v-bind:class="{ 'is-invalid': errors.bairro_agente }">
																<div v-if="errors.bairro_agente" class="invalid-feedback">
																	<p>{{ errors.bairro_agente }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end bairro_agente -->

														<!-- uf_agente -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-uf_agente">UF</label>
																<select id="input-uf_agente" v-model="uf_agente"
																	class="form-control" placeholder="UF" required
																	v-bind:class="{ 'is-invalid': errors.uf_agente }"
																	@change="onChangeUf('agente', true)">
																	<option v-for="item in states">{{ item }}</option>
																</select>
																<div v-if="errors.uf_agente" class="invalid-feedback">
																	<p>{{ errors.uf_agente }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-2 -->
														<!-- end uf_agente -->

														<!-- nome_municipio_agente -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-nome_municipio_agente">Município</label>
																<v-select v-model="municipio_ag" :options="municipios_ag"
																	:reduce="municipio => municipio.codigo_origem"
																	label="municipio"
																	v-bind:class="{ 'is-invalid': errors.nome_municipio_agente }"
																	@input="setSelected"></v-select>
																<div v-if="errors.nome_municipio_agente"
																	class="invalid-feedback d-block">
																	<p>{{ errors.nome_municipio_agente }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-4 -->
														<!-- end nome_municipio_agente -->

														<!-- cep_agente -->
														<div class="col-md-4">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-cep_agente">CEP</label>
																<input id="input-cep_agente" v-model="cep_agente"
																	v-mask="'#####-###'" class="form-control" maxlength="9"
																	placeholder="CEP" required="true" type="text"
																	v-bind:class="{ 'is-invalid': errors.cep_agente }">
																<div v-if="errors.cep_agente" class="invalid-feedback">
																	<p>{{ errors.cep_agente }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end cep_agente -->

														<!-- ddd_agente -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-ddd_agente">DDD</label>
																<input id="input-ddd_agente" v-model="ddd_agente"
																	class="form-control" maxlength="4" placeholder="DDD"
																	required type="tel"
																	v-bind:class="{ 'is-invalid': errors.ddd_agente }">
																<div v-if="errors.ddd_agente" class="invalid-feedback">
																	<p>{{ errors.ddd_agente }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-2 -->
														<!-- end ddd_agente -->

														<!-- telefone_agente -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-telefone_agente">Telefone</label>
																<div
																	v-if="telefone_agente.length != 9 || telefone_agente.length != 10">
																	<input id="input-telefone_agente"
																		v-model="telefone_agente" v-mask="'#####-####'"
																		class="form-control" maxlength="10"
																		placeholder="Telefone" required type="text"
																		v-bind:class="{ 'is-invalid': errors.telefone_agente }">
																	<div v-if="telefone_agente.length == 8">
																		<input id="input-telefone_agente"
																			v-model="telefone_agente" v-mask="'####-####'"
																			class="form-control" disabled maxlength="10"
																			placeholder="Telefone" required type="hidden"
																			v-bind:class="{ 'is-invalid': errors.telefone_agente }">
																	</div>
																	<div v-if="telefone_agente.length == 10">
																		<input id="input-telefone_agente"
																			v-model="telefone_agente" v-mask="'#####-####'"
																			class="form-control" disabled maxlength="10"
																			placeholder="Telefone" required type="hidden"
																			v-bind:class="{ 'is-invalid': errors.telefone_agente }">
																	</div>
																</div>
																<div v-if="errors.telefone_agente" class="invalid-feedback">
																	<p>{{ errors.telefone_agente }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end telefone_agente -->
													</div> <!--row p-4-->
												</div>
												<div class="btn-previous" @click="changeStep(2)">Anterior</div>
												<div class="btn-next" @click="changeStep(4)">Próximo</div>
											</fieldset>
											<!-- end agente -->

											<!-- comprador -->
											<fieldset v-if="step === 3">
												<div class="form-card">
													<div class="row form-distance">
														<!-- cpf_cnpj_devedor -->
														<div class="col-md-6">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-cpf_cnpj_devedor">CPF / CNPJ</label>
																<div
																	v-if="cpf_cnpj_devedor.length != 14 || cpf_cnpj_devedor.length != 18">
																	<input id="input-cpf_cnpj_devedor"
																		v-model="cpf_cnpj_devedor" class="form-control"
																		maxlength="18" placeholder="CPF / CNPJ" required
																		type="text"
																		v-bind:class="{ 'is-invalid': errors.cpf_cnpj_devedor }">
																	<div v-if="cpf_cnpj_devedor.length == 11">
																		<input id="input-cpf_cnpj_devedor"
																			v-model="cpf_cnpj_devedor"
																			v-mask="'###.###.###-##'" class="form-control"
																			disabled maxlength="18" placeholder="CPF / CNPJ"
																			required type="hidden"
																			v-bind:class="{ 'is-invalid': errors.cpf_cnpj_devedor }">
																	</div>
																	<div v-if="cpf_cnpj_devedor.length == 15">
																		<input id="input-cpf_cnpj_devedor"
																			v-model="cpf_cnpj_devedor"
																			v-mask="'##.###.###/####-##'"
																			class="form-control" disabled maxlength="18"
																			placeholder="CPF / CNPJ" required type="hidden"
																			v-bind:class="{ 'is-invalid': errors.cpf_cnpj_devedor }">
																	</div>
																	<div v-if="errors.cpf_cnpj_devedor"
																		class="invalid-feedback">
																		<p>{{ errors.cpf_cnpj_devedor }}</p>
																	</div>
																</div>

															</div><!-- form group -->
														</div><!-- col-md-6 -->
														<!-- end cpf_cnpj_devedor -->

														<!-- nome_devedor -->
														<div class="col-md-6">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-nome_devedor">Nome</label>
																<input id="input-nome_devedor" v-model="nome_devedor"
																	class="form-control" maxlength="40" placeholder="Nome"
																	required type="text"
																	v-bind:class="{ 'is-invalid': errors.nome_devedor }">
																<div v-if="errors.nome_devedor" class="invalid-feedback">
																	<p>{{ errors.nome_devedor }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-6 -->
														<!-- end nome_devedor -->

														<!-- nome_logradouro_devedor -->
														<div class="col-md-6">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-nome_logradouro_devedor">Logradouro</label>
																<input id="input-nome_logradouro_devedor"
																	v-model="nome_logradouro_devedor" class="form-control"
																	maxlength="30" placeholder="Logradouro" required
																	type="text"
																	v-bind:class="{ 'is-invalid': errors.nome_logradouro_devedor }">
																<div v-if="errors.nome_logradouro_devedor"
																	class="invalid-feedback">
																	<p>{{ errors.nome_logradouro_devedor }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-6 -->
														<!-- end nome_logradouro_devedor -->

														<!-- num_imovel_devedor -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-num_imovel_devedor">Nº</label>
																<input id="input-num_imovel_devedor"
																	v-model="num_imovel_devedor" class="form-control"
																	maxlength="5" placeholder="Nº" required type="text"
																	v-bind:class="{ 'is-invalid': errors.num_imovel_devedor }">
																<div v-if="errors.num_imovel_devedor"
																	class="invalid-feedback">
																	<p>{{ errors.num_imovel_devedor }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-2 -->
														<!-- end num_imovel_devedor -->

														<!-- complemento_imovel_devedor -->
														<div class="col-md-4">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-complemento_imovel_devedor">Complemento</label>
																<input id="input-complemento_imovel_devedor"
																	v-model="complemento_imovel_devedor"
																	class="form-control" maxlength="20"
																	placeholder="Complemento" required type="text"
																	v-bind:class="{ 'is-invalid': errors.complemento_imovel_devedor }">
																<div v-if="errors.complemento_imovel_devedor"
																	class="invalid-feedback">
																	<p>{{ errors.complemento_imovel_devedor }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-4 -->
														<!-- end complemento_imovel_devedor -->

														<!-- bairro_devedor -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-bairro_devedor">Bairro</label>
																<input id="input-bairro_devedor" v-model="bairro_devedor"
																	class="form-control" maxlength="20" placeholder="Bairro"
																	required type="text"
																	v-bind:class="{ 'is-invalid': errors.bairro_devedor }">
																<div v-if="errors.bairro_devedor" class="invalid-feedback">
																	<p>{{ errors.bairro_devedor }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end bairro_devedor -->

														<!-- uf_devedor -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-uf_devedor">UF</label>
																<select id="input-uf_devedor" v-model="uf_devedor"
																	class="form-control" placeholder="UF" required="true"
																	v-bind:class="{ 'is-invalid': errors.uf_devedor }"
																	@change="onChangeUf('devedor')">
																	<option v-for="item in states">{{ item }}</option>
																</select>
																<div v-if="errors.uf_devedor" class="invalid-feedback">
																	<p>{{ errors.uf_devedor }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-2 -->
														<!-- end uf_devedor -->

														<!-- nome_municipio_devedor -->
														<div class="col-md-4">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-nome_municipio_devedor">Município</label>
																<v-select id="mySelect" v-model="municipio_dev"
																	:options="municipios_dev"
																	:reduce="municipio => municipio.codigo_origem"
																	label="municipio"
																	v-bind:class="{ 'is-invalid': errors.nome_municipio_devedor }"
																	@input="setSelectedDevedor"></v-select>
																<div v-if="errors.nome_municipio_devedor"
																	class="invalid-feedback d-block">
																	<p>{{ errors.nome_municipio_devedor }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-4 -->
														<!-- end nome_municipio_devedor -->

														<!-- codigo_local_devedor-->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-codigo_local_devedor">Código Local</label>
																<input id="input-codigo_local_devedor"
																	v-model="codigo_local_devedor" class="form-control"
																	maxlength="60" placeholder="Código Local" required
																	type="text"
																	v-bind:class="{ 'is-invalid': errors.codigo_local_devedor }">
																<div v-if="errors.codigo_local_devedor"
																	class="invalid-feedback">
																	<p>{{ errors.codigo_local_devedor }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end codigo_local_devedor -->

														<!-- cep_devedor -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-cep_devedor">CEP</label>
																<input id="input-cep_devedor" v-model="cep_devedor"
																	v-mask="'#####-###'" class="form-control" maxlength="9"
																	placeholder="CEP" required type="text"
																	v-bind:class="{ 'is-invalid': errors.cep_devedor }">
																<div v-if="errors.cep_devedor" class="invalid-feedback">
																	<p>{{ errors.cep_devedor }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end cep_devedor -->

														<!-- ddd_devedor -->
														<div class="col-md-1">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-ddd_devedor">DDD</label>
																<input id="input-ddd_devedor" v-model="ddd_devedor"
																	class="form-control" maxlength="4" placeholder="DDD"
																	required type="tel"
																	v-bind:class="{ 'is-invalid': errors.ddd_devedor }">
																<div v-if="errors.ddd_devedor" class="invalid-feedback">
																	<p>{{ errors.ddd_devedor }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-2 -->
														<!-- end ddd_devedor -->

														<!-- telefone_devedor -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-telefone_devedor">Telefone</label>
																<div
																	v-if="telefone_devedor.length !== 9 || telefone_devedor.length !== 10">
																	<input id="input-telefone_devedor"
																		v-model="telefone_devedor" class="form-control"
																		maxlength="10" placeholder="Telefone" required
																		type="text"
																		v-bind:class="{ 'is-invalid': errors.telefone_devedor }">
																	<div v-if="telefone_devedor.length === 8">
																		<input id="input-telefone_devedor"
																			v-model="telefone_devedor" v-mask="'####-####'"
																			class="form-control" disabled maxlength="10"
																			placeholder="Telefone" required type="hidden"
																			v-bind:class="{ 'is-invalid': errors.telefone_devedor }">
																	</div>
																	<div v-if="telefone_devedor.length === 10">
																		<input id="input-telefone_devedor"
																			v-model="telefone_devedor" v-mask="'#####-####'"
																			class="form-control" disabled maxlength="10"
																			placeholder="Telefone" required type="hidden"
																			v-bind:class="{ 'is-invalid': errors.telefone_devedor }">
																	</div>
																</div>
																<div v-if="errors.telefone_devedor"
																	class="invalid-feedback">
																	<p>{{ errors.telefone_devedor }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end telefone_devedor -->

														<!-- email_devedor -->
														<div class="col-md-6">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-ddd_devedor">E-mail</label>
																<input id="input-email_devedor" v-model="email_devedor"
																	class="form-control" maxlength="100"
																	placeholder="E-mail" required type="email"
																	v-bind:class="{ 'is-invalid': errors.email_devedor }">
																<div v-if="errors.email_devedor" class="invalid-feedback">
																	<p>{{ errors.email_devedor }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-2 -->
														<!-- end email_devedor -->

														<!-- arquivo contrato -->
														<div class="col-md-9">
															<div class="form-group-contract">
																<span class="span-text">Tamanho máximo permitido: 5mb</span>
																<br />
																<label class="import-contract custom-label" for="attach">
																	Anexe o PDF do Contrato
																</label>
																<input id="attach" ref="attach" accept="application/pdf"
																	class="form-control" lang="pt" name="attach" type="file"
																	v-on:change="handleFileUpload()"
																	style="display: none;" />
																<span v-if="attach" class="span-name-arq">Arquivo
																	selecionado: {{ attach.name }}</span>
																<span v-else class="span-name-arq">Nenhum arquivo
																	selecionado</span>
															</div>
														</div>
														<!-- end arquivo contrato -->
													</div>
												</div>
												<a class="btn-previous" @click="changeStep(2)">Anterior</a>
												<a class="btn-next btn-enviar" @click="newData">ENVIAR CONTRATO</a>
											</fieldset>
											<!-- end comprador -->
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div><!-- card -->
		</div><!-- col-12 -->
		<vue-loading :active.sync="loading" :can-cancel="false" :is-full-page="fullPage" color="#ec2423"></vue-loading>
	</div><!-- row -->
</template>

<script>
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import Money from 'v-money';
import VMask from 'v-mask';
import { fieldNames } from '../fieldNames/fieldNames';

export default {
	props: ['auth'],
	data() {
		return {
			especie: "",
			tipo_operacao: "",
			sequencia_contrato: "",
			num_contrato_origem: "",
			//num_aditivo_origem: "",
			uf_licenciamento: "ES",
			chassi: "",
			remarcacao: "",
			indicador_novo_usado: "",
			uf_placa: "",
			placa: "",
			renavam: "",
			ano_fabricacao: "",
			ano_modelo: "",
			nome_agente: this.getData('nome'),
			cnpj_agente: this.getData('cnpj'),
			num_contrato: "",
			correcao_monetaria: "",
			data_contato: 0,
			qtd_parcelas: "",
			num_gravame: "",
			tipo_gravame: "",
			valor_taxa_contrato_aditivo: "",
			taxa_juro_mes: "",
			taxa_juro_ano: "",
			taxa_multa: "",
			taxa_mora: "",
			penalidade: "",
			comissao: "",
			valor_taxa_contrato: "",
			valor_total_financiamento: "",
			valor_iof: "",
			valor_parcela: "",
			data_pagamento: "",
			codigo_local_pagamento: "",
			data_vecto_primeira_parcela: "",
			data_liberacao_credito: "",
			indice: "",
			situacao_aditivo: "",
			num_aditivo: "",
			data_aditivo: "",
			cpf_cnpj_devedor: "",
			nome_devedor: "",
			nome_logradouro_devedor: "",
			codigo_agente: this.showCodigoAgente(),
			nome_logradouro_agente: this.getData('endereco'),
			num_imovel_agente: this.getData('numero'),
			complemento_imovel_agente: this.getData('complemento'),
			bairro_agente: this.getData('bairro'),
			nome_municipio_agente: this.getData('cidade'),
			uf_agente: this.getData('estado'),
			cep_agente: this.getData('cep'),
			ddd_agente: this.getData('ddd'),
			telefone_agente: this.getData('telefone'),
			num_imovel_devedor: "",
			complemento_imovel_devedor: "",
			bairro_devedor: "",
			uf_devedor: "",
			nome_municipio_devedor: "",
			cep_devedor: "",
			codigo_local_devedor: "",
			ddd_devedor: "",
			telefone_devedor: "",
			email_devedor: "",
			attach: "",
			states: ["AC", "AL", "AM", "AP", "BA", "CE", "DF", "ES", "GO", "MA", "MT", "MS", "MG", "PA", "PB", "PR", "PE", "PI", "RJ", "RN", "RO", "RS", "RR", "SC", "SE", "SP", "TO"],
			errors: [],
			step: 1,
			message: "",
			loading: false,
			fullPage: true,
			municipios: [],
			municipio_dev: "",
			marcas: [],
			tipos: [],
			modelos: [],
			cores: [],
			combustiveis: [],
			municipios_ag: [],
			municipio_ag: this.getData('cidade'),
			combustivel_id: "",
			tipo_veiculo_id: "",
			marca_id: "",
			modelo_id: "",
			cor_id: "",
			observacao: "",
			percentmoneyptwo: {
				decimal: ',',
				thousands: '.',
				prefix: '',
				suffix: ' %',
				precision: 2,
				masked: false
			},
			money: {
				decimal: ',',
				thousands: '.',
				prefix: '',
				suffix: ' %',
				precision: 3,
				masked: false
			},
			SevenMoney: {
				decimal: ',',
				thousands: '.',
				prefix: 'R$ ',
				suffix: '',
				precision: 2,
				masked: false
			},

			municipios_dev: [],
		}
	},
	mounted() {
		this.onChangeUf('devedor');
		this.onChangeUf('agente');
		this.loadCombustiveis();
		this.loadTipos();
		this.loadCores();
	},

	methods: {
		getData(key) {
			return JSON.parse(this.auth)[key];
		},

		showCodigoAgente() {

			const detrans = this.getData("detrans");
			let result = "";

			if (detrans) {
				detrans.forEach(function (data, i) {
					if (data.detran.estado === 'ES') {
						result = data.codigo_agente;
					}
				});
			}
			return result;
		},

		loadCores() {
			axios.get('/portal/cores').then(response => {
				this.cores = response.data
				this.cor_id = this.cores.id;
			})
		},

		getMarcas(e) {
			if (e == null || e === '' || e === []) {
				this.marcas = [];
				this.modelos = [];
				this.cor_id = '';
				this.marca_id = '';
				this.tipo_veiculo_id = '';
				this.modelo_id = '';
			}
			let tipo_veiculo_id = e

			if ("" !== tipo_veiculo_id) {
				axios.get(`/portal/marcas/${tipo_veiculo_id}`)
					.then(response => {
						this.marcas = response.data;
					});
			} else {
				this.marcas = [];
				this.modelos = [];
				this.cores = []
				this.cor_id = ''
			}
		},

		getModelos(e) {

			if (e == null) {
				this.cores = [];
				this.modelos = [];
				this.marcas = [];
				this.cor_id = '';
				this.marca_id = '';
				this.modelo_id = '';
				this.tipo_veiculo_id = '';
			}
			let marca_id = e

			if ("" !== marca_id) {

				axios.get(`/portal/modelos/${marca_id}`)
					.then(response => {
						this.modelos = response.data;
						if (this.modelos.id !== '') {
							this.loadCores()
							this.cor_id = this.cores.id
						} else {
							this.cores = []
							this.cor_id = ''
						}
					});
			} else {
				this.modelos = [];
				this.cores = []
				this.cor_id = ''
			}
		},

		loadTipos() {
			let _this = this;
			_this.loading = true;
			_this.tipos = [];
			axios.get("/portal/tipos").then((res) => {
				_this.tipos = _this.tipos.concat(res.data)
				_this.loading = false;
			}).catch((err) => {
				_this.loading = false;
			});
		},

		loadCombustiveis() {
			let _this = this;
			_this.loading = true;
			_this.combustiveis = [];
			axios.get("/portal/combustiveis").then((res) => {
				_this.combustiveis = _this.combustiveis.concat(res.data)
				_this.loading = false;
			}).catch((err) => {
				_this.loading = false;
			});
		},

		handleFileUpload() {

			if (this.$refs.attach.files[0].size > 5120 * 5120) {
				this.$swal({
					title: "Erro",
					html: "Arquivo precisa ter no máximo 5mb de tamanho!",
					icon: "error"
				});
				//return
			} else {
				this.attach = this.$refs.attach.files[0];
			}

		},
		newData() {
			let _this = this;
			_this.errors = [];
			_this.message = "";
			_this.loading = true;
			_this.penalidade = _this.penalidade;
			_this.cpf_cnpj_devedor = _this.converteCPFeCnpj(_this.cpf_cnpj_devedor)
			_this.cnpj_agente = _this.converteCnpj(_this.cnpj_agente)
			_this.cep_agente = _this.converteCep(_this.cep_agente)
			_this.cep_devedor = _this.converteCep(_this.cep_devedor)
			_this.telefone_devedor = _this.converteTelefone(_this.telefone_devedor)
			_this.telefone_agente = _this.converteTelefone(_this.telefone_agente)

			let formData = new FormData();

			// let dataInfo = {
			formData.append("tipo_operacao", _this.tipo_operacao);
			formData.append("especie", _this.especie);
			formData.append("sequencia_contrato", _this.sequencia_contrato);
			formData.append("num_contrato_origem", _this.num_contrato_origem);
			//formData.append("num_aditivo_origem", _this.num_aditivo_origem);
			formData.append("chassi", _this.chassi);
			formData.append("remarcacao", _this.remarcacao);
			formData.append("indicador_novo_usado", _this.indicador_novo_usado);
			formData.append("uf_licenciamento", _this.uf_licenciamento);
			formData.append("uf_placa", _this.uf_placa);
			formData.append("placa", _this.placa);
			formData.append("renavam", _this.renavam);
			formData.append("ano_fabricacao", _this.ano_fabricacao);
			formData.append("ano_modelo", _this.ano_modelo);
			formData.append("nome_agente", _this.nome_agente);
			formData.append("cnpj_agente", _this.cnpj_agente);
			formData.append("num_contrato", _this.num_contrato);
			formData.append("data_contato", _this.data_contato);
			formData.append("qtd_parcelas", _this.qtd_parcelas);
			formData.append("num_gravame", _this.num_gravame);
			formData.append("tipo_gravame", _this.tipo_gravame);
			formData.append("valor_taxa_contrato_aditivo", _this.valor_taxa_contrato_aditivo);

			formData.append("taxa_juro_mes", _this.taxa_juro_mes);
			formData.append("taxa_juro_ano", _this.taxa_juro_ano);
			formData.append("taxa_multa", _this.taxa_multa);
			formData.append("taxa_mora", _this.taxa_mora);
			formData.append("penalidade", _this.penalidade);
			formData.append("comissao", _this.comissao);
			formData.append("valor_taxa_contrato", _this.valor_taxa_contrato);
			formData.append("valor_total_financiamento", _this.valor_total_financiamento);
			formData.append("valor_iof", _this.valor_iof);
			formData.append("valor_parcela", _this.valor_parcela);
			formData.append("data_pagamento", _this.data_pagamento === "" ? "" : moment(String(_this.data_pagamento)).format('YYYY-MM-DD'));
			formData.append("codigo_local_pagamento", _this.codigo_local_pagamento);
			formData.append("cep_agente", _this.cep_agente);
			formData.append("ddd_agente", _this.ddd_agente);
			formData.append("telefone_agente", _this.telefone_agente);
			formData.append("data_vecto_primeira_parcela", _this.data_vecto_primeira_parcela === "" ? "" : moment(String(_this.data_vecto_primeira_parcela)).format('YYYY-MM-DD'));
			formData.append("data_liberacao_credito", _this.data_liberacao_credito === "" ? "" : moment(String(_this.data_liberacao_credito)).format('YYYY-MM-DD'));
			formData.append("indice", _this.indice);
			formData.append("num_aditivo", _this.num_aditivo == null ? '' : _this.num_aditivo);
			formData.append("situacao_aditivo", _this.situacao_aditivo);
			formData.append("nome_logradouro_agente", _this.nome_logradouro_agente);
			formData.append("complemento_imovel_agente", _this.complemento_imovel_agente);
			formData.append("num_imovel_agente", _this.num_imovel_agente);
			formData.append("bairro_agente", _this.bairro_agente);
			formData.append("uf_agente", _this.uf_agente);

			formData.append("data_aditivo", _this.data_aditivo === "" ? "" : moment(String(_this.data_aditivo)).format('YYYY-MM-DD'));
			formData.append("nome_municipio_agente", _this.nome_municipio_agente);
			formData.append("cpf_cnpj_devedor", _this.cpf_cnpj_devedor);
			formData.append("nome_devedor", _this.nome_devedor);
			formData.append("nome_logradouro_devedor", _this.nome_logradouro_devedor);
			formData.append("num_imovel_devedor", _this.num_imovel_devedor);
			formData.append("complemento_imovel_devedor", _this.complemento_imovel_devedor);
			formData.append("bairro_devedor", _this.bairro_devedor);
			formData.append("nome_municipio_devedor", _this.nome_municipio_devedor);
			formData.append("uf_devedor", _this.uf_devedor);
			formData.append("cep_devedor", _this.cep_devedor);
			formData.append("ddd_devedor", _this.ddd_devedor);
			formData.append("email_devedor", _this.email_devedor);
			formData.append("codigo_local_devedor", _this.codigo_local_devedor);
			formData.append("dataAditivo", _this.data_aditivo);
			formData.append("dataVecPrimeiraParcela", _this.data_vecto_primeira_parcela);
			formData.append("dataLibCredito", _this.data_liberacao_credito);
			formData.append("telefone_devedor", _this.telefone_devedor);
			formData.append("contrato_arquivo", _this.attach);
			formData.append("combustivel_id", _this.combustivel_id);
			formData.append("tipo_veiculo_id", _this.tipo_veiculo_id);
			formData.append("marca_id", _this.marca_id);
			formData.append("modelo_id", _this.modelo_id);
			formData.append("cor_id", _this.cor_id);
			formData.append("observacao", _this.observacao);
			// };

			axios.post("/portal/contratodetranes", formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then((res) => {

				_this.loading = false;
				_this.resetForm();

				//show confirm alert
				_this.$swal({
					title: "Sucesso",
					text: "Contrato cadastrado com sucesso, em breve será enviado ao Detran!",
					icon: "success"
				}).then((res) => {
					// Se o usuário clicar em "Confirmar"
					if (res.status = 200) {
						// Redireciona para a página de destino
						window.location.href = process.env.MIX_URL + 'contratos';
					}
				});
			}).catch((err) => {

				var errors = '';

				_this.loading = false;

				if (err.response.status == 422) {

					this.errors = err.response.data.errors;

					var errorFields = Object.keys(err.response.data.errors).map((key) => {
						// Verifica se o mapeamento existe para o nome da variável
						if (fieldNames.campoObrigatorio.hasOwnProperty(key)) {
							return fieldNames.campoObrigatorio[key];
						} else {
							// Caso o mapeamento não exista, retorna o nome da variável original
							return key;
						}
					}).join(', <br> - ');

					_this.$swal({
						title: "Erro",
						html: `O(s) seguinte(s) campo(s) está(ão) preenchido(s) incorretamente:<br> - ${errorFields}`,
						icon: "error"
					});
				} else {

					//show error alert
					_this.$swal({
						title: "Erro",
						text: "Falha ao cadastrar o contrato. Tente novamente!",
						icon: "error"
					});
				}

			});
		},
		changeStep(option) {
			this.step = option;
		},
		resetForm() {
			this.tipo_operacao = '';
			this.sequencia_contrato = '';
			this.especie = '';
			this.num_contrato_origem = '';
			//this.num_aditivo_origem = '';
			this.chassi = '';
			this.remarcacao = '';
			this.indicador_novo_usado = '';
			this.uf_placa = '';
			this.placa = '';
			this.renavam = '';
			this.ano_fabricacao = '';
			this.ano_modelo = '';
			this.nome_agente = '';
			this.cnpj_agente = '';
			this.num_contrato = '';
			this.data_contato = '';
			this.qtd_parcelas = '';
			this.num_gravame = '';
			this.tipo_gravame = '';
			this.valor_taxa_contrato_aditivo = "";
			this.taxa_juro_mes = '';
			this.taxa_juro_ano = '';
			this.taxa_multa = '';
			this.taxa_mora = '';
			this.penalidade = '';
			this.comissao = '';
			this.valor_taxa_contrato = '';
			this.valor_total_financiamento = '';
			this.valor_iof = '';
			this.num_imovel_agente = '';
			this.valor_parcela = '';
			this.data_vecto_primeira_parcela = '';
			this.nome_logradouro_agente = '';
			this.data_pagamento = '';
			this.complemento_imovel_agente = '';
			this.bairro_agente = '';
			this.uf_agente = '';
			this.cep_agente = '';
			this.ddd_agente = '';
			this.telefone_agente = '';
			this.codigo_local_pagamento = "";
			this.data_liberacao_credito = '';
			this.indice = '';
			this.num_aditivo = '';
			this.situacao_aditivo = "";
			this.data_aditivo = '';
			this.nome_municipio_agente = '';
			this.cpf_cnpj_devedor = '';
			this.nome_devedor = '';
			this.nome_logradouro_devedor = '';
			this.email_devedor = "";
			this.num_imovel_devedor = '';
			this.complemento_imovel_devedor = '';
			this.bairro_devedor = '';
			this.nome_municipio_devedor = '';
			this.uf_devedor = '';
			this.cep_devedor = '';
			this.codigo_local_devedor = "";
			this.ddd_devedor = '';
			this.telefone_devedor = '';
			this.marcas = [];
			this.modelos = [];
			this.observacao = ""
		},

		onChangeUf(typeSearch, clickButton = false) {
			let _this = this;
			let estado;

			if (typeSearch === 'agente') {
				estado = _this.uf_agente
			} else {
				estado = _this.uf_devedor
			}

			if (estado) {
				axios.get("/api/portal/detrancidades/search/" + estado).then((res) => {
					if (typeSearch === 'agente') {
						_this.municipios_ag = res.data;
						if (clickButton === true) {
							_this.municipio_ag = "";
						}
					} else {
						_this.municipios_dev = res.data;
						_this.municipio_dev = "";
					}
				}).catch((err) => {
					// console.log(err);
					// _this.$swal({
					// 	title: "Erro",
					// 	text: "Falha ao pesquisar o estado: "+estado+" tente novamente!",
					// 	icon: "error"
					// });
				});
			}
		},

		setSelected(item) {
			let _this = this;
			_this.nome_municipio_agente = item;
			//_this.municipio_ag= _this.municipios_ag.municipio
		},

		setSelectedDevedor(item) {
			let _this = this;
			_this.nome_municipio_devedor = item;
			//_this.municipio_dev = codigo;

		},
		converteCPFeCnpj(e) {
			if (typeof e == 'string') {
				if (e.length === 14) {

					let cpf = e.replace('.', '')
					cpf = cpf.replace('.', '')
					cpf = cpf.replace('-', '')
					return cpf;

				} else {

					let cpf = e.replace('.', '')
					cpf = cpf.replace('.', '')
					cpf = cpf.replace('/', '')
					cpf = cpf.replace('-', '')
					return cpf;
				}
			}
			return e;
		},
		converteCnpj(e) {
			if (typeof e == 'string') {

				let cpf = e.replace('.', '')
				cpf = cpf.replace('.', '')
				cpf = cpf.replace('/', '')
				cpf = cpf.replace('-', '')
				return cpf;
			}
			return e;
		},
		converteCep(e) {
			if (typeof e == 'string') {
				if (e.length === 9 || e.length === 8) {
					return e.replace('-', '');
				}
			}
			return e;
		},
		converteTelefone(e) {
			if (typeof e == 'string') {
				if (e.length === 10 || e.length === 9) {
					let tel = e.replace('-', '')
					return parseInt(tel);
				}
			}
			return e;
		},
		disableMulta(v, t) {
			if (v === 'NAO' && t === 'taxa_juro_multa') {
				$('.taxa_juro_multa').prop('disabled', true)
				this.taxa_multa = '';
			} else {
				$('.taxa_juro_multa').prop('disabled', false)
			}
		},

		disableMora(v, t) {
			if (v === 'NAO' && t === 'taxa_mora_dia') {
				$('.taxa_mora_dia').prop('disabled', true)
				this.taxa_mora = '';
			} else {
				$('.taxa_mora_dia').prop('disabled', false)
			}
		},
		disablePenalidade(v, t) {

			if (v === 'NAO' && t === 'indicativo_penalidade') {
				$('.penalidade').prop('disabled', true)
				this.penalidade = '';
			} else {
				$('.penalidade').prop('disabled', false)
			}
		},
		disableComissao(v, t) {

			if (v === 'NAO' && t === 'indicativo_comissao') {
				$('.indicativo_comissao').prop('disabled', true)
				this.comissao = '';
			} else {
				$('.indicativo_comissao').prop('disabled', false)
			}
		}
	},
	componentes: {
		vSelect,
		Money,
		VMask,
	}
}
</script>
