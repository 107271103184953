<template>
  <section class="section dashboard">
    <div class="row">
      <div class="col-lg-3 col-md-6 col-sm-6 col-12">
        <div class="card card-statistic-1">
          <div class="card-icon bg-warning">
            <i class="fas fa-money-check-alt"></i>
          </div>
          <div class="card-wrap">
            <div class="card-header">
              <h4>Instituições Financeiras</h4>
            </div>
            <div class="card-body">
              <div class="spinner-border spinner-border spinner-border-sm" role="status" v-if="loading"></div>
              <span v-else>{{ bancos }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6 col-12">
        <div class="card card-statistic-1">
          <div class="card-icon bg-warning">
            <i class="fas fa-road"></i>
          </div>
          <div class="card-wrap">
            <div class="card-header">
              <h4>Detrans</h4>
            </div>
            <div class="card-body">
              <div class="spinner-border spinner-border spinner-border-sm" role="status" v-if="loading"></div>
              <span v-else>{{ detrans }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12">
        <div class="card card-statistic">
          <div class="card-stats">
            <div class="card-stats-title">Contratos
              <div class="dropdown d-inline">
                <a class="font-weight-600 dropdown-toggle" data-toggle="dropdown" id="orders-month">[
                  {{ this.detranFilter }} ]</a>
                <ul class="dropdown-menu dropdown-menu-sm">
                  <li class="dropdown-title">Selecione o Detran</li>
                  <li><a @click="selecionaDetran(4, 'AC')" class="dropdown-item">AC</a></li>
                  <li><a @click="selecionaDetran(3, 'PB')" class="dropdown-item">PB</a></li>
                  <li><a @click="selecionaDetran(1, 'SC')" class="dropdown-item">SC</a></li>
                  <li><a @click="selecionaDetran(2, 'SP')" class="dropdown-item">SP</a></li>
                </ul>
              </div>
            </div>
            <div class="card-stats-items">
              <div class="card-stats-item">
                <div class="spinner-border spinner-border spinner-border-sm card-stats-item-count" role="status"
                  v-if="loading"></div>
                <div class="card-stats-item-count" v-else>{{ total }}</div>
                <div class="card-stats-item-label">Total</div>
              </div>
              <div class="card-stats-item">
                <div class="spinner-border spinner-border spinner-border-sm card-stats-item-count" role="status"
                  v-if="loading"></div>
                <div class="card-stats-item-count" v-else>{{ concluido }}</div>
                <div class="card-stats-item-label">Concluído</div>
              </div>
              <div class="card-stats-item">
                <div class="spinner-border spinner-border spinner-border-sm card-stats-item-count" role="status"
                  v-if="loading"></div>
                <div class="card-stats-item-count" v-else>{{ processando }}</div>
                <div class="card-stats-item-label">Processando</div>
              </div>
              <div class="card-stats-item">
                <div class="spinner-border spinner-border spinner-border-sm card-stats-item-count" role="status"
                  v-if="loading"></div>
                <div class="card-stats-item-count" v-else>{{ pendente }}</div>
                <div class="card-stats-item-label">Pendentes</div>
              </div>
            </div>
            <div class="card-wrap height-5"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- charts -->
    <div class="row">
      <div class="col-md-8">

        <!-- contas a pagar -->
        <div class="card mt-2 text-primary">

          <div class="card-header p-4">
            Total de Contratos emitidos em {{ year }}
          </div>

          <div class="card-body" v-if="contratosAno.length > 0">
            <adminbarchart-component :chartdata="barChart.chartData" :options="barChart.chartOptions" />
          </div>
          <div class="card-body p-4 text-center" v-else>
            <div class="spinner-border spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
        <!-- end contas a pagar -->

      </div>

      <div class="col-md-4">

        <!-- contas a pagar -->
        <div class="card mt-2">
          <div class="card-header text-primary">
            Contas a Pagar e Receber
          </div>

          <div class="card-body p-4" v-if="valor_total_fechamento_detran > 0 && valor_total_fechamento_result1 > 0">
            <admindoughnutchart-component :chartdata="doughnutChart.chartData" :options="doughnutChart.chartOptions" />
          </div>
          <div class="card-body p-4 text-center" v-else>
            <div class="spinner-border spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
        <!-- end contas a pagar -->

        <div class="card mt-2 p-4 bg-primary text-center" style="font-size: 22px;">
          <b>SALDO<br>
            {{ formatCurrency(valor_total_fechamento_result1 - valor_total_fechamento_detran) }}</b>
        </div>

      </div>
    </div>
    <!-- end charts -->
  </section>
</template>
<script>
export default {
  data () {
    return {
      bancos: [],
      detrans: [],
      detranFilter: 'AC',
      detranFilterID: 4,
      fechamentos: [],
      fechamentosDetran: [],
      fechamentosResult1: [],
      contratosAno: [],
      total: -1,
      concluido: -1,
      processando: -1,
      pendente: -1,
      loading: false,
      valor_total_fechamento_detran: 0,
      valor_total_fechamento_result1: 0,
      barChart: '',
      doughnutChart: '',
      year: moment().format('YYYY')
    }
  },
  mounted () {
    this.loadDashboardData();
    this.loadItems();
    this.loadChartData();
    this.loadChart();
  },
  methods: {
    selecionaDetran (id, sigla) {
      this.detranFilter = sigla;
      this.detranFilterID = id;
      this.loadDashboardData();
    },
    loadDashboardData () {
      let _this = this;
      let filters = {
        search: [
          {
            field: "detrans",
            value: [_this.detranFilterID],
          }
        ]
      };
      axios
        .post("/api/admin/dashboarddata", { search: filters.search })
        .then((res) => {
          _this.total = res.data.qtd_contrato;
          _this.pendente = res.data.pendente;
          _this.concluido = res.data.concluido;
          _this.processando = res.data.processando;
        });
    },
    loadItems () {
      let _this = this;
      _this.loading = true;
      axios.get("/admin/dashboard/search?detran=" + _this.detranFilter).then((res) => {
        _this.bancos = res.data.bancos.length;
        _this.detrans = res.data.detrans.length;
        _this.loading = false;
      }).catch((err) => {
        _this.loading = false;
      });
    },
    loadChartData () {
      let _this = this;
      _this.loading = true;

      let formValues = ["mensal", _this.year + "-01-01 00:00:01", _this.year + "-12-31 23:59:59"];

      axios.get("/api/admin/dashboard/grafico/todos/" + formValues + "/all").then((res) => {

        var dados = res.data.dados.split(",");
        //var dados = '2023';
        _this.contratosAno = dados;

        _this.barChart = {
          chartData: {
            labels: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
            datasets: [{
              label: "Contratos por mês",
              backgroundColor: ["#ec2434", "#ec2434", "#ec2434", "#ec2434", "#ec2434", "#ec2434", "#ec2434", "#ec2434", "#ec2434", "#ec2434", "#ec2434", "#ec2434"],
              data: dados
            }]
          },
          chartOptions: {
            responsive: true,
            maintainAspectRatio: false
          }
        };

      }).catch((err) => {
        _this.loading = false;
      });
    },
    loadChart () {
      let _this = this;
      _this.loading = true;
      axios
        .get("/api/portal/contasapagar")
        .then((res) => {
          _this.fechamentos = res.data;
          _this.loading = false;

          console.log("Conta a pagar: ", res);

          res.data.forEach((item) => {
            if (item.tipo == "Detran") {
              let today = new Date().toISOString().slice(0, 10);
              let vencimento = new Date(item.data_limite_pagamento.date)
                .toISOString()
                .slice(0, 10);
              if (_this.fechamentosDetran.length <= 5 && vencimento > today) {
                _this.fechamentosDetran.push(item);
              }
              _this.valor_total_fechamento_detran += item.valor_fechamento;
            } else {
              let today = new Date().toISOString().slice(0, 10);
              let vencimento = new Date(item.data_limite_pagamento.date)
                .toISOString()
                .slice(0, 10);
              if (_this.fechamentosDetran.length <= 5 && vencimento > today) {
                _this.fechamentosResult1.push(item);
              }
              _this.valor_total_fechamento_result1 +=
                item.valor_fechamento_result1;
            }
            if (item.status_pagamento == 1) {
              _this.qtd_contratos += item.qtd_contratos / 2;
            } else {
              _this.qtd_contratos += item.qtd_contratos / 2;
            }

            _this.doughnutChart = {
              chartData: {
                hoverBackgroundColor: "red",
                hoverBorderWidth: 10,
                labels: ["Contas a Pagar", "Contas a Receber"],
                datasets: [{
                  label: "Valor",
                  backgroundColor: ["#E46651", "#ffa426"],
                  data: [this.valor_total_fechamento_detran, this.valor_total_fechamento_result1]
                }]
              },
              chartOptions: {
                hoverBorderWidth: 20
              },
            };

          });
        })
        .catch((err) => console.log(err));
    },
    formatCurrency: function (value) {
      return Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(value);
    }
  }
}
</script>
