<template>
	<div class="row access-groups">
		<div class="col">

			<!-- top -->
			<div class="row section-top">
				<div class="col-md-12 pad0">
					<p class="section-lead">Preencha os campos abaixo</p>
					<a href="#" @click="newData()" class="btn btn-primary float-right">SALVAR</a>
					<a href="#" @click="type = 'menu'" class="btn btn-secondary float-right" style="margin-right: 15px;"
						v-if="type != 'menu'">VER MENU</a>
				</div>
			</div>
			<!-- end top -->

			<!-- name -->
			<div class="card">
				<div class="card-body">
					<div class="form-group">
						<label>Nome do Grupo de Acesso</label>
						<input class="form-control" v-model="name" id="name" />
					</div>
				</div>
			</div>
			<!-- end name -->

			<!-- menu -->
			<div class="row menu" v-if="type == 'menu'">
				<div class="col-lg-6">
					<div class="card card-large-icons">
						<div class="card-icon bg-primary text-white">
							<i class="fas fa-cog"></i>
						</div>
						<div class="card-body">
							<h4>Painel Administrativo</h4>
							<p>Permissões relacionadas às funcionalidades do Painel Administrativo</p>
							<a href="#" @click="type = 'admin'" class="card-cta">Ver Permissões <i class="fas fa-chevron-right"></i></a>
						</div>
					</div>
				</div>
				<div class="col-lg-6">
					<div class="card card-large-icons">
						<div class="card-icon bg-primary text-white">
							<i class="fas fa-search"></i>
						</div>
						<div class="card-body">
							<h4>Portal da Instituição</h4>
							<p>Permissões relacionadas às funcionalidades do Portal da Instituição</p>
							<a href="#" @click="type = 'portal'" class="card-cta">Ver Permissões <i
									class="fas fa-chevron-right"></i></a>
						</div>
					</div>
				</div>
			</div>
			<!-- end menu -->

			<!-- itens admin -->
			<div class="row list" v-if="type == 'admin'">

				<!-- list all admin permissions -->
				<div class="col-md-4" v-for="_permission, index in permission.admin" :key="index">
					<div class="card">
						<div class="card-header">
							{{ _permission.title }}
						</div>
						<div class="card-body">
							<div class="item" v-for="_item, itemIndex in _permission.items" :key="itemIndex">
								{{ _item.title }}
								<label class="custom-switch">
									<input type="checkbox" v-model="_item.value" v-on:change="changeToggle(_item.name)"
										class="custom-switch-input">
									<span class="custom-switch-indicator"></span>
								</label>
							</div>
						</div>
					</div>
				</div>
				<!-- end list all admin permissions -->

			</div>
			<!-- end itens admin -->

			<!-- itens portal -->
			<div class="row list" v-if="type == 'portal'">

				<!-- list all portal permissions -->
				<div class="col-md-4" v-for="_permission, index in permission.portal" :key="index">
					<div class="card">
						<div class="card-header">
							{{ _permission.title }}
						</div>
						<div class="card-body">
							<div class="item" v-for="_item, itemIndex in _permission.items" :key="itemIndex">
								{{ _item.title }}
								<label class="custom-switch">
									<input type="checkbox" v-model="_item.value" v-on:change="changeToggle(_item.name)"
										class="custom-switch-input">
									<span class="custom-switch-indicator"></span>
								</label>
							</div>
						</div>
					</div>
				</div>
				<!-- end list all portal permissions -->

			</div>
			<!-- end itens portal -->

		</div>

		<vue-loading color="#ec2423" :active.sync="loading" :can-cancel="false"></vue-loading>

	</div><!-- row -->
</template>

<script>

import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";

export default {
	components: {
		VueGoodTable,
	},
	data() {
		return {
			loading: false,
			name: '',
			permission: {
				admin: [
					{
						title: "Bancos",
						items: [
							{
								title: 'Listar',
								name: 'admin-banks-list'
							}, {
								title: 'Criar',
								name: 'admin-banks-create'
							}, {
								title: 'Editar',
								name: 'admin-banks-edit'
							}, {
								title: 'Deletar',
								name: 'admin-banks-delete'
							}, {
								title: 'Desativar',
								name: 'admin-banks-disable'
							}
						]
					}, {
						title: "Bancos (Detrans)",
						items: [
							{
								title: 'Listar',
								name: 'admin-banks-detrans-list'
							}, {
								title: 'Criar',
								name: 'admin-banks-detrans-create'
							}, {
								title: 'Editar',
								name: 'admin-banks-detrans-edit'
							}, {
								title: 'Deletar',
								name: 'admin-banks-detrans-delete'
							}, {
								title: 'Desativar',
								name: 'admin-banks-detrans-disable'
							}
						]
					}, {
						title: "Bancos (Usuários)",
						items: [
							{
								title: 'Listar',
								name: 'admin-banks-users-list'
							}, {
								title: 'Criar',
								name: 'admin-banks-users-create'
							}, {
								title: 'Editar',
								name: 'admin-banks-users-edit'
							}, {
								title: 'Deletar',
								name: 'admin-banks-users-delete'
							}, {
								title: 'Desativar',
								name: 'admin-banks-users-disable'
							}, {
								title: 'Resetar Senha',
								name: 'admin-banks-users-resetpassword'
							}
						]
					}, {
						title: "Detrans",
						items: [
							{
								title: 'Listar',
								name: 'admin-detrans-list'
							}, {
								title: 'Criar',
								name: 'admin-detrans-create'
							}, {
								title: 'Editar',
								name: 'admin-detrans-edit'
							}, {
								title: 'Deletar',
								name: 'admin-detrans-delete'
							}
						]
					}, {
						title: "Detrans (Cabeçalho)",
						items: [
							{
								title: 'Listar',
								name: 'admin-detrans-header-list'
							}, {
								title: 'Criar',
								name: 'admin-detrans-header-create'
							}, {
								title: 'Editar',
								name: 'admin-detrans-header-edit'
							}, {
								title: 'Deletar',
								name: 'admin-detrans-header-delete'
							}
						]
					}, {
						title: "Detrans (Retorno)",
						items: [
							{
								title: 'Listar',
								name: 'admin-detrans-return-list'
							}, {
								title: 'Criar',
								name: 'admin-detrans-return-create'
							}, {
								title: 'Editar',
								name: 'admin-detrans-return-edit'
							}, {
								title: 'Deletar',
								name: 'admin-detrans-return-delete'
							}, {
								title: 'Vincular Contrato Status',
								name: 'admin-detrans-return-link'
							}
						]
					}, {
						title: "Detrans (Status)",
						items: [
							{
								title: 'Listar',
								name: 'admin-detrans-status-list'
							}, {
								title: 'Criar',
								name: 'admin-detrans-status-create'
							}, {
								title: 'Editar',
								name: 'admin-detrans-status-edit'
							}, {
								title: 'Deletar',
								name: 'admin-detrans-status-delete'
							}
						]
					}, {
						title: "Financeiro",
						items: [
							{
								title: 'Faturamento Detran',
								name: 'admin-finance-invoicing-detrans'
							}, {
								title: 'Contratos Enviados',
								name: 'admin-finance-contracts'
							}, {
								title: 'Contas a Pagar',
								name: 'admin-finance-accounts'
							}
						]
					}, {
						title: "Grupos de Acesso",
						items: [
							{
								title: 'Listar',
								name: 'admin-permissions-list'
							}, {
								title: 'Criar',
								name: 'admin-permissions-create'
							}, {
								title: 'Editar',
								name: 'admin-permissions-edit'
							}, {
								title: 'Deletar',
								name: 'admin-permissions-delete'
							}
						]
					}, {
						title: "Usuários",
						items: [
							{
								title: 'Listar',
								name: 'admin-users-list'
							}, {
								title: 'Criar',
								name: 'admin-users-create'
							}, {
								title: 'Editar',
								name: 'admin-users-edit'
							}, {
								title: 'Deletar',
								name: 'admin-users-delete'
							}, {
								title: 'Desativar',
								name: 'admin-users-disable'
							}, {
								title: 'Resetar Senha',
								name: 'admin-users-resetpassword'
							}
						]
					}, {
						title: "Logs",
						items: [
							{
								title: 'Log Acesso',
								name: 'admin-logs-access'
							}, {
								title: 'Log API',
								name: 'admin-logs-api'
							}, {
								title: 'Log Atividade',
								name: 'admin-logs-activity'
							}, {
								title: 'Log Erro',
								name: 'admin-logs-error'
							}, {
								title: 'Log de Falhas',
								name: 'admin-logs-failedjobs'
							}
						]
					}
				],
				portal: [
					{
						title: "Contratos",
						items: [
							{
								title: 'Criar',
								name: 'portal-contracts-create'
							}, {
								title: 'Editar',
								name: 'portal-contracts-edit'
							}, {
								title: 'Visualizar',
								name: 'portal-contracts-view'
							}, {
								title: 'Reenviar',
								name: 'portal-contracts-resend'
							}
						]
					}, {
						title: "Financeiro",
						items: [
							{
								title: 'Faturamento',
								name: 'portal-finance-invoicing'
							}, {
								title: 'Fechamento',
								name: 'portal-finance-closure'
							}
						]
					}, {
						title: "Usuários",
						items: [
							{
								title: 'Listar (Dados da Instituição, Agente e Usuários)',
								name: 'portal-users-list'
							}, {
								title: 'Criar',
								name: 'portal-users-create'
							}, {
								title: 'Editar',
								name: 'portal-users-edit'
							}, {
								title: 'Deletar',
								name: 'portal-users-delete'
							}, {
								title: 'Desativar',
								name: 'portal-users-disable'
							}, {
								title: 'Resetar Senha',
								name: 'portal-users-resetpassword'
							}
						]
					}, {
						title: "Navegação",
						items: [
							{
								title: 'Dashboard',
								name: 'portal-users-dashboard'
							}, {
								title: 'Contratos',
								name: 'portal-users-contratos'
							}, {
								title: 'Faturamento',
								name: 'portal-users-financeiro'
							}, {
								title: 'Logs - Acesso e Auditoria ',
								name: 'portal-users-logs'
							},							
						]
					}, {
						title: "Operações do Contrato",
						items: [
							{
								title: 'Inclusao de Contrato',
								name: 'portal-users-contracts-created'
							}, {
								title: 'Update de Contrato',
								name: 'portal-users-contracts-update'
							}, {
								title: 'Inclusao de Aditivo de Contrato',
								name: 'portal-users-contracts-create_additive'
							}, {
								title: 'Update de Aditivo de Contrato',
								name: 'portal-users-contracts-update_additive'
							}
						]
					}
				]
			},
			permissions: [],
			type: 'menu',
		}
	},
	mounted() {
	},
	methods: {
		changeToggle(toggle) {
			if (this.permissions.length == 0) {
				this.permissions.push(toggle);
			} else {
				var check = this.permissions.includes(toggle);
				if (check) {
					this.permissions.pop(toggle);
				} else {
					this.permissions.push(toggle);
				}
			}
		},
		newData() {
			let formData = new FormData();
			formData.append('name', this.name);
			this.permissions.forEach((perm, i) => {
				formData.append(`permissions[${i}]`, perm);
			});

			this.loading = true;

			axios.post('/admin/permissions', formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then((res) => {
				this.loading = false;

				this.$swal({
					title: 'Sucesso',
					text: 'Grupo de Acesso cadastrado com sucesso!',
					icon: 'success'
				});
			}).catch((err) => {
				this.loading = false;

				this.$swal({
					title: 'Erro',
					text: 'Falha ao cadastrar Grupo de Acesso. Tente novamente!',
					icon: 'error'
				});
			});
		}
	}
}
</script>
