<template>
	<div class="row">
		<div class="col-12">
			<div class="card pt-1 pb-1" style="margin-top:-35px; margin-bottom: 5px; ">

				<div class="wizard-form" id="grad1">
					<div class="row justify-content-center mt-0">
						<div class="col-12">
							<div class="card mt-3 mb-3 bxShadow">
								<h5 class="text-center"><strong>Informações do Contrato - Detran {{ titleform
										}}</strong>
								</h5>
								<div class="row">
									<div class="col-md-12 mx-0">
										<form id="msform" class="mt-2">
											<!-- progressbar -->
											<ul id="progressbarview" class="text-center">
												<a href="#" @click="changeStep(1)">
													<li :class="step >= 1 ? 'active' : ''" id="geral"><strong>Gerais e
															Veículo</strong></li>
												</a>
												<a href="#" @click="changeStep(2)">
													<li :class="step >= 2 ? 'active' : ''" id="financeiro"
														@click="changeStep(2)"><strong>Dados
															Financeiros</strong></li>
												</a>
												<a href="#" @click="changeStep(3)">
													<li :class="step >= 3 ? 'active' : ''" id="comprador"
														@click="changeStep(3)">
														<strong>Comprador</strong>
													</li>
												</a>
												<a href="#" @click="changeStep(4)">
													<li :class="step >= 4 ? 'active' : ''" id="agente"
														@click="changeStep(4)">
														<strong>Agente</strong>
													</li>
												</a>
											</ul>
											<!-- end progressbar -->

											<!-- gerais e veículos -->
											<fieldset v-if="step == 1">
												<div class="form-card">
													<div class="row p-4" style="margin-top:-25px">

														<!-- tipo_operacao -->
														<div
															:class="{ 'col-md-4': ['ES', 'SC', 'SP'].includes(uf_contrato), 'col-md-3': !['ES', 'SC', 'SP'].includes(uf_contrato) }">
															<div class="form-group disabled is-disabled">
																<label class="form-control-label"
																	for="input-tipo_operacao">Tipo de Operação</label>
																<select disabled id="input-tipo_operacao"
																	v-bind:class="{ 'is-invalid': errors.tipo_operacao }"
																	v-model="tipo_operacao"
																	class="form-control disabled"
																	placeholder="Tipo de Operação">
																	<option value="1">1 - Inclusão do registro de
																		contrato
																	</option>
																	<option value="2">2 - Alteração do registro de
																		contrato
																	</option>
																	<option value="3">3 - Inclusão do aditivo de
																		contrato
																	</option>
																	<option value="4">4 - Alteração do aditivo de
																		contrato
																	</option>
																</select>
																<div class="invalid-feedback"
																	v-if="errors.tipo_operacao">
																	<p>{{ errors.tipo_operacao[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end tipo_operacao -->

														<!-- sequencia_contrato -->
														<div v-if="['BA', 'SC'].includes(uf_contrato)" class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-sequencia_contrato">
																	Nº Sequencial Assentamento</label>
																<input disabled type="tel" id="input-sequencia_contrato"
																	v-bind:class="{ 'is-invalid': errors.sequencia_contrato }"
																	class="form-control"
																	placeholder="Sequencial Contrato"
																	v-model="sequencia_contrato" required="true"
																	maxlength="8">
																<div class="invalid-feedback"
																	v-if="errors.sequencia_contrato">
																	<p>{{ errors.sequencia_contrato[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-2 -->
														<!-- end sequencia_contrato -->

														<!-- Nº Assentamento RJ -->
														<div v-if="['RJ'].includes(uf_contrato)" class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-numero_assentamento">
																	Nº Sequencial Assentamento</label>
																<input disabled type="tel"
																	id="input-numero_assentamento"
																	v-bind:class="{ 'is-invalid': errors.numero_assentamento }"
																	class="form-control"
																	placeholder="Nº Sequencial Assentamento"
																	v-model="numero_assentamento" required="true"
																	maxlength="8">
																<div class="invalid-feedback"
																	v-if="errors.numero_assentamento">
																	<p>{{ errors.numero_assentamento[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-2 -->
														<!-- end Nº Assentamento RJ -->

														<!-- num_contrato_origem -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-num_contrato_origem">Nº Contrato
																	Origem</label>
																<input disabled type="text"
																	id="input-num_contrato_origem"
																	v-bind:class="{ 'is-invalid': errors.num_contrato_origem }"
																	class="form-control"
																	placeholder="Nº Contrato Origem"
																	v-model="num_contrato_origem" required="true"
																	maxlength="14">
																<div class="invalid-feedback"
																	v-if="errors.num_contrato_origem">
																	<p>{{ errors.num_contrato_origem[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end num_contrato_origem -->

														<!-- num_aditivo_origem -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-num_aditivo_origem">Nº Aditivo
																	Origem</label>
																<input disabled type="text"
																	id="input-num_aditivo_origem"
																	v-bind:class="{ 'is-invalid': errors.num_aditivo_origem }"
																	class="form-control" placeholder="Nº Aditivo Origem"
																	v-model="num_aditivo_origem" required="true"
																	maxlength="14">
																<div class="invalid-feedback"
																	v-if="errors.num_aditivo_origem">
																	<p>{{ errors.num_aditivo_origem[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end num_aditivo_origem -->


														<!-- chassi -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-chassi">Chassi</label>
																<input disabled type="text" id="input-chassi"
																	v-bind:class="{ 'is-invalid': errors.chassi }"
																	class="form-control" placeholder="Chassi"
																	v-model="chassi" required="true" maxlength="21">
																<div class="invalid-feedback" v-if="errors.chassi">
																	<p>{{ errors.chassi[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-4 -->
														<!-- end chassi -->

														<!-- remarcacao -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-remarcacao">Remarcação</label>
																<select disabled id="input-remarcacao"
																	v-bind:class="{ 'is-invalid': errors.remarcacao }"
																	class="form-control" placeholder="Remarcação"
																	v-model="remarcacao" required="true">
																	<option value="1">Remarcado</option>
																	<option value="2">Não Remarcado</option>
																</select>
																<div class="invalid-feedback" v-if="errors.remarcacao">
																	<p>{{ errors.remarcacao[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-2 -->
														<!-- end remarcacao -->

														<!-- uf_licenciamento -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-uf_licenciamento">UF
																	Licenciamento</label>
																<select disabled id="input-uf_licenciamento"
																	v-bind:class="{ 'is-invalid': errors.uf_licenciamento }"
																	class="form-control" placeholder="UF Placa"
																	v-model="uf_licenciamento" required="true">
																	<option v-for="item in states">{{ item }}</option>
																</select>
																<div class="invalid-feedback"
																	v-if="errors.uf_licenciamento">
																	<p>{{ errors.uf_licenciamento[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-6 -->
														<!-- end uf_placa -->

														<!-- uf_placa -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-uf_placa">UF
																	Placa</label>
																<select disabled id="input-uf_placa"
																	v-bind:class="{ 'is-invalid': errors.uf_placa }"
																	class="form-control" placeholder="UF Placa"
																	v-model="uf_placa" required="true">
																	<option v-for="item in states">{{ item }}</option>
																</select>
																<div class="invalid-feedback" v-if="errors.uf_placa">
																	<p>{{ errors.uf_placa[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-6 -->
														<!-- end uf_placa -->

														<!-- placa -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-placa">Placa</label>
																<input disabled type="text" id="input-placa"
																	v-bind:class="{ 'is-invalid': errors.placa }"
																	class="form-control" placeholder="Placa"
																	v-model="placa" required="true" maxlength="7">
																<div class="invalid-feedback" v-if="errors.placa">
																	<p>{{ errors.placa[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-4 -->
														<!-- end placa -->

														<!-- renavam -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-renavam">Renavam</label>
																<input disabled type="tel" id="input-renavam"
																	v-bind:class="{ 'is-invalid': errors.renavam }"
																	class="form-control" placeholder="Renavam"
																	v-model="renavam" required="true" maxlength="9">
																<div class="invalid-feedback" v-if="errors.renavam">
																	<p>{{ errors.renavam[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-2 -->
														<!-- end renavam -->

														<!-- ano_fabricacao -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-ano_fabricacao">Ano Fabricação</label>
																<input disabled type="tel" id="input-ano_fabricacao"
																	v-bind:class="{ 'is-invalid': errors.ano_fabricacao }"
																	class="form-control" placeholder="Ano Fabricação"
																	v-model="ano_fabricacao" required="true"
																	maxlength="4">
																<div class="invalid-feedback"
																	v-if="errors.ano_fabricacao">
																	<p>{{ errors.ano_fabricacao[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-2 -->
														<!-- end ano_fabricacao -->

														<!-- ano_modelo -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-ano_modelo">Ano
																	Modelo</label>
																<input disabled type="text" id="input-ano_modelo"
																	v-bind:class="{ 'is-invalid': errors.ano_modelo }"
																	class="form-control" placeholder="Ano Modelo"
																	v-model="ano_modelo" required="true" maxlength="4">
																<div class="invalid-feedback" v-if="errors.ano_modelo">
																	<p>{{ errors.ano_modelo[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-2 -->
														<!-- end ano_modelo -->

														<!-- Tipo de veiculos -->
														<div v-if="['RJ'].includes(uf_contrato)" class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-tipo_id">Tipo
																	de Veículo</label>
																<v-select disabled v-model="tipo_id" :options="tipos"
																	:reduce="descricao => descricao.id"
																	label="descricao"></v-select>
																<div v-if="errors.tipo_id" class="invalid-feedback">
																	<p>{{ errors.tipo_id }}</p>
																</div>
															</div><!-- form group -->
														</div>
														<!-- Tipo de veiculos -->

														<!-- Tipo de veiculos -->
														<div v-if="!['RJ'].includes(uf_contrato)" class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-tipo_id">Tipo
																	de Veículo</label>
																<v-select disabled v-model="tipo_id" :options="tipos"
																	:reduce="nome => nome.id" label="nome"></v-select>
																<div v-if="errors.tipo_id" class="invalid-feedback">
																	<p>{{ errors.tipo_id }}</p>
																</div>
															</div><!-- form group -->
														</div>
														<!-- Tipo de veiculos -->

														<!-- marcas -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-marcas">Marcas
																	do veículo</label>
																<v-select disabled label="nome" :options="marcas"
																	:reduce="nome => nome.id"
																	v-model="marca_id"></v-select>
																<div class="invalid-feedback" v-if="errors.marca">
																	<p>{{ errors.marca }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-2 -->
														<!-- end marcas -->

														<!-- Modelos -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-modelos">Modelos</label>
																<v-select disabled label="nome" :options="modelos"
																	:reduce="nome => nome.id"
																	v-model="modelo_id"></v-select>
																<div class="invalid-feedback" v-if="errors.modelos">
																	<p>{{ errors.modelos }}</p>
																</div>
															</div><!-- form group modelos-->
														</div><!-- col-md-2 modelos-->

														<!-- cores -->
														<div class="col-md-1">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-modelos">Cores</label>
																<v-select disabled v-model="cor_id" :options="cores"
																	:reduce="nome => nome.id" label="nome"></v-select>
																<div v-if="errors.cores" class="invalid-feedback">
																	<p>{{ errors.cores }}</p>
																</div>
															</div><!-- form group cores-->
														</div><!-- col-md-2 cores-->

														<!-- uf_liberacao_ass_contrato -->
														<div class="col-md-2" v-if="uf_contrato == 'RJ'">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-uf_liberacao_ass_contrato">UF Ass.
																	Contrato</label>
																<select disabled id="input-uf_liberacao_ass_contrato"
																	v-bind:class="{ 'is-invalid': errors.uf_liberacao_ass_contrato }"
																	class="form-control" placeholder="UF Ass."
																	v-model="uf_liberacao_ass_contrato" required="true">
																	<option v-for="item in states">{{ item }}</option>
																</select>
																<div class="invalid-feedback"
																	v-if="errors.uf_liberacao_ass_contrato">
																	<p>{{ errors.uf_liberacao_ass_contrato }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end uf_liberacao_ass_contrato -->

														<!-- municipio_ass_contrato -->
														<div class="col-md-2" v-if="uf_contrato == 'RJ'">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-municipio_ass_contrato">Município Ass.
																	Crédito</label>
																<v-select disabled label="municipio"
																	id="input-municipio_ass_contrato"
																	:options="municipios_lb"
																	:reduce="municipio => municipio.municipio"
																	v-model="municipio_ass_contrato"
																	@input="settedMunicipioCredito"
																	v-bind:class="{ 'is-invalid': errors.municipio_ass_contrato }">
																</v-select>
																<div class="invalid-feedback"
																	v-if="errors.municipio_ass_contrato">
																	<p>{{ errors.municipio_ass_contrato }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-6 -->
														<!-- end municipio_ass_contrato -->

														<!-- data_ass_contrato -->
														<div class="col-md-2" v-if="uf_contrato == 'RJ'">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-data_ass_contrato">Data Ass.
																	Contrato</label>
																<input disabled type="date" id="input-data_ass_contrato"
																	v-bind:class="{ 'is-invalid': errors.data_ass_contrato }"
																	class="form-control" placeholder="Data Contrato"
																	v-model="data_ass_contrato" required="true">
																<div class="invalid-feedback"
																	v-if="errors.data_ass_contrato">
																	<p>{{ errors.data_ass_contrato }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end data_ass_contrato -->

														<!-- clausula_penal -->
														<div class="col-md-2" v-if="uf_contrato == 'RJ'">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-clausula_penal">Cláusula Penal</label>
																<input disabled type="text" id="input-clausula_penal"
																	v-bind:class="{ 'is-invalid': errors.clausula_penal }"
																	class="form-control" placeholder="Cláusula Penal"
																	v-model="clausula_penal" required="true"
																	maxlength="50">
																<div class="invalid-feedback"
																	v-if="errors.clausula_penal">
																	<p>{{ errors.clausula_penal }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end clausula_penal -->

														<!-- vl_encargos_contratos -->
														<div class="col-md-2" v-if="uf_contrato == 'RJ'">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-vl_encargos_contratos">Valor Encargos
																	Contrato</label>
																<money disabled v-model="vl_encargos_contratos"
																	class="form-control" v-bind="SevenMoney"
																	maxlength="21">
																</money>
																<div class="invalid-feedback"
																	v-if="errors.vl_encargos_contratos">
																	<p>{{ errors.vl_encargos_contratos }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end vl_encargos_contratos -->


														<div class="col-md-12">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-modelos">Observações</label>
																<textarea disabled style="height: 90px"
																	id="input-observacao" class="form-control"
																	placeholder="Observações"
																	v-bind:class="{ 'is-invalid': errors.observacao }"
																	v-model="observacao" maxlength="255">
	                                                             </textarea>
															</div>
														</div>

													</div>
												</div>
												<a @click="changeStep(2)" class="btn-next">Próximo</a>
											</fieldset>
											<!-- end gerais e veículos -->

											<!--- dados financeiros -->
											<fieldset v-if="step == 2">
												<div class="form-card">
													<div class="row p-4" style="margin-top:-25px">

														<!-- num_contrato -->
														<div v-if="['RJ', 'SP', 'MG'].includes(uf_contrato)"
															class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-num_contrato">Nº Contrato</label>
																<input disabled type="text" id="input-num_contrato"
																	v-bind:class="{ 'is-invalid': errors.num_contrato }"
																	class="form-control" placeholder="Nº Contrato"
																	v-model="num_contrato" required="true"
																	maxlength="20">
																<div class="invalid-feedback"
																	v-if="errors.num_contrato">
																	<p>{{ errors.num_contrato[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-7 -->
														<div v-else class="col-md-4">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-num_contrato">Nº Contrato</label>
																<input disabled type="text" id="input-num_contrato"
																	v-bind:class="{ 'is-invalid': errors.num_contrato }"
																	class="form-control" placeholder="Nº Contrato"
																	v-model="num_contrato" required="true"
																	maxlength="20">
																<div class="invalid-feedback"
																	v-if="errors.num_contrato">
																	<p>{{ errors.num_contrato[0] }}</p>
																</div>
															</div>
														</div>
														<!-- end num_contrato -->

														<!-- data_contrato -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-data_contrato">Data Contrato</label>
																<input disabled type="date" id="input-data_contrato"
																	v-bind:class="{ 'is-invalid': errors.data_contrato }"
																	class="form-control" placeholder="Data Contrato"
																	v-model="data_contrato" required="true">
																<div class="invalid-feedback"
																	v-if="errors.data_contrato">
																	<p>{{ errors.data_contrato[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end data_contrato -->

														<!-- qtd_parcelas -->
														<div class="col-md-1">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-qtd_parcelas">Qtd Parcelas</label>
																<input disabled type="tel" id="input-qtd_parcelas"
																	v-bind:class="{ 'is-invalid': errors.qtd_parcelas }"
																	class="form-control" placeholder="Qtd Parcelas"
																	v-model="qtd_parcelas" required="true"
																	maxlength="3">
																<div class="invalid-feedback"
																	v-if="errors.qtd_parcelas">
																	<p>{{ errors.qtd_parcelas[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-2 -->
														<!-- end qtd_parcelas -->

														<!-- Data Vigência de Contrato -->
														<div class="col-md-2" v-if="uf_contrato == 'SP'">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-data_vigencia_contrato">Data Vigência de
																	Contrato</label>
																<input disabled type="date"
																	id="input-data_vigencia_contrato"
																	v-bind:class="{ 'is-invalid': errors.data_vigencia_contrato }"
																	class="form-control" placeholder="Data Contrato"
																	v-model="data_vigencia_contrato" required="true">
																<div class="invalid-feedback"
																	v-if="errors.data_vigencia_contrato">
																	<p>{{ errors.data_vigencia_contrato[0] }}</p>
																</div>
															</div><!-- form group -->
														</div>
														<!-- End Data Vigência de Contrato -->

														<!-- num_gravame -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-num_gravame">Nº
																	Gravame</label>
																<input disabled type="tel" id="input-num_gravame"
																	v-bind:class="{ 'is-invalid': errors.num_gravame }"
																	class="form-control" placeholder="Nº Gravame"
																	v-model="num_gravame" required="true" maxlength="8">
																<div class="invalid-feedback" v-if="errors.num_gravame">
																	<p>{{ errors.num_gravame[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end num_gravame -->

														<!-- tipo_gravame -->
														<div class="col-md-1">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-tipo_gravame">Tipo Gravame</label>
																<input disabled type="tel" id="input-tipo_gravame"
																	v-bind:class="{ 'is-invalid': errors.tipo_gravame }"
																	class="form-control" placeholder="Tipo Gravame"
																	v-model="tipo_gravame" required="true"
																	maxlength="2">
																<div class="invalid-feedback"
																	v-if="errors.tipo_gravame">
																	<p>{{ errors.tipo_gravame[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end tipo_gravame -->

														<!-- num_duda -->
														<div class="col-md-3" v-if="['RJ'].includes(uf_contrato)">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-num_duda">Nº
																	DUDA</label>
																<input disabled type="tel" id="input-num_duda"
																	v-bind:class="{ 'is-invalid': errors.num_duda }"
																	class="form-control" placeholder="Nº Gravame"
																	v-model="num_duda" required="true" maxlength="10">
																<div class="invalid-feedback" v-if="errors.num_duda">
																	<p>{{ errors.num_duda }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end num_duda -->

														<!--correcao_monetaria-->
														<div class="col-md-2" v-if="['RJ'].includes(uf_contrato)">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-correcao_monetaria">Correção
																	monetária</label>
																<money disabled v-model="correcao_monetaria"
																	class="form-control correcao_monetaria"
																	v-bind="percentmoney" maxlength="14"
																	v-bind:class="{ 'is-invalid': errors.correcao_monetaria }">
																</money>
																<div class="invalid-feedback"
																	v-if="errors.correcao_monetaria">
																	<p>{{ errors.correcao_monetaria }}</p>
																</div>
															</div>
														</div>
														<!-- end correcao_monetaria-->

														<!-- taxa_juro_mes -->
														<div class="col-md-1"
															v-if="['RJ', 'MG', 'SP'].includes(uf_contrato)">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-taxa_juro_mes">Tx. Juros Mês</label>
																<money disabled type="tel" id="input-taxa_juro_mes"
																	v-bind:class="{ 'is-invalid': errors.taxa_juro_mes }"
																	class="form-control" placeholder="Taxa de Juros Mês"
																	v-model="taxa_juro_mes" required="true"
																	maxlength="14" v-bind="percentmoney"></money>
																<div class="invalid-feedback"
																	v-if="errors.taxa_juro_mes">
																	<p>{{ errors.taxa_juro_mes[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end taxa_juro_mes -->

														<!-- taxa_juro_mes -->
														<div v-else class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-taxa_juro_mes">Tx. Juros Mês</label>
																<money disabled type="tel" id="input-taxa_juro_mes"
																	v-bind:class="{ 'is-invalid': errors.taxa_juro_mes }"
																	class="form-control" placeholder="Taxa de Juros Mês"
																	v-model="taxa_juro_mes" required="true"
																	maxlength="14" v-bind="percentmoney"></money>
																<div class="invalid-feedback"
																	v-if="errors.taxa_juro_mes">
																	<p>{{ errors.taxa_juro_mes[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end taxa_juro_mes -->

														<!-- taxa_juro_ano -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-taxa_juro_ano">Taxa de Juros Ano</label>
																<money disabled type="tel" id="input-taxa_juro_ano"
																	v-bind:class="{ 'is-invalid': errors.taxa_juro_ano }"
																	class="form-control" placeholder="Taxa de Juros Ano"
																	v-model="taxa_juro_ano" required="true"
																	maxlength="14" v-bind="percentmoney"></money>
																<div class="invalid-feedback"
																	v-if="errors.taxa_juro_ano">
																	<p>{{ errors.taxa_juro_ano[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end taxa_juro_ano -->

														<!-- taxa_juro_multa -->
														<div class="col-md-1">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-taxa_juro_multa">Tx. Juros
																	Multa?</label>
																<select disabled type="text" id="input-taxa_juro_multa"
																	v-bind:class="{ 'is-invalid': errors.taxa_juro_multa }"
																	class="form-control"
																	placeholder="Taxa de Juros Multa"
																	v-model="taxa_juro_multa" required="true">
																	<option value="NAO">Não</option>
																	<option value="SIM">Sim</option>
																</select>
																<div class="invalid-feedback"
																	v-if="errors.taxa_juro_multa">
																	<p>{{ errors.taxa_juro_multa[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end taxa_juro_multa -->


														<!-- taxa_multa -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-taxa_multa">
																	Taxa Juros Multa</label>
																<money disabled type="tel" id="input-taxa_multa"
																	v-bind:class="{ 'is-invalid': errors.taxa_multa }"
																	class="form-control" placeholder="Taxa Multa"
																	v-model="taxa_multa" required="true" maxlength="14"
																	v-bind="percentmoney"></money>
																<div class="invalid-feedback" v-if="errors.taxa_multa">
																	<p>{{ errors.taxa_multa[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end taxa_multa -->

														<!-- taxa_mora_dia -->
														<div class="col-md-1">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-taxa_mora_dia">Tx. Mora Dia?</label>
																<select disabled type="text" id="input-taxa_mora_dia"
																	v-bind:class="{ 'is-invalid': errors.taxa_mora_dia }"
																	class="form-control" placeholder=""
																	v-model="taxa_mora_dia" required="true">
																	<option value="NAO">Não</option>
																	<option value="SIM">Sim</option>
																</select>
																<div class="invalid-feedback"
																	v-if="errors.taxa_mora_dia">
																	<p>{{ errors.taxa_mora_dia[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end taxa_mora_dia -->

														<!-- taxa_mora -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label" for="input-taxa_mora">
																	Taxa Mora Dia</label>
																<money disabled type="tel" id="input-taxa_mora"
																	v-bind:class="{ 'is-invalid': errors.taxa_mora }"
																	class="form-control" placeholder="Taxa Mora"
																	v-model="taxa_mora" required="true" maxlength="14"
																	v-bind="percentmoney"></money>
																<div class="invalid-feedback" v-if="errors.taxa_mora">
																	<p>{{ errors.taxa_mora[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end taxa_mora -->

														<!-- indicativo_penalidade -->
														<div class="col-md-1">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-indicativo_penalidade">Penalidade?</label>
																<select disabled type="text"
																	id="input-indicativo_penalidade"
																	v-bind:class="{ 'is-invalid': errors.indicativo_penalidade }"
																	class="form-control" placeholder=""
																	v-model="indicativo_penalidade" required="true">
																	<option value="NAO">Não</option>
																	<option value="SIM">Sim</option>
																</select>
																<div class="invalid-feedback"
																	v-if="errors.indicativo_penalidade">
																	<p>{{ errors.indicativo_penalidade[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end indicativo_penalidade -->

														<!-- penalidade -->
														<div v-if="uf_contrato !== 'RJ'" class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-penalidade">Penalidade</label>
																<input disabled type="text" id="input-penalidade"
																	v-bind:class="{ 'is-invalid': errors.penalidade }"
																	class="form-control" placeholder="Penalidade"
																	v-model="penalidade" required="true" maxlength="50">
																<div class="invalid-feedback" v-if="errors.penalidade">
																	<p>{{ errors.penalidade[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-6 -->
														<!-- end penalidade -->

														<!-- penalidade -->
														<div class="col-md-1">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-penalidade">Penalidade</label>
																<input disabled type="text" id="input-penalidade"
																	v-bind:class="{ 'is-invalid': errors.penalidade }"
																	class="form-control" placeholder="Penalidade"
																	v-model="penalidade" required="true" maxlength="50">
																<div class="invalid-feedback" v-if="errors.penalidade">
																	<p>{{ errors.penalidade[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-6 -->
														<!-- end penalidade -->

														<!-- indicativo_comissao -->
														<div class="col-md-1">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-indicativo_comissao">Comissão?</label>
																<select disabled type="text"
																	id="input-indicativo_comissao"
																	v-bind:class="{ 'is-invalid': errors.indicativo_comissao }"
																	class="form-control" placeholder=""
																	v-model="indicativo_comissao" required="true">
																	<option value="NAO">Não</option>
																	<option value="SIM">Sim</option>
																</select>
																<div class="invalid-feedback"
																	v-if="errors.indicativo_comissao">
																	<p>{{ errors.indicativo_comissao[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end indicativo_comissao -->

														<!-- comissao -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-comissao">Valor
																	Comissão</label>
																<money disabled type="tel" id="input-comissao"
																	v-bind:class="{ 'is-invalid': errors.comissao }"
																	class="form-control" placeholder="Comissão"
																	v-model="comissao" required="true" maxlength="18"
																	v-bind="valueMaskD3"></money>
																<div class="invalid-feedback" v-if="errors.comissao">
																	<p>{{ errors.comissao[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end comissao -->

														<!-- valor_taxa_contrato -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-valor_taxa_contrato">Valor Taxa
																	Contrato</label>
																<money disabled type="tel"
																	id="input-valor_taxa_contrato"
																	v-bind:class="{ 'is-invalid': errors.valor_taxa_contrato }"
																	class="form-control"
																	placeholder="Valor Taxa Contrato"
																	v-model="valor_taxa_contrato" required="true"
																	maxlength="14" v-bind="SevenMoney"></money>
																<div class="invalid-feedback"
																	v-if="errors.valor_taxa_contrato">
																	<p>{{ errors.valor_taxa_contrato[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end valor_taxa_contrato -->

														<!-- valor_total_financiamento -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-valor_total_financiamento">Valor Total do
																	Financiamento</label>
																<money disabled type="tel"
																	id="input-valor_total_financiamento"
																	v-bind:class="{ 'is-invalid': errors.valor_total_financiamento }"
																	class="form-control"
																	placeholder="Valor Total do Financiamento"
																	v-model="valor_total_financiamento" required="true"
																	maxlength="21" v-bind="SevenMoney"></money>
																<div class="invalid-feedback"
																	v-if="errors.valor_total_financiamento">
																	<p>{{ errors.valor_total_financiamento[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end valor_total_financiamento -->

														<!-- valor_parcela -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-valor_parcela">Valor da Parcela</label>
																<money disabled type="tel" id="input-valor_parcela"
																	v-bind:class="{ 'is-invalid': errors.valor_parcela }"
																	class="form-control" placeholder="Valor da Parcela"
																	v-model="valor_parcela" required="true"
																	maxlength="14" v-bind="SevenMoney"></money>
																<div class="invalid-feedback"
																	v-if="errors.valor_parcela">
																	<p>{{ errors.valor_parcela[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end valor_parcela -->

														<!-- valor_iof -->
														<div v-if="['RJ'].includes(uf_contrato)" class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-valor_iof">Valor IOF</label>
																<money disabled type="tel" id="input-valor_iof"
																	v-bind:class="{ 'is-invalid': errors.valor_iof }"
																	class="form-control" placeholder="Valor IOF"
																	v-model="valor_iof" required="true" maxlength="14"
																	v-bind="SevenMoney"></money>
																<div class="invalid-feedback" v-if="errors.valor_iof">
																	<p>{{ errors.valor_iof[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end valor_iof -->

														<!-- valor_iof -->
														<div v-else class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-valor_iof">Valor IOF</label>
																<money disabled type="tel" id="input-valor_iof"
																	v-bind:class="{ 'is-invalid': errors.valor_iof }"
																	class="form-control" placeholder="Valor IOF"
																	v-model="valor_iof" required="true" maxlength="14"
																	v-bind="SevenMoney"></money>
																<div class="invalid-feedback" v-if="errors.valor_iof">
																	<p>{{ errors.valor_iof[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end valor_iof -->



														<!-- data_vecto_primeira_parcela -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-data_vecto_primeira_parcela">Dt.
																	Venc. 1ª Parcela</label>
																<input disabled type="date"
																	id="input-data_vecto_primeira_parcela"
																	v-bind:class="{ 'is-invalid': errors.data_vecto_primeira_parcela }"
																	class="form-control"
																	placeholder="data_vecto_primeira_parcela"
																	v-model="data_vecto_primeira_parcela"
																	required="true">
																<div class="invalid-feedback"
																	v-if="errors.data_vecto_primeira_parcela">
																	<p>{{ errors.data_vecto_primeira_parcela[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end data_vecto_primeira_parcela -->

														<!-- data_vecto_ultima_parcela -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-data_vecto_ultima_parcela">Dt. de
																	Venc. Última Parcela</label>
																<input disabled type="date"
																	id="input-data_vecto_ultima_parcela"
																	v-bind:class="{ 'is-invalid': errors.data_vecto_ultima_parcela }"
																	class="form-control"
																	placeholder="data_vecto_ultima_parcela"
																	v-model="data_vecto_ultima_parcela" required="true">
																<div class="invalid-feedback"
																	v-if="errors.data_vecto_ultima_parcela">
																	<p>{{ errors.data_vecto_ultima_parcela[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-4 -->
														<!-- end data_vecto_ultima_parcela -->

														<!-- data_liberacao_credito -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-data_liberacao_credito">Data de Liberação
																	do
																	Crédito</label>
																<input disabled type="date"
																	id="input-data_liberacao_credito"
																	v-bind:class="{ 'is-invalid': errors.data_liberacao_credito }"
																	class="form-control"
																	placeholder="data_liberacao_credito"
																	v-model="data_liberacao_credito" required="true">
																<div class="invalid-feedback"
																	v-if="errors.data_liberacao_credito">
																	<p>{{ errors.data_liberacao_credito[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-4 -->
														<!-- end data_liberacao_credito -->

														<!-- uf_liberacao_credito -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-uf_liberacao_credito">UF de Liberação do
																	Crédito</label>
																<select disabled id="input-uf_liberacao_credito"
																	v-bind:class="{ 'is-invalid': errors.uf_liberacao_credito }"
																	class="form-control" placeholder="UF Placa"
																	v-model="uf_liberacao_credito" required="true">
																	<option v-for="item in states">{{ item }}</option>
																</select>
																<div class="invalid-feedback"
																	v-if="errors.uf_liberacao_credito">
																	<p>{{ errors.uf_liberacao_credito[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end uf_liberacao_credito -->

														<!-- municipio_liberacao_credito -->
														<div class="col-md-4">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-municipio_liberacao_credito">Município de
																	Liberação do Crédito</label>
																<input v-if="municipio_credito != ''" disabled
																	type="text" id="input-municipio_liberacao_credito"
																	v-bind:class="{ 'is-invalid': errors.municipio_liberacao_credito }"
																	class="form-control"
																	placeholder="Município de Liberação do Crédito"
																	v-model="municipio_credito" required="true"
																	maxlength="25">
																<input v-else="municipio_credito == ''" disabled
																	type="text" id="input-municipio_liberacao_credito"
																	v-bind:class="{ 'is-invalid': errors.municipio_liberacao_credito }"
																	class="form-control"
																	placeholder="Município de Liberação do Crédito"
																	v-model="municipio_liberacao_credito"
																	required="true" maxlength="25">
																<div class="invalid-feedback"
																	v-if="errors.municipio_liberacao_credito">
																	<p>{{ errors.municipio_liberacao_credito[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-6 -->
														<!-- end municipio_liberacao_credito -->

														<!-- indice -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-indice">Indíce</label>
																<input disabled type="text" id="input-indice"
																	v-bind:class="{ 'is-invalid': errors.indice }"
																	class="form-control" placeholder="indice"
																	v-model="indice" required="true" maxlength="10">
																<div class="invalid-feedback" v-if="errors.indice">
																	<p>{{ errors.indice[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-2 -->
														<!-- end indice -->

														<!-- num_grupo_consorcio -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-num_grupo_consorcio">Nº Grupo
																	Consórcio</label>
																<input disabled type="text"
																	id="input-num_grupo_consorcio"
																	v-bind:class="{ 'is-invalid': errors.num_grupo_consorcio }"
																	class="form-control"
																	placeholder="Nº Grupo Consórcio"
																	v-model="num_grupo_consorcio" required="true"
																	maxlength="6">
																<div class="invalid-feedback"
																	v-if="errors.num_grupo_consorcio">
																	<p>{{ errors.num_grupo_consorcio[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-2 -->
														<!-- end num_grupo_consorcio -->

														<!-- num_cota_consorcio -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-num_cota_consorcio">Nº Cota
																	Consórcio</label>
																<input disabled type="tel" id="input-num_cota_consorcio"
																	v-bind:class="{ 'is-invalid': errors.num_cota_consorcio }"
																	class="form-control" placeholder="Nº Cota Consórcio"
																	v-model="num_cota_consorcio" required="true"
																	maxlength="6">
																<div class="invalid-feedback"
																	v-if="errors.num_cota_consorcio">
																	<p>{{ errors.num_cota_consorcio[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-2 -->
														<!-- end num_cota_consorcio -->

														<!-- num_aditivo -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-num_aditivo">Nº
																	Aditivo</label>
																<input disabled type="text" id="input-num_aditivo"
																	v-bind:class="{ 'is-invalid': errors.num_aditivo }"
																	class="form-control" placeholder="Nº Aditivo"
																	v-model="num_aditivo" required="true"
																	maxlength="20">
																<div class="invalid-feedback" v-if="errors.num_aditivo">
																	<p>{{ errors.num_aditivo[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end num_aditivo -->

														<!-- data_aditivo -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-data_aditivo">Data Aditivo</label>
																<input disabled type="date" id="input-data_aditivo"
																	v-bind:class="{ 'is-invalid': errors.data_aditivo }"
																	class="form-control" placeholder="data_aditivo"
																	v-model="data_aditivo" required="true">
																<div class="invalid-feedback"
																	v-if="errors.data_aditivo">
																	<p>{{ errors.data_aditivo[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end data_aditivo -->

													</div>
												</div>
												<a @click="changeStep(1)" class="btn-previous">Anterior</a>
												<a @click="changeStep(3)" class="btn-next">Próximo</a>
											</fieldset>
											<!-- end dados financeiros -->

											<!-- comprador -->
											<fieldset v-if="step == 3">
												<div class="form-card">
													<div class="row p-4" style="margin-top:-25px">

														<!-- cpf_cnpj_devedor -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-cpf_cnpj_devedor">CPF / CNPJ</label>
																<input disabled type="text" id="input-cpf_cnpj_devedor"
																	v-bind:class="{ 'is-invalid': errors.cpf_cnpj_devedor }"
																	class="form-control" placeholder="CPF / CNPJ"
																	v-model="cpf_cnpj_devedor" required="true"
																	maxlength="14">
																<div class="invalid-feedback"
																	v-if="errors.cpf_cnpj_devedor">
																	<p>{{ errors.cpf_cnpj_devedor[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-6 -->
														<!-- end cpf_cnpj_devedor -->

														<!-- nome_devedor -->
														<div class="col-md-5">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-nome_devedor">Nome</label>
																<input disabled type="text" id="input-nome_devedor"
																	v-bind:class="{ 'is-invalid': errors.nome_devedor }"
																	class="form-control" placeholder="Nome"
																	v-model="nome_devedor" required="true"
																	maxlength="40">
																<div class="invalid-feedback"
																	v-if="errors.nome_devedor">
																	<p>{{ errors.nome_devedor[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-6 -->
														<!-- end nome_devedor -->

														<!-- ddd_devedor -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-ddd_devedor">DDD</label>
																<input disabled type="tel" id="input-ddd_devedor"
																	v-bind:class="{ 'is-invalid': errors.ddd_devedor }"
																	class="form-control" placeholder="DDD"
																	v-model="ddd_devedor" required="true" maxlength="4">
																<div class="invalid-feedback" v-if="errors.ddd_devedor">
																	<p>{{ errors.ddd_devedor[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-2 -->
														<!-- end ddd_devedor -->

														<!-- telefone_devedor -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-telefone_devedor">Telefone</label>
																<input disabled type="text" id="input-telefone_devedor"
																	v-bind:class="{ 'is-invalid': errors.telefone_devedor }"
																	class="form-control" placeholder="Telefone"
																	v-model="telefone_devedor" required="true"
																	maxlength="9">
																<div class="invalid-feedback"
																	v-if="errors.telefone_devedor">
																	<p>{{ errors.telefone_devedor[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end telefone_devedor -->

														<!-- cep_devedor -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-cep_devedor">CEP</label>
																<input disabled type="text" id="input-cep_devedor"
																	v-bind:class="{ 'is-invalid': errors.cep_devedor }"
																	class="form-control" placeholder="CEP"
																	v-model="cep_devedor" required="true" maxlength="8">
																<div class="invalid-feedback" v-if="errors.cep_devedor">
																	<p>{{ errors.cep_devedor[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end cep_devedor -->

														<!-- uf_devedor -->
														<div class="col-md-1">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-uf_devedor">UF</label>
																<select disabled id="input-uf_devedor"
																	v-bind:class="{ 'is-invalid': errors.uf_devedor }"
																	class="form-control" placeholder="UF"
																	v-model="uf_devedor" required="true">
																	<option v-for="item in states">{{ item }}</option>
																</select>
																<div class="invalid-feedback" v-if="errors.uf_devedor">
																	<p>{{ errors.uf_devedor[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-2 -->
														<!-- end uf_devedor -->

														<!-- nome_municipio_devedor -->
														<div class="col-md-4">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-nome_municipio_devedor">Município</label>
																<input disabled type="text"
																	id="input-nome_municipio_devedor"
																	v-bind:class="{ 'is-invalid': errors.nome_municipio_devedor }"
																	class="form-control" placeholder="Município"
																	v-model="municipio_devedor" required="true"
																	maxlength="60">
																<div class="invalid-feedback"
																	v-if="errors.nome_municipio_devedor">
																	<p>{{ errors.nome_municipio_devedor[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-4 -->
														<!-- end nome_municipio_devedor -->

														<!-- nome_logradouro_devedor -->
														<div class="col-md-5">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-nome_logradouro_devedor">Logradouro</label>
																<input disabled type="text"
																	id="input-nome_logradouro_devedor"
																	v-bind:class="{ 'is-invalid': errors.nome_logradouro_devedor }"
																	class="form-control" placeholder="Logradouro"
																	v-model="nome_logradouro_devedor" required="true"
																	maxlength="100">
																<div class="invalid-feedback"
																	v-if="errors.nome_logradouro_devedor">
																	<p>{{ errors.nome_logradouro_devedor[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-6 -->
														<!-- end nome_logradouro_devedor -->

														<!-- num_imovel_devedor -->
														<div class="col-md-1">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-num_imovel_devedor">Nº</label>
																<input disabled type="text"
																	id="input-num_imovel_devedor"
																	v-bind:class="{ 'is-invalid': errors.num_imovel_devedor }"
																	class="form-control" placeholder="Nº"
																	v-model="num_imovel_devedor" required="true"
																	maxlength="5">
																<div class="invalid-feedback"
																	v-if="errors.num_imovel_devedor">
																	<p>{{ errors.num_imovel_devedor[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-2 -->
														<!-- end num_imovel_devedor -->

														<!-- bairro_devedor -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-bairro_devedor">Bairro</label>
																<input disabled type="text" id="input-bairro_devedor"
																	v-bind:class="{ 'is-invalid': errors.bairro_devedor }"
																	class="form-control" placeholder="Bairro"
																	v-model="bairro_devedor" required="true"
																	maxlength="100">
																<div class="invalid-feedback"
																	v-if="errors.bairro_devedor">
																	<p>{{ errors.bairro_devedor[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end bairro_devedor -->

														<!-- complemento_imovel_devedor -->
														<div class="col-md-4">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-complemento_imovel_devedor">Complemento</label>
																<input disabled type="text"
																	id="input-complemento_imovel_devedor"
																	v-bind:class="{ 'is-invalid': errors.complemento_imovel_devedor }"
																	class="form-control" placeholder="Complemento"
																	v-model="complemento_imovel_devedor" required="true"
																	maxlength="20">
																<div class="invalid-feedback"
																	v-if="errors.complemento_imovel_devedor">
																	<p>{{ errors.complemento_imovel_devedor[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-4 -->
														<!-- end complemento_imovel_devedor -->

														<!-- codigo SERPRO-->
														<div class="col-md-2">
															<div class="form-group">
																<!-- <div class="form-group" v-if="!cepValido"></div> -->
																<label class="form-control-label"
																	for="input_cod_serpro_devedor">Cód SERPRO</label>
																<input disabled type="text"
																	id="input_cod_serpro_devedor"
																	v-bind:class="{ 'is-invalid': errors.cod_serpro_devedor }"
																	class="form-control" placeholder="Código"
																	v-model="cod_serpro_devedor" required="true"
																	maxlength="4">
																<div class="invalid-feedback" v-if="errors.cep_devedor">
																	<p>{{ errors.cod_serpro_devedor[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-2 -->
														<!-- end codigo SERPRO -->

														<!-- arquivo contrato -->
														<div class="col-md-7">
															<div class="form-group">
																<label class="form-control-label">PDF do
																	Contrato</label>
																<div
																	v-if="arquivo === 'null' || arquivo === '' || arquivo === null">
																	<button type="button"
																		class="btn btn-primary icon-left btn-icon disabled"
																		title="Contrato PDF não cadastrado" disabled>
																		<i class="fas fa-download"></i>
																		Download</button>
																</div>
																<div v-else>
																	<a :href="'/portal/contratos/' + url_estado + '/' + this.getData('id') + '/download'"
																		target="_blank"
																		class="btn btn-primary icon-left btn-icon">
																		<i class="fas fa-download"></i> Download</a>
																</div>
															</div>
														</div>
														<!-- end arquivo contrato -->
													</div>
												</div>
												<a @click="changeStep(2)" class="btn-previous">Anterior</a>
												<a class="btn-next" @click="changeStep(4)">Próximo</a>
											</fieldset>
											<!-- end comprador -->
											<!-- agente -->
											<fieldset v-if="step == 4">
												<div class="form-card">
													<div class="row p-4" style="margin-top:-25px">

														<!-- nome_agente -->
														<div class="col-md-6">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-nome_agente">Nome do Agente</label>
																<input disabled type="text" id="input-nome_agente"
																	v-bind:class="{ 'is-invalid': errors.nome_agente }"
																	class="form-control" placeholder="Nome do Agente"
																	v-model="nome_agente" required="true"
																	maxlength="40">
																<div class="invalid-feedback" v-if="errors.nome_agente">
																	<p>{{ errors.nome_agente[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-6 -->
														<!-- end nome_agente -->

														<!-- cnpj_agente -->
														<div class="col-md-6">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-cnpj_agente">CNPJ do Agente</label>
																<input disabled type="text" id="input-cnpj_agente"
																	v-bind:class="{ 'is-invalid': errors.cnpj_agente }"
																	class="form-control" placeholder="CNPJ do Agente"
																	v-model="cnpj_agente" required="true"
																	maxlength="14">
																<div class="invalid-feedback" v-if="errors.cnpj_agente">
																	<p>{{ errors.cnpj_agente[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-6 -->
														<!-- end cnpj_agente -->

														<!-- nome_logradouro_agente -->
														<div class="col-md-6">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-nome_logradouro_agente">Logradouro</label>
																<input disabled type="text"
																	id="input-nome_logradouro_agente"
																	v-bind:class="{ 'is-invalid': errors.nome_logradouro_agente }"
																	class="form-control" placeholder="Logradouro"
																	v-model="nome_logradouro_agente" required="true"
																	maxlength="30">
																<div class="invalid-feedback"
																	v-if="errors.nome_logradouro_agente">
																	<p>{{ errors.nome_logradouro_agente[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-6 -->
														<!-- end nome_logradouro_agente -->

														<!-- num_imovel_agente -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-num_imovel_agente">Nº</label>
																<input disabled type="text" id="input-num_imovel_agente"
																	v-bind:class="{ 'is-invalid': errors.num_imovel_agente }"
																	class="form-control" placeholder="Nº"
																	v-model="num_imovel_agente" required="true"
																	maxlength="5">
																<div class="invalid-feedback"
																	v-if="errors.num_imovel_agente">
																	<p>{{ errors.num_imovel_agente[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-2 -->
														<!-- end num_imovel_agente -->

														<!-- complemento_imovel_agente -->
														<div class="col-md-4">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-complemento_imovel_agente">Complemento</label>
																<input disabled type="text"
																	id="input-complemento_imovel_agente"
																	v-bind:class="{ 'is-invalid': errors.complemento_imovel_agente }"
																	class="form-control" placeholder="Complemento"
																	v-model="complemento_imovel_agente" required="true"
																	maxlength="20">
																<div class="invalid-feedback"
																	v-if="errors.complemento_imovel_agente">
																	<p>{{ errors.complemento_imovel_agente[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-4 -->
														<!-- end complemento_imovel_agente -->

														<!-- bairro_agente -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-bairro_agente">Bairro</label>
																<input disabled type="text" id="input-bairro_agente"
																	v-bind:class="{ 'is-invalid': errors.bairro_agente }"
																	class="form-control" placeholder="Bairro"
																	v-model="bairro_agente" required="true"
																	maxlength="20">
																<div class="invalid-feedback"
																	v-if="errors.bairro_agente">
																	<p>{{ errors.bairro_agente[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end bairro_agente -->

														<!-- nome_municipio_agente -->
														<div class="col-md-4">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-nome_municipio_agente">Município</label>
																<input disabled type="text"
																	id="input-nome_municipio_agente"
																	v-bind:class="{ 'is-invalid': errors.nome_municipio_agente }"
																	class="form-control" placeholder="Município"
																	v-model="municipio_agente" required="true"
																	maxlength="40">
																<div class="invalid-feedback"
																	v-if="errors.nome_municipio_agente">
																	<p>{{ errors.nome_municipio_agente[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-4 -->
														<!-- end nome_municipio_agente -->

														<!-- uf_agente -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-uf_agente">UF</label>
																<select disabled id="input-uf_agente"
																	v-bind:class="{ 'is-invalid': errors.uf_agente }"
																	class="form-control" placeholder="UF"
																	v-model="uf_agente" required="true">
																	<option v-for="item in states">{{ item }}</option>
																</select>
																<div class="invalid-feedback" v-if="errors.uf_agente">
																	<p>{{ errors.uf_agente[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-2 -->
														<!-- end uf_agente -->

														<!-- cep_agente -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-cep_agente">CEP</label>
																<input disabled type="text" id="input-cep_agente"
																	v-bind:class="{ 'is-invalid': errors.cep_agente }"
																	class="form-control" placeholder="CEP"
																	v-model="cep_agente" required="true" maxlength="8">
																<div class="invalid-feedback" v-if="errors.cep_agente">
																	<p>{{ errors.cep_agente[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end cep_agente -->

														<!-- ddd_agente -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-ddd_agente">DDD</label>
																<input disabled type="tel" id="input-ddd_agente"
																	v-bind:class="{ 'is-invalid': errors.ddd_agente }"
																	class="form-control" placeholder="DDD"
																	v-model="ddd_agente" required="true" maxlength="4">
																<div class="invalid-feedback" v-if="errors.ddd_agente">
																	<p>{{ errors.ddd_agente[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-2 -->
														<!-- end ddd_agente -->

														<!-- telefone_agente -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-telefone_agente">Telefone</label>
																<input disabled type="text" id="input-telefone_agente"
																	v-bind:class="{ 'is-invalid': errors.telefone_agente }"
																	class="form-control" placeholder="Telefone"
																	v-model="telefone_agente" required="true"
																	maxlength="9">
																<div class="invalid-feedback"
																	v-if="errors.telefone_agente">
																	<p>{{ errors.telefone_agente[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end telefone_agente -->

													</div>
												</div>
												<a @click="changeStep(3)" class="btn-previous">Anterior</a>
											</fieldset>
											<!-- end agente -->
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div><!-- card -->
		</div><!-- col-12 -->

		<vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
	</div><!-- row -->
</template>

<script>

export default {
	props: ["contrato", 'estado'],
	data() {
		let data = {
			titleform: this.estado,
			uf_contrato: this.estado,
			url_estado: "",
			num_duda: this.getData('num_duda'),
			uf_liberacao_ass_contrato: this.getData('uf_assinatura_contrato'),
			municipios_lb: [],
			municipio_ass_contrato: this.getData('local_assinatura_contrato'),
			data_ass_contrato: this.getData('data_assinatura_contrato'),
			clausula_penal: this.getData('clausula_penal'),
			vl_encargos_contratos: this.getData('valor_encargos_contrato'),
			correcao_monetaria: this.getData('correcao_monetaria'),
			tipo_operacao: this.getData("tipo_operacao"),
			sequencia_contrato: this.getData("sequencia_contrato"), // BA e SC
			numero_assentamento: this.getData("num_assentamento"), //RJ
			num_contrato_origem: this.getData("num_contrato_origem"),
			num_aditivo_origem: this.getData("num_aditivo_origem"),
			chassi: this.getData("chassi"),
			remarcacao: this.getData("remarcacao"),
			uf_licenciamento: this.getData("uf_licenciamento"),
			uf_placa: this.getData("uf_placa"),
			placa: this.getData("placa"),
			renavam: this.getData("renavam"),
			ano_fabricacao: this.getData("ano_fabricacao"),
			ano_modelo: this.getData("ano_modelo"),
			nome_agente: this.getData("nome_agente"),
			cnpj_agente: this.getData("cnpj_agente"),
			num_contrato: this.getData("num_contrato"),
			data_contrato: this.getData("data_contrato"),
			data_contato: this.getData("data_contato"),
			qtd_parcelas: this.getData("qtd_parcelas"),
			num_gravame: this.getData("num_gravame"),
			tipo_gravame: this.getData("tipo_gravame"),
			taxa_juro_mes: this.getData("taxa_juro_mes"),
			taxa_juro_ano: this.getData("taxa_juro_ano"),
			taxa_juro_multa: this.getData("taxa_juro_multa"),
			taxa_mora_dia: this.getData("taxa_mora_dia"),
			taxa_multa: this.getData("taxa_multa"),
			taxa_mora: this.getData("taxa_mora"),
			indicativo_penalidade: this.getData("indicativo_penalidade"),
			penalidade: this.getData("penalidade"),
			indicativo_comissao: this.getData("indicativo_comissao"),
			comissao: this.getData("comissao"),
			valor_taxa_contrato: this.getData("valor_taxa_contrato"),
			valor_total_financiamento: this.getData("valor_total_financiamento"),
			valor_iof: this.getData("valor_iof"),
			valor_parcela: this.getData("valor_parcela"),
			data_vecto_primeira_parcela: this.getData("data_vecto_primeira_parcela"),
			data_vecto_ultima_parcela: this.getData("data_vecto_ultima_parcela"),
			data_liberacao_credito: this.getData("data_liberacao_credito"),
			uf_liberacao_credito: this.getData("uf_liberacao_credito"),
			municipio_liberacao_credito: this.getData("municipio_liberacao_credito"),
			municipio_credito: '',
			indice: this.getData("indice"),
			num_grupo_consorcio: this.getData("num_grupo_consorcio"),
			num_cota_consorcio: this.getData("num_cota_consorcio"),
			num_aditivo: this.getData("num_aditivo"),
			data_aditivo: this.getData("data_aditivo"),
			nome_logradouro_agente: this.getData("nome_logradouro_agente"),
			num_imovel_agente: this.getData("num_imovel_agente"),
			complemento_imovel_agente: this.getData("complemento_imovel_agente"),
			bairro_agente: this.getData("bairro_agente"),
			nome_municipio_agente: this.getData("nome_municipio_agente"),
			municipio_agente: "",
			cod_serpro_devedor: this.getData("nome_municipio_devedor"),
			uf_agente: this.getData("uf_agente"),
			cep_agente: this.getData("cep_agente"),
			ddd_agente: this.getData("ddd_agente"),
			telefone_agente: this.getData("telefone_agente"),
			cpf_cnpj_devedor: this.getData("cpf_cnpj_devedor"),
			nome_devedor: this.getData("nome_devedor"),
			nome_logradouro_devedor: this.getData("nome_logradouro_devedor"),
			num_imovel_devedor: this.getData("num_imovel_devedor"),
			complemento_imovel_devedor: this.getData("complemento_imovel_devedor"),
			bairro_devedor: this.getData("bairro_devedor"),
			nome_municipio_devedor: this.getData("nome_municipio_devedor"),
			municipio_devedor: "",
			uf_devedor: this.getData("uf_devedor"),
			cep_devedor: this.getData("cep_devedor"),
			ddd_devedor: this.getData("ddd_devedor"),
			telefone_devedor: this.getData("telefone_devedor"),
			arquivo: this.getData("arquivo"),
			attach: "",
			cor_id: this.getData('cor_id'),
			tipo_id: this.getData('tipo_id') != null || this.getData('tipo_id') != undefined ? this.getData('tipo_id') : this.getData('tipo_veiculo_id'),
			marca_id: this.getData("marca_id"),
			modelo_id: this.getData("modelo_id"),
			observacao: this.getData("observacao"),
			tipos: [],
			marcas: [],
			modelos: [],
			cores: [],
			states: ["AC", "AL", "AM", "AP", "BA", "CE", "DF", "ES", "GO", "MA", "MT", "MS", "MG", "PA", "PB", "PR", "PE", "PI", "RJ", "RN", "RO", "RS", "RR", "SC", "SE", "SP", "TO"],
			errors: [],
			step: 1,
			message: "",
			loading: false,
			fullPage: true,
			data_vigencia_contrato: moment(String(this.getData("data_vigencia_contrato"))).format('YYYY-MM-DD'),
			attachedFiles: [],
			money: {
				decimal: ',',
				thousands: '.',
				prefix: '',
				suffix: ' %',
				precision: 3,
				masked: false
			},
			percentmoney: {
				decimal: ',',
				thousands: '.',
				prefix: '',
				suffix: ' %',
				precision: 4,
				masked: false
			},
			valueMaskD3: {
				decimal: ',',
				thousands: '.',
				prefix: '',
				precision: 3,
				masked: false
			},
			SevenMoney: {
				decimal: ',',
				thousands: '.',
				prefix: 'R$ ',
				suffix: '',
				precision: 2,
				masked: false
			},
		}

		return data;
	},
	mounted() {
		this.onChangeUf("agente", this.getData("nome_municipio_agente"), this.getData("uf_agente"))
		this.onChangeUf("devedor", this.getData("nome_municipio_devedor"), this.getData("uf_devedor"))
		this.getCurrentURLEstado();
		if (this.url_estado == 'AC') {
			this.onChangeUf("ac", this.getData("municipio_liberacao_credito"), this.url_estado)
		}
		this.b64decode(this.arquivo);
		this.loadCores()
		this.loadTipos()
	},
	methods: {

		searchDetranCidades(estado, changeUF = false) {
			let _this = this;

			axios.get("/api/portal/detrancidades/search/" + estado).then((res) => {

				_this.municipios_lb = res.data;

				if (changeUF !== true) {
					//forma mais rapida de mostrar o nome Município de Liberação do Crédito
					let codMunicipioLiberacaoCredito = this.getData("municipio_liberacao_credito");

					_this.municipios_lb.forEach(function (nome, i) {
						if (nome.codigo_origem === codMunicipioLiberacaoCredito) {
							_this.municipio_liberacao_credito = nome.municipio
						}
					});
				} else {
					_this.municipio_liberacao_credito = "";
					_this.cod_municipio_liberacao_credito = "";
				}
			}).catch((err) => {
				console.warn(err);
			});
		},
		settedMunicipioCredito(item) {
			let _this = this;
			_this.municipio_liberacao_credito = item;
		},
		loadCores() {
			axios.get('/portal/cores').then(response => {
				this.cores = response.data;
			})
		},


		loadModelos(e) {

			if ("" !== e) {
				axios.get(`/portal/modelos/${e}`)
					.then(response => {
						this.modelos = response.data;
					})
					.catch(error => {
					});
			} else {
				this.marcas = [];
				this.modelos = [];
				this.cores = []
				this.cor_id = ''
			}
		},
		loadMarcas(e) {
			if ("" !== e) {

				var path = 'marcas'
				if (this.url_estado == 'RJ') {
					path = 'newmarcas'
				}

				axios.get(`/portal/${path}/${e}`)
					.then(response => {
						this.marcas = response.data;
						this.loadModelos(this.marca_id)
					})
					.catch(error => {

					});
			} else {
				this.marcas = [];
				this.modelos = [];
				this.cores = []
				this.cor_id = ''
			}
		},

		loadTipos() {
			let _this = this;
			_this.loading = true;

			var path = 'tipos'
			if (this.url_estado == 'RJ') {
				path = 'tipoveiculo'
			}

			_this.tipos = [];
			axios.get(`/portal/${path}`).then((res) => {
				_this.tipos = _this.tipos.concat(res.data)
				_this.loading = false;

				if (this.tipo_id) this.loadMarcas(this.tipo_id)

			}).catch((err) => {
				_this.loading = false;
			});
		},
		/*pega o estada que esta aparecendo na url*/
		getCurrentURLEstado() {
			let currentURL = window.location.pathname;
			this.url_estado = currentURL.split("/")[3];
		},
		getData(key) {
			return JSON.parse(this.contrato)[key];
		},
		changeStep(option) {
			this.step = option;
		},
		b64decode(b64String) {
			if (b64String) {
				const encodedData = b64String.split(',')[1];
				if (encodedData) {
					this.arquivo = atob(encodedData);
				}
			}
		},
		onChangeUf(param, codMunicipio, uf) {

			let municipios_ag = []
			axios.get("/api/portal/detrancidades/search/" + uf).then((res) => {

				municipios_ag = res.data;
				if (param == 'agente') {

					let codMunicipioAgente = codMunicipio.toString();
					codMunicipioAgente = this.addLeadingZeros(codMunicipioAgente);
					let nome_municipio_ag = "";
					municipios_ag.forEach(function (nome, i) {
						if (nome.codigo_origem === codMunicipioAgente) {
							nome_municipio_ag = nome.municipio
						}
					});

					this.municipio_agente = nome_municipio_ag

				} else if (param == 'devedor') {

					let codMunicipioDevedor = codMunicipio.toString();
					codMunicipioDevedor = this.addLeadingZeros(codMunicipioDevedor);
					let nome_municipio_dev = "";
					municipios_ag.forEach(function (nome, i) {
						if (nome.codigo_origem === codMunicipioDevedor) {
							nome_municipio_dev = nome.municipio
						}
					});

					this.municipio_devedor = nome_municipio_dev
				} else {
					let codMunicipioCredito = codMunicipio.toString();
					codMunicipioCredito = this.addLeadingZeros(codMunicipioCredito);
					let nome_municipio_credt = "";
					municipios_ag.forEach(function (nome, i) {
						if (nome.codigo_origem === codMunicipioCredito) {
							nome_municipio_credt = nome.municipio
						}
					});

					this.municipio_credito = nome_municipio_credt
				}
			}).catch((err) => {
				_this.$swal({
					title: "Erro",
					text: "Falha ao pesquisar o municipio tente novamente!",
					icon: "error"
				});
			});
		},
		addLeadingZeros(number) {

			let leadingZeros = "";

			if (number !== 'null' && number !== 'undefined' && number !== "") {

				if (number.length === 1) leadingZeros = '000' + number;
				if (number.length === 2) leadingZeros = '00' + number;
				if (number.length === 3) leadingZeros = '0' + number;

				return leadingZeros ? leadingZeros : number;
			}
			//return number;
		},
	}
}
</script>
